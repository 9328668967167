import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import { GET_CONTACT_HEADERS } from 'src/graphql';
import { ContactModel } from 'src/models';

export const COLUMNS_TABLE: SubTableColumn[] = [
  {
    title: 'NAME',
    dataIndex: 'firstName',
    minWidth: 200,
    dataType: 'string',
    editable: true,
    filterable: true,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
    searchField: 'names',
    render: (value: string, record: any) => {
      const val = value || record.firstName;

      return <Link to={`/contacts/${record.id}`}>{val}</Link>;
    },
  },
  {
    title: 'LAST NAME',
    dataIndex: 'lastName',
    minWidth: 200,
    dataType: 'string',
    editable: true,
    filterable: false,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
  {
    title: 'JOB TITLE',
    dataIndex: 'businessInfo',
    key: 'jobTitle',
    minWidth: 200,
    dataType: 'string',
    editable: true,
    filterable: false,
    hidden: false,
    options: [],
    required: true,
  },
  {
    key: 'name',
    title: 'COMPANY',
    dataIndex: 'businessInfo.company',
    minWidth: 200,
    dataType: 'string',
    editable: true,
    filterable: false,
    hidden: false,
    options: [],
    required: true,
    render: (value: string, record: any) => {
      const val = value || record?.businessInfo?.company?.name;

      if (!val) {
        return '-';
      }

      return <Link to={`/companies/${record.businessInfo.company?.id}`}>{val}</Link>;
    },
  },
  {
    key: 'phone',
    title: 'PHONE',
    dataIndex: 'businessInfo.mainPhone',
    minWidth: 200,
    dataType: 'phone',
    editable: true,
    filterable: false,
    hidden: false,
    options: [],
    required: true,
  },
  {
    key: 'email',
    title: 'EMAIL',
    dataIndex: 'businessInfo.mainEmail',
    minWidth: 200,
    dataType: 'string',
    editable: true,
    filterable: false,
    hidden: false,
    dataStructure: 'string',
    options: [],
    required: true,
  },
];

const useContactHeaders = () => {
  const [initialColumns, setInitialColumns] = useState<SubTableColumn[]>([]);

  useQuery(GET_CONTACT_HEADERS, {
    onCompleted: (data) => {
      const cols = data.GetContactsGridHeader.filter((c: SubTableColumn) => c.gridVisible !== false)
        .map((column: SubTableColumn) => ({
          ...column,
          title: column.headerName,
          minWidth: 250,
          width: 250,
          ellipsis: false,
        }))
        .map((col: SubTableColumn) => {
          if (col.dataIndex === 'businessInfo.company') {
            return {
              ...col,
              minWidth: 380,
              width: 380,
              render: (value: string, record: any) => <p>{record?.businessInfo?.company?.name}</p>,
            };
          }

          if (col.dataIndex === 'businessInfo.company.companyType') {
            return {
              ...col,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.company?.companyType.name}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.jobTitle') {
            return {
              ...col,
              render: (value: string, record: any) => <p>{record?.businessInfo?.jobTitle}</p>,
            };
          }

          if (col.dataIndex === 'businessInfo.company.salesTeam') {
            return {
              ...col,
              minWidth: 360,
              width: 360,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.company?.salesTeam.name}</p>
              ),
            };
          }

          if (col.dataIndex === 'name') {
            return {
              ...col,
              render(value: string, record: any) {
                const name = value || [record.firstName, record.lastName].filter(Boolean).join(' ');

                return <Link to={`/contacts/${record.id}`}>{name}</Link>;
              },
            };
          }

          if (col.dataIndex === 'firstName') {
            return {
              ...col,
              render: (value: string, record: ContactModel) => {
                const val = value || record.firstName;

                return <Link to={`/contacts/${record.id}`}>{val}</Link>;
              },
            };
          }

          if (col.dataIndex === 'businessInfo.mainPhone') {
            return {
              ...col,
              dataType: 'phone',
              title: 'Phone',
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainPhone?.phone}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.mainMobile') {
            return {
              ...col,
              dataType: 'phone',
              title: 'Mobile',
            };
          }

          if (col.dataIndex === 'businessInfo.mainAddress' && col.key === 'zipCode') {
            return {
              ...col,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainAddress?.zipCode}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.mainAddress.address') {
            return {
              ...col,
              minWidth: 460,
              width: 460,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainAddress?.address}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.mainAddress.region') {
            return {
              ...col,
              minWidth: 265,
              width: 265,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainAddress?.region?.name}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.mainAddress.state') {
            return {
              ...col,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainAddress?.state?.name}</p>
              ),
            };
          }

          if (col.dataIndex === 'businessInfo.mainAddress.zipCode') {
            return {
              ...col,
              render: (value: string, record: any) => (
                <p>{record?.businessInfo?.mainAddress?.zipCode}</p>
              ),
            };
          }

          return col;
        });

      setInitialColumns(cols);
    },
  });

  const visibleHeaders = initialColumns.filter((column) => !column.hidden);
  const selectOptions = initialColumns.map((column) => ({
    label: column.title,
    value: [column.dataIndex, column.key].filter(Boolean).join('.'),
  }));

  return { initialColumns, setInitialColumns, visibleHeaders, selectOptions };
};

export default useContactHeaders;
