import SubTable from 'src/components/commons/subTable';
import PageContainer from 'src/components/pageContainer/PageContainer';
import StyleInfinityScroll from '../../../../styles/StyleInfinityScroll.style';
import usePriceCodesTable from './hooks/usePriceCodesTable';

const TabPriceCode = () => {
  const {
    dataSource,
    columns,
    queryResponse,
    reset,
    newRecord,
    autocompleteFieldSearch,
    handleFilterChange,
    onListRender,
    setColumns,
    postNewRecord,
    resetNewRecord,
    handleNewRecord,
    handleUpdateRows,
    handleDeleteRows,
    predefinedData,
  } = usePriceCodesTable();

  return (
    <PageContainer margin='2rem'>
      <StyleInfinityScroll>
        <SubTable
          data={dataSource ?? []}
          onListRender={onListRender}
          columns={columns}
          setColumns={setColumns}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onFilterChange={handleFilterChange}
          handleUpdateRows={handleUpdateRows}
          onDeleteClick={handleDeleteRows}
          scroll={{ y: '60vh', x: '90vw' }}
          actionCell={['delete']}
          headerActions={['filter', 'edit', 'delete']}
          id='price-codes-table'
          showOptionFilterLeft={false}
          showAutoIncrement
          allowGlobalEditActive
          clearFilter={reset}
          editBySelected={false}
          newRecord={newRecord}
          postData={handleNewRecord}
          resetNewRecord={resetNewRecord}
          saveNewRecord={postNewRecord}
          total
          loading={queryResponse.loading}
          dataToCompare={predefinedData}
          columnFilterShown
          storageKey='productFamily'
          storageType='session'
        />
      </StyleInfinityScroll>
    </PageContainer>
  );
};

export default TabPriceCode;
