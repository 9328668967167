import SubTable from 'src/components/commons/subTable';
import { DeleteOutlined } from '@ant-design/icons';
import PageContainer from 'src/components/pageContainer/PageContainer';
import { CustomLoadingModal } from 'src/components/commons/customModal';
import useRankTable from './hooks/useRankTable';
import StyleInfinityScroll from '../../../../styles/StyleInfinityScroll.style';

const TabRankTable = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    setColumns,
    columns,
    handleUpdateRows,
    newRecord,
    resetNewRecord,
    postData,
    createNewRecord,
    spinning,
    dataSource,
    onListRender,
    handleClearFilter,
    handleDeleteRows,
  } = useRankTable();

  return (
    <>
      {spinning && <CustomLoadingModal modalStatus />}
      <PageContainer margin='2rem'>
        <StyleInfinityScroll>
          <SubTable
            data={dataSource ?? []}
            onListRender={onListRender}
            columns={columns}
            setColumns={setColumns}
            autocompleteFieldProvider={autocompleteFieldSearch}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortModelChange}
            handleUpdateRows={handleUpdateRows}
            onDeleteClick={handleDeleteRows}
            scroll={{ y: '60vh', x: '90vw' }}
            actionCell={['delete']}
            detailsIcon={<DeleteOutlined />}
            headerActions={['filter', 'edit', 'delete']}
            id='contacts-table'
            showOptionFilterLeft={false}
            newRecord={newRecord}
            resetNewRecord={resetNewRecord}
            saveNewRecord={createNewRecord}
            postData={postData}
            allowGlobalEditActive
            total
            showAutoIncrement
            clearFilter={handleClearFilter}
            columnFilterShown
          />
        </StyleInfinityScroll>
      </PageContainer>
    </>
  );
};
export default TabRankTable;
