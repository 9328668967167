import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, ProductFamilyMasterModel } from 'src/graphql/schema-types';
import { GetAllProductFamily, GetAllProductFamilyResponse } from '../types';
import { GET_ALL_PRODUCT_FAMILY } from '../graphql/queries';
import COLUMNS from '../constants';
import {
  CREATE_PRODUCT_FAMILY,
  REMOVE_PRODUCT_FAMILY,
  UPDATE_PRODUCT_FAMILY,
} from '../graphql/mutations';

const useProductFamilyTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, queryResponse] = useLazyQuery<GetAllProductFamily>(GET_ALL_PRODUCT_FAMILY, {
    fetchPolicy: 'network-only',
  });
  const [getAllFilter] = useLazyQuery<GetAllProductFamily>(GET_ALL_PRODUCT_FAMILY, {
    fetchPolicy: 'network-only',
  });

  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });
  const [update] = useMutation(UPDATE_PRODUCT_FAMILY);
  const [remove] = useMutation(REMOVE_PRODUCT_FAMILY);
  const [create] = useMutation(CREATE_PRODUCT_FAMILY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const uploadData: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllProductFamilyFromElastic2.results || [],
      total: res.data?.GetAllProductFamilyFromElastic2?.total || 0,
    }));
  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autoCompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          enabled: true,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllProductFamilyFromElastic2.results || [],
          filter: res?.data?.GetAllProductFamilyFromElastic2.results?.map(
            (result: GetAllProductFamilyResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleUpdateRows = (rows: ProductFamilyMasterModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateProductFamilyInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };
  const handleDeleteRows = async (rows: ProductFamilyMasterModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };
  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };
  const postNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createProductFamilyMasterInput: {
          name: capitalizeFirstWord(newRecord.name),
        },
      },
    }).then((productFamily) => {
      setDataSource([
        {
          id: productFamily.data.CreateProductFamily.id,
          name: productFamily.data.CreateProductFamily.name,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleClearFilter = () => {
    reset();
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };
  return {
    columns,
    dataSource,
    spinning: queryResponse.loading || deleting,
    newRecord,
    onListRender,
    setColumns,
    handleFilterChange,
    handleDeleteRows,
    handleNewRecord,
    resetNewRecord,
    handleUpdateRows,
    postNewRecord,
    handleClearFilter,
    autoCompleteFieldSearch,
  };
};

export default useProductFamilyTable;
