import { Col } from 'antd';
import styled from 'styled-components';

const FlexCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }

  .ant-select-selector {
    height: 40px !important;
  }

  .ant-select-selection-item {
    line-height: 40px !important;
  }

  .ant-select-selection-search-input {
    height: 38px !important;
  }
`;

export default FlexCol;
