/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableProps as AntTableProps } from 'antd';
import { MutableRefObject, PropsWithChildren, SyntheticEvent } from 'react';
import { ResizeCallbackData } from 'react-resizable';
import { ListInfo } from 'src/hooks/useInfinityDataSource';
import { ColumnTypes, HeaderDropdownOptions } from 'src/types/companiesTypes';

export type CustomizeComponent = React.FC<any> | React.ComponentClass<any>;
export type SubTableColumn = ColumnTypes[number] & {
  editable?: boolean;
  filterable?: boolean;
  sortable?: boolean;
  dataIndex: string | number | string[];
  filterKey?: string;
  sortKey?: string;
  title: string | React.ReactNode | null | undefined;
  onHeaderCell?: (column: SubTableColumn) => any;
  key?: string | number;
  dataType?: string;
  dataKey?: string | string[];
  options?: HeaderDropdownOptions[];
  unique?: boolean;
  required?: boolean;
  dataStructure?: string;
  gridVisible?: boolean;
  hasLink?: boolean;
  headerName?: string;
  hidden?: boolean;
  orderField?: string;
  relabelIndex?: string;
  searchField?: string;
  minWidth?: number;
  width?: number;
  selected?: boolean;
  gridPreferenceKey?: string;
};

export type RecordPostData = {
  dataIndex: string | number | string[];
  value: any;
  autoSave: boolean;
  dataKey?: string;
};

export type SubTableProviderProps = PropsWithChildren<{
  nonEditable?: boolean;
  nonDeletable?: boolean;
  showAutoIncrement?: boolean;
  nonSelectable?: boolean;
  nonAddable?: boolean;
  nonFilterable?: boolean;
  leftFilters?: () => React.ReactNode;
  leftFiltersOptions?: (Option & {
    type: string;
    options?: Option[];
  })[];
  selectedLeftFilter?: string;
  setSelectedLeftFilter?: React.Dispatch<React.SetStateAction<string | undefined>>;
  nonGlobalFilterable?: boolean;
  pagination?: boolean;
  columnFilterShown?: boolean;
  total?: number | boolean;
  page?: number;
  pageSize?: number;
  actionCell?: boolean | React.ReactNode[] | ActionRowType[];
  headerActions?: React.ReactNode[] | HeaderActionType[] | boolean;
  onRowClick?: (record: any, index?: number) => void;
  onGlobalFilterChange?: (value: string) => void;
  onFilterChange?: (filterFields: Record<string, any>) => void;
  onSortChange?: (sortModel: SortModel | undefined) => void;
  onPaginationChange?: (page: number, pageSize: number) => void;
  autocompleteGlobalProvider?: (filter: string) => Promise<Option[]>;
  selectedRows?: any[];
  autocompleteFieldProvider?: (
    searchField: string,
    value: string,
    dataType: string | number | string[],
    dataIndex?: string,
  ) => Promise<{
    results: unknown[];
    filter: Option[];
  }>;
  onEditClick?: (records: any[]) => void;
  onDeleteClick?: (records: any[]) => void;
  onFavClick?: (records: any[]) => void;
  titleOnDelete?: string;
  contentOnDelete?: string;
  onDetailsClick?: (record: any) => void;
  data: any[];
  storageKey?: string;
  storageType?: 'local' | 'session';
  detailsTable?: () => any;
  addNewButtonDetailsTable?: boolean;
  clickedRecord?: (record: any) => void;
  isModalCreateEditOpen?: boolean;
  setIsModalCreateEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  saveNewRecord?: (record?: any) => void;
  onSelectRecord?: (record?: any[]) => void;
}> &
  Pick<AntTableProps<RecordEntry>, 'dataSource'>;

export type Option = { value: any; label: any };
export type SortModel = { sortBy: string; sort: 'ASC' | 'DESC' };
export type FilterFields = Record<string | number, string | number>;

export type SubTableContextProps = {
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  rowsEdited: any[];
  setRowsEdited: (rowsEdited: any[]) => void;
  selectedRowKeys: string[];
  setSelectedRowKeys: (selectedRowKeys: string[]) => void;
  selectedRows: any[];
  setSelectedRows: (selectedRows: any[]) => void;
  isFiltering: boolean;
  setIsFiltering: (isFiltering: boolean) => void;
  globalFilter: string;
  setGlobalFilter: (globalFilter: string) => void;
  filterFields: FilterFields;
  setFilterFields: (filterFields: FilterFields) => void;
  sortModel: SortModel | undefined;
  setSortModel: (sortModel: SortModel | undefined) => void;
  pagination?: boolean;
  showLeftFilter: boolean;
  setShowLeftFilter: (showLeftFilter: boolean) => void;
  showModalBulkDelete: boolean;
  setShowModalBulkDelete: React.Dispatch<React.SetStateAction<boolean>>;
  addNewRecord: boolean;
  setAddNewRecord: React.Dispatch<React.SetStateAction<boolean>>;
  dataTable: any[] | undefined;
  setDataTable: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  setEditingKey: React.Dispatch<React.SetStateAction<string>>;
  editingKey: string;
  setKeyToEditNewRow: React.Dispatch<React.SetStateAction<string>>;
  keyToEditNewRow: string;
  optionSelectedFromSelect: string;
  setOptionSelectedFromSelect: React.Dispatch<React.SetStateAction<string>>;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  record: Record<any, any> | undefined;
  setRecord: React.Dispatch<React.SetStateAction<Record<any, any> | undefined>>;
  setOptionsFromValues: React.Dispatch<React.SetStateAction<string>>;
  optionsFromValues: string;
  hideSubtableBorders?: boolean;
} & Omit<SubTableProviderProps, 'children'>;

export type SubTableGridProps = Omit<
  AntTableProps<any>,
  'components' | 'columns' | 'dataSource'
> & {
  onListRender?: (listInfo: ListInfo) => void;
  handleUpdateRows?: (rowsEdited: any[]) => void;
  id?: string;
  columns: SubTableColumn[];
  setColumns?: React.Dispatch<React.SetStateAction<SubTableColumn[]>>;
  columnsToSelectOption?: Option[];
  columnsHeaderSelected?: string[];
  components?: {
    table?: CustomizeComponent;
    header?: {
      wrapper?: CustomizeComponent;
      row?: CustomizeComponent;
      cell?: (Comp: any) => CustomizeComponent;
    };
    body?: {
      wrapper?: CustomizeComponent;
      row?: (Comp: any) => CustomizeComponent;
      cell?: (Comp: any) => CustomizeComponent;
    };
  };
  deleteIcon?: React.ReactNode;
  editIcon?: React.ReactNode;
  detailsIcon?: React.ReactNode;
  favIcon?: React.ReactNode;
  showOptionFilterLeft?: boolean;
  showHeaderSelect?: boolean;
  allowGlobalEditActive?: boolean;
  activeOnSelectRowsDisableEdit?: boolean;
  editBySelected?: boolean;
  data: any[];
  setDataTable: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  postData?: (args0: { [key: string]: unknown }) => void;
  newRecord?: { [key: string]: unknown };
  resetNewRecord?: () => void;
  saveNewRecord?: (record?: any) => void;
  clearFilter?: () => void;
  deleteNewRecord?: (record: any) => void;
  dataToCompare?: any[];
  dataForListInHeader?: any[];
  keyExpandableRow?: string;
  addNewButtonDetailsTable?: boolean;
  isSubTableExpandable?: boolean;
  filterByHeaderListServer?: (listInfo: ListInfo) => void;
  filterByHeaderListClient?: (search: string) => void;
  BodyModal?: React.ComponentType<Record<any, any>>;
  addWithModal?: boolean;
  onAddClick?: () => void;
  titleModalEdit?: string;
  titleModalNew?: string;
  notEditWithModal?: boolean;
  clickedRecord?: (record: any) => void;
  headerSelectDefaultValue?: string;
  isModalCreateEditOpen?: boolean;
  setIsModalCreateEditOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onSelectRecord?: (records: any[]) => void;
  hideSubtableBorders?: boolean;
  searchPlaceholder?: string;
  clickable?: boolean;
  handleCollapseToggle?: () => void;
} & Omit<SubTableProviderProps, 'children'>;

export type ActionRowType = 'edit' | 'delete' | 'details' | 'fav';
export type HeaderActionType =
  | 'filter'
  | 'edit'
  | 'delete'
  | 'clear-left-filter'
  | 'columns'
  | 'email'
  | 'download'
  | 'reassign';

export type ResizeHandler = ((e: SyntheticEvent, data: ResizeCallbackData) => any) | undefined;

export type ResizeHandlerBuilder = (index: number) => ResizeHandler;

export type RecordEntry = any;

export enum EditableCellEnum {
  editingNewRow = 'editingNewRow',
}

export type SubTableExpandableContextProps = PropsWithChildren<{
  rowsExpanded: any[];
  setRowsExpanded: (rowsEdited: any[]) => void;
  rowsExpandedRef: MutableRefObject<any[]>;
  saveRowsExpanded: (newData: any[]) => void;
}>;

export type SubTableExpandableProviderProps = PropsWithChildren;

export type InterfaceStatus = '' | 'success' | 'warning' | 'error' | 'validating' | undefined;
