import { useState } from 'react';

type Filter = {
  fieldName: string;
  value: string;
};
const useFilterColumns = () => {
  const [columnFilters, setColumnFilters] = useState<Filter[]>([]);

  const createdFilters = (searchField: string, search: string) => {
    const newFilter: Filter = {
      fieldName: searchField,
      value: search,
    };

    let newFilters: Filter[] = [];

    if (columnFilters.length === 0) {
      newFilters = [newFilter];
      setColumnFilters(newFilters);
    } else {
      const foundFilter = columnFilters.find((filter) => filter.fieldName === searchField);

      if (!foundFilter) {
        newFilters = [...columnFilters, newFilter];
        setColumnFilters(newFilters);
      } else {
        newFilters = columnFilters.map((filter) => {
          if (filter.fieldName === searchField) {
            return {
              ...filter,
              value: search,
            };
          }

          return filter;
        });

        setColumnFilters(newFilters);
      }
    }

    return newFilters.filter((filter) => filter.value !== '');
  };

  return { columnFilters, setColumnFilters, createdFilters };
};

export default useFilterColumns;
