import { gql } from '@apollo/client';

export const CREATE_UNIT_OF_MEASUREMENTS = gql`
  mutation CreateUnitOfMeasurement($createUnitOfMeasurementInput: CreateUnitOfMeasurementInput!) {
    CreateUnitOfMeasurement(createUnitOfMeasurementInput: $createUnitOfMeasurementInput) {
      id
      name
      units
    }
  }
`;

export const UPDATE_UNIT_OF_MEASUREMENTS = gql`
  mutation UpdateUnitOfMeasurement($updateUnitOfMeasurementInput: UpdateUnitOfMeasurementInput!) {
    UpdateUnitOfMeasurement(updateUnitOfMeasurementInput: $updateUnitOfMeasurementInput) {
      name
      units
    }
  }
`;
export const REMOVE_UNIT_OF_MEASUREMENTS = gql`
  mutation RemoveUnitOfMeasurement($id: Int!) {
    RemoveUnitOfMeasurement(id: $id)
  }
`;
