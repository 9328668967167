import { gql } from '@apollo/client';

export const GET_ALL_PRICE_CODES = gql`
  query GetAllPriceCodeFromElastic2(
    $criteria: PriceCodeCriteriaInput!
    $searchFields: FindPriceCodeInput
  ) {
    GetAllPriceCodeFromElastic2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        manufacturer {
          id
          name
        }
        code
        description
      }
      total
    }
  }
`;

export const GET_HEADERS_PRICE_CODES = gql`
  query Query {
    GetPriceCodeGridHeaders
  }
`;
