import {
  CloseCircleOutlined,
  DeleteOutlined,
  DoubleRightOutlined,
  EditOutlined,
  FilterOutlined,
  ImportOutlined,
  MailOutlined,
  PlusOutlined,
  SwapOutlined,
  TableOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import CheckboxMenu from 'src/components/checkboxMenu/CheckboxMenu';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import handleKeyboardDown from 'src/utils/functions/handleKeyboardEvents';
import { ListInfo } from 'src/hooks/useInfinityDataSource';
// eslint-disable-next-line import/no-cycle
import ReassignModal from './reassignModal';
import StyledAddButton from '../styles/addButton.style';
import { useSubTableContext } from '../contexts/subtable.context';
import GlobalSearch from './globalSearch';
import { HeaderActionType, SubTableColumn } from '../types';
import CustomButton from '../../customButton';
import { showConfirmationMessage } from '../helpers';
import { FILTER_SPAN } from './AdvancedFilterWithSubTableGrid';
import EmailModal from './EmailModal';

type Props = {
  total?: boolean | number;
  setColumns?: (columns: SubTableColumn[]) => void;
  columns?: SubTableColumn[];
  showOptionFilterLeft?: boolean;
  allowGlobalEditActive?: boolean;
  activeOnSelectRowsDisableEdit?: boolean;
  editBySelected?: boolean;
  showHeaderSelect?: boolean;
  dataForListInHeader?: any[];
  clearList?: () => void;
  filterByHeaderListServer?: (listInfo: ListInfo) => void;
  filterByHeaderListClient?: (search: string) => void;
  addWithModal?: boolean;
  onAddClick?: () => void;
  headerSelectDefaultValue?: string;
  searchPlaceholder?: string;
  handleCollapseToggle?: () => void;
};

const SubTableHeader: FC<Props> = ({
  total,
  columns,
  setColumns,
  showOptionFilterLeft = true,
  allowGlobalEditActive,
  activeOnSelectRowsDisableEdit = false,
  editBySelected,
  clearList,
  showHeaderSelect,
  dataForListInHeader,
  filterByHeaderListServer,
  filterByHeaderListClient,
  addWithModal,
  onAddClick,
  headerSelectDefaultValue,
  searchPlaceholder,
  handleCollapseToggle,
}) => {
  const {
    nonAddable,
    isFiltering,
    setIsFiltering,
    nonGlobalFilterable,
    headerActions,
    selectedRows,
    onEditClick,
    setFilterFields,
    showLeftFilter,
    data: dataSource,
    setShowLeftFilter,
    setAddNewRecord,
    addNewRecord,
    isEditing,
    setIsEditing,
    setOpenModal,
    setRecord,
    columnFilterShown,
    onDeleteClick,
    filterFields,
    leftFiltersOptions,
    selectedLeftFilter,
    setSelectedLeftFilter,
    saveNewRecord,
  } = useSubTableContext();
  const [globalMenuActive, setGlobalMenuActive] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);

  const handleFilterClick = () => {
    if (globalMenuActive === 'filter' && isFiltering && Object.keys(filterFields).length > 0) {
      clearList?.();
    }
    setIsFiltering(!isFiltering);
    setFilterFields?.({});
    setGlobalMenuActive(globalMenuActive === 'filter' ? '' : 'filter');
  };

  const handleEditClick = () => {
    setGlobalMenuActive(globalMenuActive === 'edit' ? '' : 'edit');

    if (onEditClick && editBySelected) {
      onEditClick(selectedRows);
    }

    if (allowGlobalEditActive) {
      setIsEditing(!isEditing);
    }
  };

  const handleDownloadClick = () => {
    setGlobalMenuActive(globalMenuActive === 'download' ? '' : 'download');
  };

  const handleDeleteClick = () => {
    showConfirmationMessage({
      title: 'Are you sure you want to delete these records',
      content: 'This action cannot be undone',
      onOk: () => {
        if (onDeleteClick) {
          onDeleteClick(selectedRows);
        }
      },
    });
  };

  const handleAdvancedFilter = () => {
    setShowLeftFilter(!showLeftFilter);
  };

  const handleClearFilter = () => {
    if (!columnFilterShown) {
      setIsFiltering(false);
    }
    setShowLeftFilter?.(false);
    setFilterFields?.({});
    setGlobalMenuActive(globalMenuActive === 'clear-left-filter' ? '' : 'clear-left-filter');
  };

  // TODO Organize the columns if the user drag and drop a column
  // TODO Refactor in order to get all data of the row
  const handleColumnsChange = (selectedValues: CheckboxValueType[]) => {
    if (setColumns) {
      const newColumns =
        columns?.map((column) => ({
          ...column,
          selected: selectedValues.includes(String(column.gridPreferenceKey)),
        })) ?? [];

      setColumns(newColumns);
    }
  };

  const handleColumnsIconClick = () => {
    setGlobalMenuActive(globalMenuActive === 'columns' ? '' : 'columns');
  };

  const handleOnOpenChange = (visible: boolean) => {
    if (!visible) {
      setGlobalMenuActive('');
    }
  };

  const handleHeaderFilter = (filter: string) => {
    // to filter in the server
    if (filterByHeaderListServer) {
      filterByHeaderListServer({ start: 0, renderLen: 100, filter });
    }

    // to filter in the client
    if (filterByHeaderListClient) {
      filterByHeaderListClient(filter);
    }
  };
  const handleNewRecord = () => {
    if (addWithModal) {
      setOpenModal(true);
      setRecord(undefined);
    } else if (onAddClick) {
      onAddClick();
    } else {
      setAddNewRecord((prev) => {
        if (isEditing) return prev;
        return !prev;
      });
    }
  };

  useEffect(() => {
    const handleKeyBoardDown = (event: KeyboardEvent) => {
      if (!nonAddable && event.key === 'Escape' && !addWithModal) {
        event.preventDefault();
        setAddNewRecord(false);
        return;
      }

      if (addWithModal && handleKeyboardDown(event) && event.ctrlKey && event.key === 'n') {
        handleNewRecord();
      }

      if (!nonAddable && handleKeyboardDown(event) && !addWithModal)
        setAddNewRecord((prev) => !prev);
    };
    window.addEventListener('keydown', handleKeyBoardDown);

    return () => {
      window.addEventListener('keydown', handleKeyBoardDown);
    };
  }, []);

  useEffect(() => {
    if (columnFilterShown) {
      setGlobalMenuActive('filter');
    }
  }, []);

  const columnsToSelectOption = useMemo(() => {
    if (!columns) {
      return [];
    }

    return columns.map((column) => ({
      label: column.title,
      value: String(column.gridPreferenceKey),
    }));
  }, [columns]);

  const columnsHeaderSelected = useMemo(() => {
    if (!columns) {
      return [];
    }

    return columns
      .filter((column) => column.selected !== false)
      .map((column) => String(column.gridPreferenceKey));
  }, [columns]);

  const actions = useMemo(() => {
    if (!headerActions) {
      return null;
    }

    let localActions = headerActions as (ReactNode | HeaderActionType)[];

    if (typeof headerActions === 'boolean') {
      localActions = ['filter', 'edit', 'delete'];
    }

    return localActions.map((action: HeaderActionType | React.ReactNode) => {
      switch (action) {
        case 'filter':
          return (
            <CustomButton
              dataTestId='subtable-filter-button'
              key='filter'
              shape='circle'
              type='text'
              tooltipText='Filter'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<FilterOutlined />}
              onClick={handleFilterClick}
              ClassName={`filter-button ${isFiltering ? 'active' : ''}`}
              active={globalMenuActive === 'filter' && isFiltering}
            />
          );
        case 'clear-left-filter':
          return (
            <CustomButton
              dataTestId='subtable-clear-left-filter-button'
              shape='circle'
              key='clear-left-filter'
              type='text'
              tooltipText='Remove Filters'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<CloseCircleOutlined />}
              onClick={handleClearFilter}
              active={globalMenuActive === 'clear-left-filter'}
            />
          );
        case 'edit':
          return (
            <CustomButton
              dataTestId='subtable-edit-button'
              disabled={
                (!allowGlobalEditActive && !selectedRows.length) ||
                (activeOnSelectRowsDisableEdit && selectedRows.length > 1)
              }
              key='edit'
              shape='circle'
              type='text'
              tooltipText='Edit'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<EditOutlined />}
              onClick={handleEditClick}
              ClassName={`edit-button ${isEditing ? 'active' : ''}`}
              active={globalMenuActive === 'edit'}
            />
          );
        case 'collapsed':
          return (
            <CustomButton
              dataTestId='subtable-collapsed-button'
              key='collapsed'
              shape='circle'
              type='text'
              tooltipText='Collapse card'
              onlyIcon
              tooltipColor='gray'
              icon={<EyeInvisibleOutlined />}
              onClick={handleCollapseToggle}
              active={globalMenuActive === 'delete'}
            />
          );
        case 'delete':
          return (
            <CustomButton
              dataTestId='subtable-delete-button'
              disabled={!selectedRows.length}
              key='delete'
              shape='circle'
              type='text'
              tooltipText='Delete'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<DeleteOutlined />}
              onClick={handleDeleteClick}
              active={globalMenuActive === 'delete'}
            />
          );
        case 'download':
          return (
            <CustomButton
              dataTestId='subtable-download-button'
              key='download'
              shape='circle'
              type='text'
              tooltipText='Download'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<ImportOutlined rotate={270} />}
              onClick={handleDownloadClick}
              active={globalMenuActive === 'download'}
            />
          );
        case 'columns':
          return (
            <CheckboxMenu
              key='columns-menu'
              options={columnsToSelectOption || []}
              optionsSelected={columnsHeaderSelected}
              onCheckboxChange={handleColumnsChange}
              onOpenChange={handleOnOpenChange}
            >
              <CustomButton
                dataTestId='subtable-columns-button'
                key='columns'
                shape='circle'
                type='text'
                tooltipText='Columns'
                onlyIcon
                danger
                tooltipColor='gray'
                icon={<TableOutlined />}
                onClick={handleColumnsIconClick}
                active={globalMenuActive === 'columns'}
                style={{ marginRight: '25px' }}
              />
            </CheckboxMenu>
          );
        case 'email':
          return (
            <CustomButton
              dataTestId='subtable-email-button'
              disabled={
                selectedRows.some((row) => !row?.businessInfo?.emails?.[0]?.email) ||
                !selectedRows.length
              }
              key='email'
              shape='circle'
              type='text'
              tooltipText='Send Mail'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<MailOutlined />}
              onClick={() => setOpenEmailModal(true)}
              active={globalMenuActive === 'email'}
            />
          );
        case 'reassign':
          return (
            <CustomButton
              dataTestId='subtable-reassign-button'
              key='email'
              shape='circle'
              type='text'
              tooltipText='Reassign'
              onlyIcon
              danger
              tooltipColor='gray'
              icon={<SwapOutlined />}
              onClick={() => setOpenReassignModal(true)}
              active={globalMenuActive === 'email'}
            />
          );
        default:
          return action;
      }
    });
  }, [
    headerActions,
    isFiltering,
    setIsFiltering,
    selectedRows,
    handleEditClick,
    handleDeleteClick,
  ]);

  const showTotal = useMemo(() => total === true || typeof total === 'number', [total]);

  const totalForShow = useMemo(() => {
    if (total === true) {
      return dataSource?.every((e) => e.placeholder) ? 0 : dataSource?.length;
    }

    return total;
  }, [total, dataSource]);

  const leftFilterSpan = useMemo(() => {
    if (!showOptionFilterLeft) return 0;
    if (showLeftFilter) return FILTER_SPAN;
    return undefined;
  }, [showOptionFilterLeft, showLeftFilter]);

  return (
    <>
      {openReassignModal && <ReassignModal setOpenModal={setOpenReassignModal} />}
      {openEmailModal && (
        <EmailModal setOpenModal={setOpenEmailModal} saveNewRecord={saveNewRecord} />
      )}
      <Row
        style={{ position: 'relative', zIndex: 10, paddingBottom: 10 }}
        data-testid='subtable-header'
      >
        <Col span={leftFilterSpan}>
          <Row style={{ gap: 10 }}>
            {showOptionFilterLeft && (
              <Col>
                <Button
                  type='text'
                  data-testid='move-grid-contacts-button-id'
                  shape='circle'
                  icon={<DoubleRightOutlined rotate={showLeftFilter ? 180 : 0} />}
                  style={{ border: 'none' }}
                  onClick={handleAdvancedFilter}
                />
              </Col>
            )}
            {showLeftFilter && (
              <Col style={{ flex: '1' }}>
                <Select
                  style={{ width: '100%' }}
                  options={
                    leftFiltersOptions?.map((opt) => ({ value: opt.value, label: opt.label })) || []
                  }
                  data-testid='all-contacts-select-id'
                  value={selectedLeftFilter}
                  onChange={(value) => {
                    if (setSelectedLeftFilter) {
                      setSelectedLeftFilter(value);
                    }
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col style={{ display: 'flex', gap: 16, alignItems: 'center', flex: '1' }}>
          {!nonAddable && (
            <StyledAddButton
              data-testid='subtable-add-button'
              type='primary'
              shape='circle'
              active={addNewRecord}
              icon={<PlusOutlined />}
              onClick={handleNewRecord}
            />
          )}
          {!nonGlobalFilterable ? (
            <GlobalSearch placeholder={searchPlaceholder} />
          ) : (
            <div style={{ width: '100%' }} />
          )}
          {showHeaderSelect && (
            <Select
              onChange={handleHeaderFilter}
              style={{ width: '20%' }}
              options={dataForListInHeader}
              data-testid='select-header-subtable'
              defaultValue={headerSelectDefaultValue ?? ''}
            />
          )}
        </Col>
        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingLeft: 10,
          }}
        >
          {showTotal && (
            <span style={{ whiteSpace: 'nowrap' }} data-testid='sutable-header-total'>
              <span style={{ fontSize: 16 }}>{totalForShow} </span>
              <span style={{ color: '#858585' }}>in total</span>
            </span>
          )}
        </Col>
        <Col style={{ display: 'flex', gap: 16, alignItems: 'center' }}>{actions}</Col>
      </Row>
    </>
  );
};

export default SubTableHeader;
