import { useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { RelatedCompanyDbModel } from 'src/graphql/schema-types';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { DETAILS_COLUMNS, StorageKey } from '../constants';
import {
  CREATE_RELATED_DETAIL_CATEGORY_COMMISSION,
  UPDATE_RELATED_DETAIL_CATEGORY_COMMISSION,
  DELETE_RELATED_DETAIL_CATEGORY_COMMISSION,
} from '../graphql/mutations';

interface RelatedCompanyDetail {
  companyManufacturer: string | null;
  customerSplit: string;
  newRecord?: boolean;
}

const useCompanyRelatedDetailsTable = (data?: RelatedCompanyDetail[]) => {
  const { storeData } = useLocalStorage(StorageKey.expandableRows, []);
  const [dataSource, setDataSource] = useState<RelatedCompanyDetail[]>(data || []);

  const [deleting, setDeleting] = useState(false);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(DETAILS_COLUMNS);
  const [newRecord, setNewRecord] = useState<RelatedCompanyDetail>({
    companyManufacturer: '',
    customerSplit: '',
  });

  const [create] = useMutation(CREATE_RELATED_DETAIL_CATEGORY_COMMISSION);
  const [update] = useMutation(UPDATE_RELATED_DETAIL_CATEGORY_COMMISSION);
  const [remove] = useMutation(DELETE_RELATED_DETAIL_CATEGORY_COMMISSION);

  useEffect(() => {
    if (newRecord.companyManufacturer && newRecord.customerSplit) {
      storeData(newRecord);
    }
  }, [newRecord, storeData]);

  const resetNewRecord = () => {
    setNewRecord({
      companyManufacturer: '',
      customerSplit: '',
    });
  };

  const createNewRecord = async () => {
    if (newRecord.companyManufacturer === '') {
      return;
    }

    await create({
      variables: {
        createContactGroupInput: {
          companyManufacturer: newRecord.companyManufacturer,
          customerSplit: newRecord.customerSplit,
        },
      },
    }).then((contactGroup) => {
      setDataSource([
        {
          companyManufacturer: contactGroup.data.CreateContactGroup.id,
          customerSplit: contactGroup.data.CreateContactGroup.name,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: RelatedCompanyDbModel[]) => {
    rows.forEach((row) => {
      const { id, company } = row;
      update({
        variables: {
          updateContactGroupInput: {
            id,
            name: capitalizeFirstWord(company?.name as string),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: RelatedCompanyDetail[]) => {
    setDeleting(true);
    await Promise.all(
      rows.map((row: any) => {
        if (row.newRecord) {
          // TODO refactor when delete created records
          const newData = dataSource?.filter((item: RelatedCompanyDetail) => item.newRecord);
          setDataSource([...newData]);
          return Promise.resolve();
        }

        return remove({ variables: { id: row.id } });
      }),
    );
    setDeleting(false);
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    handleUpdateRows,
    spinning: deleting,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleDeleteRows,
  };
};

export default useCompanyRelatedDetailsTable;
