import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import useCallPatternStore from 'src/hooks/useCallPatternStore';
import { CallPatternModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { CallPatternResponse } from '../types';
import useFilterColumn from './useFilterColumn';

const useCallPatternTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const { removeOne, updateOne, createOne, getAll, loading, getAllFilter } = useCallPatternStore();
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [deleting, setDeleting] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    getAll({
      Criteria: {
        pagination: {
          from: start,
          size: renderLen,
        },
      },
      SearchFields: {
        ids: null,
        names: null,
        enabled: null,
      },
    }).then((res) => ({
      results: res.data?.GetAllCallPattern2.results || [],
      total: res.data?.GetAllCallPattern2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);

    return getAllFilter({
      Criteria: {
        pagination: {
          from: 0,
          size: 25,
        },

        filter: filters,
      },
      SearchFields: {
        ids: null,
        names: null,
        enabled: null,
      },
    }).then((res) => ({
      results: res?.data?.GetAllCallPattern2.results as any[],
      filter: res?.data?.GetAllCallPattern2.results?.map((result: CallPatternResponse) => ({
        value: result[searchField],
        label: result[searchField],
      })) as Option[],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await createOne({
      name: capitalizeFirstWord(name),
      description,
    }).then((customType) => {
      setDataSource([
        {
          id: customType.data.CreateCallPattern.id,
          name: customType.data.CreateCallPattern.name,
          description: customType.data.CreateCallPattern.description,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: CallPatternModel[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      updateOne({
        id,
        name: capitalizeFirstWord(name!),
        description,
      });
    });
  };

  const handleRemoveRecords = async (rows: CallPatternModel[]) => {
    setDeleting(true);
    Promise.allSettled(rows.map((row) => removeOne(row.id)));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: loading || deleting,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleRemoveRecords,
  };
};

export default useCallPatternTable;
