import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, CompanyCategoryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import GET_ALL_COMPANY_CATEGORY from '../graphql/queries';
import {
  UPDATE_CATEGORY_COMPANY,
  CREATE_CATEGORY_COMPANY,
  DELETE_CATEGORY_COMPANY,
} from '../graphql/mutations';
import { GetAllCompanyCategories, GetAllCompanyCategoriesResponse } from '../types';

const useTabCategoryCompanyTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, { loading }] = useLazyQuery<GetAllCompanyCategories>(GET_ALL_COMPANY_CATEGORY, {
    fetchPolicy: 'network-only',
  });

  const [getAllFilter] = useLazyQuery<GetAllCompanyCategories>(GET_ALL_COMPANY_CATEGORY, {
    fetchPolicy: 'network-only',
  });
  const [update] = useMutation(UPDATE_CATEGORY_COMPANY);
  const [create] = useMutation(CREATE_CATEGORY_COMPANY);
  const [deleteMutation] = useMutation(DELETE_CATEGORY_COMPANY);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const fetchCompanyCategoriesPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCompanyCategory2.results || [],
      total: res.data?.GetAllCompanyCategory2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(
    fetchCompanyCategoriesPage,
  );

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllCompanyCategory2.results as any[],
          filter: res?.data?.GetAllCompanyCategory2.results?.map(
            (result: GetAllCompanyCategoriesResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createCompanyCategoryInput: {
          name: capitalizeFirstWord(newRecord.name),
        },
      },
    }).then((region) => {
      setDataSource([
        {
          id: region.data.CreateCompanyCategory.id,
          name: region.data.CreateCompanyCategory.name,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: CompanyCategoryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateCompanyCategoryInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyCategoryDbModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => deleteMutation({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: loading || deleting,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useTabCategoryCompanyTable;
