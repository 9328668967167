import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Option, SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AddressTypeModel, Query } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GetAddressTypeGridHeaders } from '../types';
import { GET_ALL_ADDRESS_TYPE, GET_HEADERS_ADDRESS_TYPE } from '../graphql/queries';
import {
  CREATE_ADDRESS_TYPE,
  DELETE_ADDRESS_TYPE,
  UPDATE_ADDRESS_TYPE,
} from '../graphql/mutations';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useAddressTypeTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [deleting, setDeleting] = useState(false);
  const [loadAddressType, queryResponse] = useLazyQuery<Pick<Query, 'GetAllAddressType2'>>(
    GET_ALL_ADDRESS_TYPE,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [loadAddressTypeFilter] = useLazyQuery<Pick<Query, 'GetAllAddressType2'>>(
    GET_ALL_ADDRESS_TYPE,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [loadHeaders] = useLazyQuery<GetAddressTypeGridHeaders>(GET_HEADERS_ADDRESS_TYPE, {
    fetchPolicy: 'network-only',
  });

  const [create] = useMutation(CREATE_ADDRESS_TYPE);
  const [update] = useMutation(UPDATE_ADDRESS_TYPE);
  const [remove] = useMutation(DELETE_ADDRESS_TYPE);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
    isCompany: true,
    isContact: false,
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    loadAddressType({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllAddressType2.results || [],
      total: res.data?.GetAllAddressType2.total || 0,
    }));

  const loadHeader = () => {
    loadHeaders().then((res) => {
      const filteredHeaders = filterIDAndAddingTitle(res.data?.GetAddressTypeGridHeaders ?? []).map(
        (el) => {
          if (el.options) {
            el.options = el.options.map((option: any) => ({
              VALUE: option?.id,
              LABEL: option?.name,
            }));
          }
          el.key = '';
          return el;
        },
      );
      setColumns(COLUMNS ?? filteredHeaders);
    });
  };

  useEffect(() => {
    loadHeader();
  }, []);

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return loadAddressTypeFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllAddressType2.results || [],
      filter: res?.data?.GetAllAddressType2.results
        ?.map((result) => ({
          value: result[searchField as keyof AddressTypeModel],
          label: result[searchField as keyof AddressTypeModel],
        }))
        .filter((e) => e.value !== null) as Option[],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
      isCompany: true,
      isContact: false,
    });
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createAddressTypeInput: {
          name: capitalizeFirstWord(name),
          description,
          isCompany: true,
          isContact: false,
        },
      },
    }).then((addressType) => {
      setDataSource([
        {
          id: addressType.data.CreateAddressType.id,
          name: addressType.data.CreateAddressType.name,
          description: addressType.data.CreateAddressType.description,
          isCompany: addressType.data.CreateAddressType.isCompany,
          isContact: addressType.data.CreateAddressType.isContact,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateAddressTypeInput: {
            id,
            name: capitalizeFirstWord(name),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: AddressTypeModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: queryResponse.loading || deleting,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useAddressTypeTable;
