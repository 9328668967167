/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint react/jsx-no-useless-fragment: 0 */
/* eslint no-nested-ternary: 0 */
import { Checkbox, Input, Radio, Select, DatePicker, InputRef, Tag } from 'antd';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Addresses from 'src/components/addressesSubForm';
import Phones from 'src/components/phonesSubForm';
import Emails from 'src/components/emailsSubForm';
import { getDefaultValue, getValueFromSelectToInput } from 'src/utils/functions/getDefaultValues';
import { FieldType } from '../types';
import FileInput from './fileInput';

type Props = {
  field: FieldType;
  value?: any;
  onChange?: (value: any, option?: any) => void;
};

const GenericInput: FC<Props> = ({ field, value, onChange }) => {
  const defaultPlaceholder = field.type === 'select' ? 'Please select' : 'Type here...';
  // const regexp = /^{{%(.+)%}}/;
  const { Option } = Select;
  const placeholder = field.placeholder ?? defaultPlaceholder;
  const initialized = useRef(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  useEffect(() => {
    if (field.type === 'multiSelect' && field.options) {
      const initialValues = field.options
        .filter((option) => value?.includes(option.value))
        .map((option) => option.label);
      setSelectedOptions(initialValues);
    }
  }, [value, field.type, field.options]);

  const customDropdownRender = (menu: React.ReactNode) => <>{menu}</>;

  const maxTagPlaceholder = () => (
    <Tag style={{ background: '#DCDFFB', border: '1px solid #0078D4' }}>
      {selectedOptions?.length} Total
    </Tag>
  );

  const renderOptionWithCheckboxMulti = (option: string) => {
    // Check if the label of the option is included in the selectedOptions
    const isChecked = selectedOptions.includes(option);
    return (
      <div style={{ display: 'flex', alignItems: 'center', padding: '0px !important' }}>
        <Checkbox checked={isChecked} style={{ marginRight: 5 }} />
        {option}
      </div>
    );
  };

  const autoFocus = useCallback(
    (ref: InputRef) => {
      if (ref && field.autoFocus && !initialized.current) {
        initialized.current = true;
        setTimeout(() => {
          ref.focus();
        }, 10);
      }
    },
    [field.name],
  );

  const handleChange = (e: any) => {
    onChange?.(e);
    field.onChange?.(e);
  };

  const handleChangeSelect = (e: any, option: any) => {
    onChange?.(e, option);
    field.onChange?.(e, option);
  };

  const handleChangeSelectMulti = (selectedValues: any[], Soption?: any) => {
    const newSelectedOptions = selectedValues.map((val) => {
      const foundOption = field?.options?.find((option) => option.value === val);
      return foundOption ? foundOption.label : val;
    });

    setSelectedOptions(newSelectedOptions);
    onChange?.(selectedValues, Soption);
    field.onChange?.(selectedValues, Soption);
  };

  // const tagRenderFixed = (props: { value: string; label: any }) => {
  //   const onTagClose = () => {
  //     const newSelectedOptions = selectedOptions?.filter((option) => option !== props.label);
  //     setSelectedOptions(newSelectedOptions);
  //     handleChangeSelectMulti(
  //       newSelectedOptions,
  //       field.options
  //         ?.filter((option) => option.value !== props.value)
  //         .filter((e) => selectedOptions.includes(e.label)),
  //     );
  //   };

  //   return (
  //     <Tag closable onClose={() => onTagClose()}>
  //       {props?.label?.replace?.(regexp, '')}
  //     </Tag>
  //   );
  // };
  if (field.render)
    return field.render({
      value,
      onChange:
        field.type === 'multiselect'
          ? handleChangeSelectMulti
          : field.type === 'select'
          ? handleChangeSelect
          : handleChange,
    });

  switch (field.type) {
    case 'select':
      return field.readOnly ? (
        <Input
          readOnly
          value={getValueFromSelectToInput(value, field.options as [])}
          placeholder='Please select'
        />
      ) : (
        <Select
          defaultValue={field.initialValue}
          value={getDefaultValue(value, field.options as [])}
          placeholder={placeholder}
          options={field.options}
          onChange={handleChangeSelect}
          data-cy={String(field.name)}
          disabled={field.disabled}
        />
      );
    case 'multiSelect':
      return (
        <Select
          maxTagCount='responsive'
          disabled={field.disabled || field.readOnly}
          maxTagPlaceholder={maxTagPlaceholder}
          placeholder={placeholder}
          defaultValue={getDefaultValue(field.initialValue, field.options as [])}
          allowClear
          showSearch
          data-cy={String(field.name)}
          mode='multiple'
          className={field.readOnly ? 'readOnly-ant-multisel' : undefined}
          dropdownRender={customDropdownRender}
          value={getDefaultValue(value, field.options as [])} // Control the value of Select
          // tagRender={(props) => tagRenderFixed(props)}
          onChange={handleChangeSelectMulti}
        >
          {field.options?.map((option, index) => (
            <Option
              key={option.label}
              value={option.value}
              data-testid={`columns-options-id-${index}`}
              style={{ display: 'flex', alignItems: 'center', padding: '0px !important' }}
            >
              {renderOptionWithCheckboxMulti(option.label)}
            </Option>
          ))}
        </Select>
      );
    case 'radio':
      return (
        <Radio.Group
          defaultValue={field.initialValue}
          value={value}
          options={field.options}
          onChange={
            field.readOnly
              ? (e) => {
                  e.preventDefault();
                  return false;
                }
              : handleChange
          }
          data-cy={String(field.name)}
          disabled={field.disabled}
        />
      );
    case 'checkbox':
      return (
        <Checkbox
          name={String(field.name)}
          value={value}
          checked={value}
          style={{ pointerEvents: field.readOnly ? 'none' : undefined }}
          onChange={(e) => handleChange(e.target.checked)}
          data-cy={String(field.name)}
          disabled={field.disabled}
        >
          {field.label}
        </Checkbox>
      );
    case 'textarea':
      return (
        <Input.TextArea
          defaultValue={field.initialValue}
          value={value}
          placeholder={placeholder}
          autoSize={{ minRows: 4 }}
          onChange={handleChange}
          data-cy={String(field.name)}
          ref={autoFocus}
          disabled={field.disabled}
          readOnly={field.readOnly}
        />
      );
    case 'file':
      return <FileInput accept={field.accept} value={value} onChange={handleChange} />;
    case 'addresses':
      return (
        <Addresses
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'phones':
      return (
        <Phones
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'emails':
      return (
        <Emails
          value={value}
          onChange={handleChange}
          readOnly={field.readOnly}
          formName={field.formName}
        />
      );
    case 'dateRange':
      return (
        <DatePicker.RangePicker
          value={value}
          onChange={handleChange}
          disabled={field.disabled}
          data-cy={String(field.name)}
        />
      );
    case 'number':
      return (
        <Input
          defaultValue={field.initialValue}
          value={value}
          type='number'
          maxLength={field.maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={field.disabled}
          readOnly={field.readOnly}
        />
      );
    default:
      return (
        <Input
          defaultValue={field.initialValue}
          value={value}
          maxLength={field.maxLength}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={field.disabled}
          readOnly={field.readOnly}
          data-cy={String(field.name)}
          ref={autoFocus}
        />
      );
  }
};

export default GenericInput;
