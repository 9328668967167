import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, CountryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GetAllCountriesResponse, GetAllCountries } from '../types';
import GET_ALL from '../graphql/queries';
import { CREATE_COUNTRY, DELETE_COUNTRY, UPDATE_COUNTRY } from '../graphql/mutations';

const useCountryTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [load, queryResponse] = useLazyQuery<GetAllCountries>(GET_ALL, {
    fetchPolicy: 'network-only',
  });

  const [loadFilter] = useLazyQuery<GetAllCountries>(GET_ALL, {
    fetchPolicy: 'network-only',
  });

  const [updateData] = useMutation(UPDATE_COUNTRY);
  const [createData] = useMutation(CREATE_COUNTRY);
  const [removeData] = useMutation(DELETE_COUNTRY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    load({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCountries2.results || [],
      total: res.data?.GetAllCountries2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    loadFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },

          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllCountries2.results as any[],
          filter: res?.data?.GetAllCountries2.results?.map((result: GetAllCountriesResponse) => ({
            value: result[searchField],
            label: result[searchField],
          })) as Option[],
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const createNewRecord = async () => {
    const { name } = newRecord;
    if (name === '') return;
    await createData({
      variables: {
        createCountryInput: {
          name: capitalizeFirstWord(name),
        },
      },
    }).then((country) => {
      setDataSource([
        { id: country.data.CreateCountry.id, name: country.data.CreateCountry.name },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: CountryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      updateData({
        variables: {
          updateCountryInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CountryDbModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => removeData({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleClearFilter = () => {
    reset();
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: queryResponse.loading || deleting,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useCountryTable;
