import React, { useContext } from 'react';
import { Typography, Progress, Form } from 'antd';
import { CompaniesContext } from 'src/context/companies.context';
import BasicInfoRenderer from 'src/components/commons/createCompany/internalComponents/basicInfo';
import DetailsRenderer from 'src/components/commons/createCompany/internalComponents/details';
import CommentsRenderer from 'src/components/commons/createCompany/internalComponents/comments';
import Industry from 'src/components/commons/createCompany/internalComponents/industry';
import ProductPotentialsRenderer from 'src/components/commons/createCompany/internalComponents/productPotentials';
import CustomButton from 'src/components/commons/customButton';
import { CreateCompanyStyles, CustomStyledInput } from './createCompany.styles';
import { CreateCompanyBodyRendererTypes, CreateCompanyHeaderRendererType } from './types';
import isRequired from './utils/functions';
import useCreateCompany from './hook/useCreateCompany';
import { CustomLoadingModal } from '../customModal';

const CreateCompanyHeaderRenderer: React.FC<CreateCompanyHeaderRendererType> = ({
  percentage = 0,
}) => (
  <>
    <CreateCompanyStyles />
    <div className='new-company-modal-header-container' data-testid='new-company-modal-title'>
      <div>
        <Typography.Text style={{ fontFamily: 'Libre Franklin, sans-serif', fontSize: '24px' }}>
          New Company
        </Typography.Text>
      </div>
      <div className='progress-bar'>
        <div className='progress-bar-title-container '>
          <Typography.Text type='secondary'>Progress</Typography.Text>
        </div>
        <div className='progress-bar-container'>
          <Progress percent={percentage} />
        </div>
      </div>
    </div>
  </>
);

const CreateCompanyBodyRenderer: React.FC<CreateCompanyBodyRendererTypes> = ({
  handleInputChange,
  handleTextAreaChange,
  handleSelectChange,
  validatingStatus,
  formElementsOptions,
  dependencyCompanyTypeHandler,
  dependencyCompanyType,
  handleMultiSelection,
  onRadioChangeEvent,
  addDateAndTimeIntoComments,
  separateComponentDataHandler,
  form,
  onCloseParentModel,
  openedParentModal,
  setOpenedParentModal,
  externalCountry,
  checkGroupHandler,
}) => (
  <Form layout='vertical' form={form}>
    <BasicInfoRenderer
      {...{ validatingStatus }}
      {...{ formElementsOptions }}
      {...{ handleInputChange }}
      {...{ separateComponentDataHandler }}
      {...{ externalCountry }}
    />
    <DetailsRenderer
      {...{ formElementsOptions }}
      {...{ handleInputChange }}
      {...{ dependencyCompanyTypeHandler }}
      {...{ handleSelectChange }}
      {...{ dependencyCompanyType }}
      {...{ onRadioChangeEvent }}
      {...{ handleTextAreaChange }}
      {...{ openedParentModal }}
      {...{ setOpenedParentModal }}
      {...{ onCloseParentModel }}
      {...{ handleMultiSelection }}
      {...{ checkGroupHandler }}
    />
    {/* Verify if division will be part of the form in the future */}
    {/* <Typography.Title level={4} className='section-title'>
      Divisions{' '}
    </Typography.Title> */}
    {/* TODO this feature will be enabled in the future */}
    {/* <Form.Item
      label='Tenant Division'
      name='tenantDivision'
      required={isRequired(formElementsOptions, 'tenantDivision')}
    >
      <CustomSelect
        customClassName='customSelect'
        placeHolder='Select a Tenant Division'
        handleChange={handleSelectChange}
        options={getOptions(formElementsOptions, 'tenantDivision')}
        externalName='tenantDivision'
        dataTestId='tenant-division-select'
      />
    </Form.Item> */}
    {/* <Form.Item
      label='Company Division'
      name='divisionsId'
      required={isRequired(formElementsOptions, 'divisions')}
    >
      <CustomSelect
        customClassName='customSelect'
        placeHolder='Select a Company Division'
        handleMultipleSelect={(value, name) => {
          const event = parseEventFromHeader(value, 'divisions') as unknown as string[];
          if (handleMultiSelection) {
            handleMultiSelection(event, name);
          }
        }}
        listDefault={getOptions(formElementsOptions, 'divisions').map((item) => item.label)}
        externalName='divisionsId'
        dataTestId='company-division-select'
        multiSel
        externalWidth='100%'
        allowClear
      />
    </Form.Item> */}
    <ProductPotentialsRenderer {...{ formElementsOptions }} {...{ handleMultiSelection }} />
    <Industry {...{ formElementsOptions }} {...{ handleMultiSelection }} />
    <CommentsRenderer
      {...{ addDateAndTimeIntoComments }}
      {...{ formElementsOptions }}
      {...{ handleTextAreaChange }}
    />
    {dependencyCompanyType?.showAditionalFields && (
      <>
        <Typography.Title level={4} style={{ color: 'rgb(0, 10, 104)' }}>
          Quote footer{' '}
        </Typography.Title>
        <Form.Item name='quoteFooter' required={isRequired(formElementsOptions, 'quoteFooter')}>
          <CustomStyledInput.TextArea
            onChange={handleTextAreaChange}
            rows={4}
            style={{ borderRadius: 0 }}
            name='quoteFooter'
          />
        </Form.Item>
        <Typography.Title level={4} style={{ color: 'rgb(0, 10, 104)' }}>
          PO footer{' '}
        </Typography.Title>
        <Form.Item name='poFooter' required={isRequired(formElementsOptions, 'poFooter')}>
          <CustomStyledInput.TextArea
            onChange={handleTextAreaChange}
            rows={4}
            style={{ borderRadius: 0 }}
            name='poFooter'
          />
        </Form.Item>
      </>
    )}
  </Form>
);

const CreateCommpanyFooterRenderer = () => {
  const { createCompany, createCompanyLoading } = useCreateCompany();
  const { allowCreateCompany, activeCreateCompany, createCompanyPayload } =
    useContext(CompaniesContext);

  const onSaveClick = () => {
    const payload = createCompanyPayload.current;
    createCompany(payload).then(activeCreateCompany);
  };

  return (
    <div className='new-company-modal-footer-container'>
      <CustomButton
        type='default'
        onClick={activeCreateCompany}
        dataTestId='button-cancel'
        noborder={false}
        title='Cancel'
      />

      <CustomButton
        type='primary'
        title='Save'
        disabled={!allowCreateCompany}
        tooltipColor='#CCCCCC'
        tooltipText={!allowCreateCompany && 'Please fill the required fields before saving'}
        tooltipClassName='custom-tooltip'
        dataTestId='button-save'
        onClick={onSaveClick}
      />

      {createCompanyLoading && <CustomLoadingModal modalStatus modalTitle='Creating company' />}
    </div>
  );
};

export { CreateCompanyHeaderRenderer, CreateCompanyBodyRenderer, CreateCommpanyFooterRenderer };
