import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import { FC } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';

type Props = { showTitle?: boolean };

const ContactFormNotes: FC<Props> = ({ showTitle = true }) => {
  const form = Form.useFormInstance();
  const { activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();

  const fields: FieldType[] = [
    {
      name: 'notes',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'textarea',
      span: 24,
    },
  ];

  const handleAddTimeClick = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formatted = `${month}/${day}/${year} - ${hours}:${minutes}`;
    const notes = form.getFieldValue('notes') || '';
    form.setFieldValue('notes', `${formatted}\n\n${notes}`);
  };

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection
      title='Notes'
      suffix={
        <Button shape='circle' type='link' onClick={handleAddTimeClick}>
          <ClockCircleOutlined />
        </Button>
      }
    >
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormNotes;
