import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SortModel } from 'src/components/commons/subTable/types';
import {
  ContactGroupModel,
  RelatedCategoryModel,
  RelatedCompanyDbModel,
} from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import get from 'lodash/get';
import COLUMNS, { StorageKey } from '../constants';
import {
  CREATE_RELATED_COMPANY,
  DELETE_RELATED_COMPANY,
  UPDATE_RELATED_COMPANY,
} from '../graphql/mutations';
import GET_ALL_RELATED_COMPANIES, { GET_ALL_RELATED_CATEGORIES } from '../graphql/queries';
import { GetAllRelatedCategories, GetAllRelatedCompanies } from '../types';
import useFilterColumns from './useFilterColumns';

const useCompanyRelated = () => {
  const { createdFilters, setColumnFilters } = useFilterColumns();
  const { id: companyID } = useParams();
  const localStorageExpandableRows = JSON.parse(
    localStorage.getItem(StorageKey.expandableRows) || '[]',
  );

  const [deleting, setDeleting] = useState(false);
  const [categories, setCategories] = useState<RelatedCategoryModel[] | undefined>([]);
  const [getAllRelatedCompaniesByCompanyId] = useLazyQuery<GetAllRelatedCompanies>(
    GET_ALL_RELATED_COMPANIES,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [getRelatedCategories] = useLazyQuery<GetAllRelatedCategories>(GET_ALL_RELATED_CATEGORIES);

  const [create] = useMutation(CREATE_RELATED_COMPANY);
  const [update] = useMutation(UPDATE_RELATED_COMPANY);
  const [remove] = useMutation(DELETE_RELATED_COMPANY);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState<{
    company: string | undefined;
    relatedCompany: string | null;
    relatedCompanyId: number | null;
    relatedCategory: number | string | null;
    valueRole: string;
    companyManufacturer: any;
  }>({
    company: companyID,
    relatedCompany: null,
    relatedCompanyId: null,
    relatedCategory: null,
    valueRole: '',
    companyManufacturer: null,
  });

  const fetchCompanyRelatedPage: FetchFunc = ({ start, renderLen }) =>
    getAllRelatedCompaniesByCompanyId({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        company: Number(companyID),
      },
    }).then((res) => ({
      results: res.data?.GetAllRelatedCompanies.results || [],
      total: res.data?.GetAllRelatedCompanies.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } =
    useInfinityDataSource(fetchCompanyRelatedPage);

  useEffect(() => {
    const fetchData = async () => {
      getRelatedCategories().then((res) => {
        const copiedColumns = [...COLUMNS].map((el) => {
          if (el.title === 'CATEGORY') {
            return {
              ...el,
              options: res.data?.GetAllRelatedCategories.results.map((category) => ({
                VALUE: category.name,
                LABEL: category.name,
              })),
            };
          }
          return el;
        });

        setCategories(res.data?.GetAllRelatedCategories.results);

        setColumns(copiedColumns as []);
      });
    };
    fetchData();
  }, []);

  const autocompleteFieldSearch = (searchField: string, search: string) => {
    let filterData = [];
    let mapData = [];

    const filters = createdFilters(searchField, search);

    filterData = dataSource.filter((item: any) => {
      const hasAllFilters = filters.every((filter) => {
        const cellValue = get(item, filter.fieldName);
        return cellValue.toLocaleLowerCase().includes(filter.value.toLocaleLowerCase());
      });

      return hasAllFilters;
    });

    if (filterData.length) {
      mapData = filterData.map((result: any) => {
        if (searchField.includes('.')) {
          const keys = searchField.split('.');
          const [keyParent, keyPropertyParent] = keys;
          return {
            value: result[keyParent][keyPropertyParent],
            label: result[keyParent][keyPropertyParent],
          };
        }

        return {
          value: result[searchField],
          label: result[searchField],
        };
      });

      return Promise.resolve({
        results: filterData,
        filter: mapData,
      });
    }

    return Promise.resolve({
      results: [],
      filter: [],
    });
  };

  const resetNewRecord = () => {
    setNewRecord({
      company: companyID,
      relatedCompany: null,
      relatedCompanyId: null,
      relatedCategory: null,
      valueRole: '',
      companyManufacturer: null,
    });
  };

  const createNewRecord = async () => {
    if (
      newRecord.company === '' ||
      newRecord.relatedCompany === null ||
      newRecord.relatedCategory === null
    ) {
      return;
    }

    categories?.forEach((category) => {
      if (category.name === newRecord.relatedCategory) {
        newRecord.relatedCategory = category.id;
      }
    });

    const inputs: {
      company: number;
      type: string;
      relatedCompany: number;
      relatedCategory: number;
      valueRole: string;
      companyManufacturer?: number;
      customerSplit?: string;
    } = {
      company: Number(newRecord.company),
      type: '',
      relatedCompany: Number(newRecord.relatedCompanyId),
      relatedCategory: newRecord.relatedCategory as number,
      valueRole: newRecord.valueRole,
    };

    // Only set companyManufacturer if the user filled the customerSplit field
    if (
      localStorageExpandableRows?.companyManufacturer &&
      localStorageExpandableRows?.customerSplit
    ) {
      if (localStorageExpandableRows?.companyManufacturer) {
        inputs.companyManufacturer = localStorageExpandableRows?.companyManufacturer[0].id;
      }

      if (localStorageExpandableRows?.customerSplit) {
        inputs.customerSplit = localStorageExpandableRows?.customerSplit;
      }
    }

    setDeleting(true);
    await create({
      variables: {
        createRelatedCompanyInput: inputs,
      },
    })
      .then((res) => {
        const newRecordToAdd: any = {
          id: res.data.CreateRelatedCompany.id,
          relatedCompany: {
            id: res.data.CreateRelatedCompany.relatedCompany.id,
            name: res.data.CreateRelatedCompany.relatedCompany.name,
          },
          relatedCategory: {
            id: res.data.CreateRelatedCompany.relatedCategory.id,
            name: res.data.CreateRelatedCompany.relatedCategory.name,
          },
          valueRole: res.data.CreateRelatedCompany.valueRole,
        };

        if (
          res.data.CreateRelatedCompany.companyManufacturer &&
          res.data.CreateRelatedCompany.customerSplit
        ) {
          newRecordToAdd.companyManufacturer = {
            id: res.data.CreateRelatedCompany.companyManufacturer?.id,
            name: res.data.CreateRelatedCompany.companyManufacturer?.name,
          };

          newRecordToAdd.customerSplit = res.data.CreateRelatedCompany.customerSplit;
        }

        setDataSource([newRecordToAdd, ...dataSource]);
        resetNewRecord();
        setDeleting(false);
        localStorage.removeItem(StorageKey.expandableRows);
      })
      .catch((err) => {
        handleError(err);
        resetNewRecord();
        setDataSource([...dataSource]);
        setDeleting(false);
        localStorage.removeItem(StorageKey.expandableRows);
      });
  };

  const handleUpdateRows = (rows: RelatedCompanyDbModel[]) => {
    rows.forEach((row) => {
      const { id, company } = row;
      update({
        variables: {
          updateRelatedCompanyInput: {
            id,
            name: capitalizeFirstWord(company?.name as string),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: ContactGroupModel[]) => {
    setDeleting(true);
    await Promise.all(
      rows.map((row) =>
        remove({ variables: { removeRelatedCompanyId: row.id } }).catch((err) => {
          setDeleting(false);
          handleError(err);
          throw err;
        }),
      ),
    );
    setDeleting(false);
    reset();
  };

  const postData = (args: any) => {
    if (args?.dataIndex === 'relatedCategory') {
      if (args?.value?.toLowerCase() === 'commission split') {
        setNewRecord((prev) => ({
          ...prev,
          companyManufacturer: [],
          activeNewRowExpandableOnNewItem: true,
          [args?.dataIndex as string]: args?.value as string,
          autoSave: !!args?.autoSave,
        }));
      } else {
        setNewRecord((prev) => ({
          ...prev,
          companyManufacturer: null,
          activeNewRowExpandableOnNewItem: false,
          [args?.dataIndex as string]: args?.value as string,
          autoSave: !!args?.autoSave,
        }));
      }
    }

    if (args?.dataIndex === 'relatedCompany') {
      setNewRecord((prev) => ({
        ...prev,
        relatedCompanyId: args?.value[0]?.id,
        relatedCompany: args?.value[0]?.label,
        autoSave: !!args?.autoSave,
      }));

      return;
    }

    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handleClearFilter = () => {
    setColumnFilters([]);
    reset(dataSource.length);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: deleting,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useCompanyRelated;
