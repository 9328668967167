import { Link } from 'react-router-dom';

export type ItemType = {
  link?: string;
  label: string | JSX.Element;
  key: string;
  type?: string;
  children?: ItemType[];
};

export const items: ItemType[] = [
  {
    link: '/companies',
    label: 'Companies',
    key: 'companies',
  },
  {
    link: '/contacts',
    label: 'Contacts',
    key: 'contacts',
  },
  // {
  //   link: '/opportunities',
  //   label: 'Opportunities',
  //   key: 'opportunities',
  // },
  {
    link: '/activity',
    label: 'Activity Journals',
    key: 'activity-journals',
  },
  // {
  //   link: '/tasks',
  //   label: 'Tasks',
  //   key: 'tasks',
  // },
  // {
  //   link: '/messages',
  //   label: 'Messages',
  //   key: 'messages',
  // },
  // {
  //   link: '/email',
  //   label: 'Email',
  //   key: 'email',
  // },
  // {
  //   link: '/calendar',
  //   label: 'Calendar',
  //   key: 'calendar',
  // },
  // {
  //   link: '/planner',
  //   label: 'Planner',
  //   key: 'planner',
  // },
  // {
  //   link: '/goals',
  //   label: 'Goals',
  //   key: 'goals',
  // },
  // {
  //   label: 'More CRM',
  //   key: 'more-crm',
  //   children: [
  //     {
  //       link: '/more_crm/quotes',
  //       label: 'Quotes',
  //       key: 'more-crm-quotes',
  //     },
  //     {
  //       link: '/more_crm/purchase_orders',
  //       label: 'Purchase Orders',
  //       key: 'more-crm-purchase-orders',
  //     },
  //     {
  //       link: '/more_crm/samples',
  //       label: 'Samples',
  //       key: 'more-crm-samples',
  //     },
  //     {
  //       link: '/more_crm/lend_items',
  //       label: 'Lend Items',
  //       key: 'more-crm-lend-items',
  //     },
  //     {
  //       link: '/more_crm/Jobs',
  //       label: 'Jobs',
  //       key: 'more-crm-jobs',
  //     },
  //     {
  //       link: '/more_crm/projects',
  //       label: 'Projects',
  //       key: 'more-crm-projects',
  //     },
  //     {
  //       link: '/more_crm/account_registrations',
  //       label: 'Account Registrations',
  //       key: 'more-crm-account-registrations',
  //     },
  //   ],
  // },
  // {
  //   label: 'Reporting',
  //   key: 'reporting',
  //   children: [
  //     {
  //       label: 'Sales Reports',
  //       key: 'reporting-sales-reports',
  //       children: [
  //         {
  //           link: '/reporting/sales_reports/customer_details',
  //           label: 'Customer Details',
  //           key: 'reporting-sales-reports-customer-details',
  //         },
  //         {
  //           link: '/reporting/sales_reports/customer_summary',
  //           label: 'Customer Summary',
  //           key: 'reporting-sales-reports-customer-summary',
  //         },
  //         {
  //           link: '/reporting/sales_reports/product_details',
  //           label: 'Product Details',
  //           key: 'reporting-sales-reports-product-details',
  //         },
  //         {
  //           link: '/reporting/sales_reports/product_sales_history',
  //           label: 'Product Sales History',
  //           key: 'reporting-sales-reports-product-sales-history',
  //         },
  //         {
  //           link: '/reporting/sales_reports/sales_by_month',
  //           label: 'Sales By Month',
  //           key: 'reporting-sales-reports-sales-by-month',
  //         },
  //         {
  //           link: '/reporting/sales_reports/sales_comparison',
  //           label: 'Sales Comparison',
  //           key: 'reporting-sales-reports-sales-comparison',
  //         },
  //         {
  //           link: '/reporting/sales_reports/credit_sales_summary',
  //           label: 'Credit Sales Summary',
  //           key: 'reporting-sales-reports-credit-sales-summary',
  //         },
  //         {
  //           link: '/reporting/sales_reports/product_line_page',
  //           label: 'Mfg/Product Line Page',
  //           key: 'reporting-sales-reports-mfg-product-line-page',
  //         },
  //         {
  //           link: '/reporting/sales_reports/line_overview',
  //           label: 'Line Overview',
  //           key: 'reporting-sales-reports-line-overview',
  //         },
  //         {
  //           link: '/reporting/sales_reports/plan_vs_actual',
  //           label: 'Plan vs Actual',
  //           key: 'reporting-sales-reports-plan-vs-actual',
  //         },
  //         {
  //           link: '/reporting/sales_reports/sales_analytical_summary',
  //           label: 'Sales Analytical Summary',
  //           key: 'reporting-sales-reports-sales-analytical-summary',
  //         },
  //         {
  //           link: '/reporting/sales_reports/product_line_summary',
  //           label: 'Mfg/Product Line Summary',
  //           key: 'reporting-sales-reports-mfg-product-line-summary',
  //         },
  //         {
  //           link: '/reporting/sales_reports/last_sales_report',
  //           label: 'Last Sales Report',
  //           key: 'reporting-sales-reports-last-sales-report',
  //         },
  //         {
  //           link: '/reporting/sales_reports/divisional_report',
  //           label: 'Divisional Report',
  //           key: 'reporting-sales-reports-divisional-report',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'CRM Reports',
  //       key: 'reporting-crm-reports',
  //       children: [
  //         {
  //           link: '/reporting/crm_reports/funnel_report',
  //           label: 'Funnel Report',
  //           key: 'reporting-crm-reports-funnel-report',
  //         },
  //         {
  //           link: '/reporting/crm_reports/salesperson_activity',
  //           label: 'Salesperson Activity',
  //           key: 'reporting-crm-reports-salesperson-activity',
  //         },
  //         {
  //           link: '/reporting/crm_reports/opportunity_report',
  //           label: 'Opportunity Report',
  //           key: 'reporting-crm-reports-opportunity-report',
  //         },
  //         {
  //           link: '/reporting/crm_reports/opportunity_history',
  //           label: 'Opportunity History',
  //           key: 'reporting-crm-reports-opportunity-history',
  //         },
  //         {
  //           link: '/reporting/crm_reports/opportunity_sync_changes',
  //           label: 'Opportunity Sync Changes',
  //           key: 'reporting-crm-reports-opportunity-sync-changes',
  //         },
  //         {
  //           link: '/reporting/crm_reports/forecast_vs_booking',
  //           label: 'Forecast vs Booking',
  //           key: 'reporting-crm-reports-forecast-vs-booking',
  //         },
  //       ],
  //     },
  //     {
  //       label: 'Commission Reports',
  //       key: 'reporting-commission-reports',
  //       children: [
  //         {
  //           link: '/reporting/commission_reports/sales_rep_summary',
  //           label: 'Sales Rep Summary',
  //           key: 'reporting-commission-reports-sales-rep-summary',
  //         },
  //         {
  //           link: '/reporting/commission_reports/sales_rep_detail',
  //           label: 'Sales Rep Detail',
  //           key: 'reporting-commission-reports-sales-rep-detail',
  //         },
  //         {
  //           link: '/reporting/commission_reports/comm_by_month',
  //           label: 'Sales/Comm By Month',
  //           key: 'reporting-commission-reports-sales-comm-by-month',
  //         },
  //         {
  //           link: '/reporting/commission_reports/comm_comparison',
  //           label: 'Sales/Comm Comparison',
  //           key: 'reporting-commission-reports-sales-comm-comparison',
  //         },
  //         {
  //           link: '/reporting/commission_reports/comm_by_product',
  //           label: 'Sales/Comm By Product',
  //           key: 'reporting-commission-reports-sales-comm-by-product',
  //         },
  //         {
  //           link: '/reporting/commission_reports/ytd_sales_comparison',
  //           label: 'YTD Sales Comparison',
  //           key: 'reporting-commission-reports-ytd-sales-comparison',
  //         },
  //         {
  //           link: '/reporting/commission_reports/line_overview',
  //           label: 'Line Overview',
  //           key: 'reporting-commission-reports-line-overview',
  //         },
  //         {
  //           link: '/reporting/commission_reports/commission_expanded',
  //           label: 'Commission Expanded',
  //           key: 'reporting-commission-reports-commission-expanded',
  //         },
  //         {
  //           link: '/reporting/commission_reports/commission_summary',
  //           label: 'Commission Summary',
  //           key: 'reporting-commission-reports-commission-summary',
  //         },
  //         {
  //           link: '/reporting/commission_reports/commission_details',
  //           label: 'Commission Details',
  //           key: 'reporting-commission-reports-commission-details',
  //         },
  //         {
  //           link: '/reporting/commission_reports/po_backlog_report',
  //           label: 'PO Backlog Report',
  //           key: 'reporting-commission-reports-po-backlog-report',
  //         },
  //         {
  //           link: '/reporting/commission_reports/cash_flow_forecast',
  //           label: 'Cash Flow Forecast',
  //           key: 'reporting-commission-reports-cash-flow-forecast',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   label: 'Data Management',
  //   key: 'data-management',
  //   children: [
  //     {
  //       link: '/data_management/import_transactions',
  //       label: 'Import Transactions',
  //       key: 'data-management-import-transactions',
  //     },
  //     {
  //       link: '/data_management/commissionable_transactions',
  //       label: 'Commissionable Transactions',
  //       key: 'data-management-commissionable-transactions',
  //     },
  //     {
  //       link: '/data_management/internal_pn_splits',
  //       label: 'Internal PN Splits',
  //       key: 'data-management-internal-pn-splits',
  //     },
  //     {
  //       link: '/data_management/customer_exceptions',
  //       label: 'Customer Exceptions',
  //       key: 'data-management-customer-exceptions',
  //     },
  //     {
  //       link: '/data_management/po_exceptions',
  //       label: 'PO Exceptions',
  //       key: 'data-management-po-exceptions',
  //     },
  //     {
  //       link: '/data_management/commission_split_exceptions',
  //       label: 'Commission Split Exceptions',
  //       key: 'data-management-commission-split-exceptions',
  //     },
  //     {
  //       link: '/data_management/region_exception',
  //       label: 'Region Exception',
  //       key: 'data-management-region-exception',
  //     },
  //     {
  //       link: '/data_management/import_crm_info',
  //       label: 'Import CRM Info',
  //       key: 'data-management-import-crm-info',
  //     },
  //     {
  //       link: '/data_management/commission_data_overview',
  //       label: 'Commission Data Overview',
  //       key: 'data-management-commission-data-overview',
  //     },
  //     {
  //       link: '/data_management/sales_data_overview',
  //       label: 'Sales Data Overview',
  //       key: 'data-management-sales-data-overview',
  //     },
  //     {
  //       link: '/data_management/products_master',
  //       label: 'Products Master',
  //       key: 'data-management-products-master',
  //     },
  //     {
  //       link: '/data_management/product_crosses',
  //       label: 'Product Crosses',
  //       key: 'data-management-product-crosses',
  //     },
  //     {
  //       link: '/data_management/customer_products',
  //       label: 'Customer Products',
  //       key: 'data-management-customer-products',
  //     },
  //   ],
  // },
  {
    label: 'Settings',
    key: 'settings',
    children: [
      {
        link: '/user-setup',
        label: 'Users',
        key: 'settings-users',
      },
      {
        link: '/settings/sales_teams',
        label: 'Sales Teams',
        key: 'settings-sales-teams',
      },
      {
        link: '/settings/private_teams',
        label: 'Private Teams',
        key: 'settings-private-teams',
      },
      {
        link: '/settings/divisions',
        label: 'Divisions',
        key: 'settings-divisions',
      },
      {
        link: '/settings/subtables/companies/type',
        label: 'Subtables',
        key: 'settings-subtables',
      },
      {
        link: '/settings/application_settings/custom_fields',
        label: 'Application settings',
        key: 'application-settings-custom-fields',
      },
    ],
  },
  // {
  //   link: '/expenses',
  //   label: 'Expenses',
  //   key: 'expenses',
  // },
];

const parseItem: (item: ItemType) => ItemType = (item: ItemType) => ({
  ...item,
  label: item.link ? <Link to={item.link}>{item.label}</Link> : item.label,
  'data-label': item.label,
  children: item?.children?.map((child) => parseItem(child)),
});

const menuItems = items.map((item) => parseItem(item));

export default menuItems;
