import { gql } from '@apollo/client';

export const CREATE_PRICE_CODES = gql`
  mutation CreatePriceCode($createPriceCodeInput: CreatePriceCodeInput!) {
    CreatePriceCode(createPriceCodeInput: $createPriceCodeInput) {
      id
      manufacturer {
        id
        name
      }
      code
      description
    }
  }
`;

export const UPDATE_PRICE_CODES = gql`
  mutation UpdatePriceCode($updatePriceCodeInput: UpdatePriceCodeInput!) {
    UpdatePriceCode(updatePriceCodeInput: $updatePriceCodeInput) {
      id
    }
  }
`;
export const REMOVE_PRICE_CODES = gql`
  mutation RemovePriceCode($id: Int!) {
    RemovePriceCode(id: $id)
  }
`;
