import { gql } from '@apollo/client';

export const CREATE_SALES_TEAM = gql`
  mutation CreateSalesTeam($createSalesTeamInput: CreateSalesTeamInput!) {
    CreateSalesTeam(createSalesTeamInput: $createSalesTeamInput) {
      id
      name
      companySplit
      specifierSplit
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      companySplitType {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const REMOVE_SALES_TEAM = gql`
  mutation RemoveSalesTeam($id: Int!) {
    RemoveSalesTeam(id: $id)
  }
`;

export const UPDATE_SALES_TEAM = gql`
  mutation UpdateSalesTeam($updateSalesTeamInput: UpdateSalesTeamInput!) {
    UpdateSalesTeam(updateSalesTeamInput: $updateSalesTeamInput) {
      id
      name
      companySplit
      specifierSplit
      type {
        id
        name
        description
        createdAt
        updatedAt
      }
      companySplitType {
        id
        name
        description
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
