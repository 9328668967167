import { createGlobalStyle } from 'styled-components';

const CompanyDetailStyles = createGlobalStyle`

  .form-control {
    border-radius: 0 !important;
    width: 100% !important;
    height: 40px !important;
  }
`;

export default CompanyDetailStyles;
