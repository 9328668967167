import { ContactModel } from 'src/models';
import _ from 'lodash';

export const prepareContactCreationPayload = (contact: ContactModel) => {
  const innerContactData = JSON.parse(JSON.stringify(contact));
  // businessInfo
  const innerBusinessInfoAddress = innerContactData.businessInfo?.addresses;
  const innerBusinessInfoEmails = innerContactData.businessInfo?.emails;
  const innerBusinessInfoPhones = innerContactData.businessInfo?.phones;

  if (innerBusinessInfoAddress) {
    const filteredInnerBusinessInfoAddress = _.filter(
      innerBusinessInfoAddress,
      (obj: any) => obj.address,
    );
    innerContactData.businessInfo.addresses.shift();
    if (filteredInnerBusinessInfoAddress.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainAddress = filteredInnerBusinessInfoAddress[0];
    }
    if (innerContactData.businessInfo?.addresses?.length === 0) {
      delete innerContactData.businessInfo.addresses;
    }
  }
  if (innerBusinessInfoEmails) {
    const filteredInnerBusinessInfoEmails = _.filter(
      innerBusinessInfoEmails,
      (obj: any) => obj.email.length > 0,
    );
    innerContactData.businessInfo.emails.shift();
    if (filteredInnerBusinessInfoEmails.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainEmail = filteredInnerBusinessInfoEmails[0];
    }
    if (innerContactData.businessInfo?.emails?.length === 0) {
      delete innerContactData.businessInfo.emails;
    }
  }
  if (innerBusinessInfoPhones) {
    const filteredInnerBusinessInfoPhones = _.filter(
      innerBusinessInfoPhones,
      (obj: any) => obj.phone,
    );
    innerContactData.businessInfo.phones.shift();
    if (filteredInnerBusinessInfoPhones.length > 0) {
      // eslint-disable-next-line prefer-destructuring
      innerContactData.businessInfo.mainPhone = filteredInnerBusinessInfoPhones[0];
    }
    if (innerContactData.businessInfo?.phones?.length === 0) {
      delete innerContactData.businessInfo.phones;
    }
  }

  return innerContactData;
};
