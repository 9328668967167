import { Form, Input, InputRef } from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import StyledPitchForm from 'src/components/companyDetails/components/pitches/styles/pitchForm.styles';

const AddSalesTeam: FC<Record<any, any>> = (props) => {
  const { validateNameUnique } = props;
  const inputRef = useRef<InputRef>(null);
  const [commission, setCommission] = useState(0);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const handleCommissionInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (value > 0 && value < 100) setCommission(value);
  };

  const validateNumber = async (_: any, value: any) => {
    if (Number(value) <= 0 || Number(value) > 100) {
      return Promise.reject(new Error('The value must be greater than 0 and not exceed 100.'));
    }
    if (!/^\d+(\.\d{0,2})?$/.test(value)) {
      return Promise.reject(new Error('The value must have at most 2 decimal places.'));
    }
    return Promise.resolve();
  };
  return (
    <StyledPitchForm>
      <Form.Item
        style={{
          margin: 0,
        }}
        name='name'
        label='Sales Team Name'
        className='name'
        rules={[
          {
            required: true,
            message: 'This is a required field',
          },
          {
            validator: validateNameUnique,
          },
        ]}
        hasFeedback
      >
        <Input
          ref={inputRef}
          autoComplete='off'
          className='input-new-record-js'
          placeholder='Type here...'
        />
      </Form.Item>
      <Form.Item
        style={{
          margin: 0,
        }}
        name='companySplit'
        label='Company Split %'
        className='name'
        rules={[
          {
            required: true,
            message: 'This is a required field',
          },
          {
            validator: validateNumber,
          },
        ]}
        hasFeedback
      >
        <Input
          onChange={handleCommissionInput}
          type='number'
          min={0}
          max={100}
          autoComplete='off'
          className='input-new-record-js'
          placeholder='Type here...'
        />
      </Form.Item>
      <div>
        <p style={{ marginBottom: '0.2rem' }}>
          Sales Team Split %<span style={{ color: 'red' }}>*</span>
        </p>
        <Input
          disabled
          value={100 - commission}
          style={{ border: 'none' }}
          autoComplete='off'
          className='input-new-record-js'
          placeholder='Type here...'
        />
      </div>
    </StyledPitchForm>
  );
};

export default AddSalesTeam;
