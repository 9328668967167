import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, TerritoryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GET_ALL_TERRITORIES, GET_TERRITORY_HEADERS } from '../graphql/queries';
import { UPDATE_TERRITORY, CREATE_TERRITORY, REMOVE_TERRITORY } from '../graphql/mutations';
import { GetAllTerritories, GetAllTerritoriesResponse, GetTerritoryGridHeaders } from '../types';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';

const useTabTerritoryTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, queryResponse] = useLazyQuery<GetAllTerritories>(GET_ALL_TERRITORIES, {
    fetchPolicy: 'network-only',
  });
  const [getAllSearch] = useLazyQuery<GetAllTerritories>(GET_ALL_TERRITORIES, {
    fetchPolicy: 'network-only',
  });
  const [getHeaders] = useLazyQuery<GetTerritoryGridHeaders>(GET_TERRITORY_HEADERS, {
    fetchPolicy: 'network-only',
  });
  const [update] = useMutation(UPDATE_TERRITORY);
  const [create] = useMutation(CREATE_TERRITORY);
  const [remove] = useMutation(REMOVE_TERRITORY);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  useEffect(() => {
    getHeaders().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetTerritoryGridHeaders as unknown as SubTableColumn[],
      ).map((header) => {
        if (header.dataIndex === 'name')
          return {
            ...header,
            headerName: 'NAME',
            title: 'NAME',
          };
        return header;
      });
      setColumns(COLUMNS ?? filteredResult);
    });
  }, []);

  const fetchTerritoryPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllTerritories2.results || [],
      total: res.data?.GetAllTerritories2.total || 0,
    }));

  const { dataSource, onListRender, setDataSource, reset } =
    useInfinityDataSource(fetchTerritoryPage);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    getAllSearch({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllTerritories2.results,
          filter: res?.data?.GetAllTerritories2?.results.map(
            (result: GetAllTerritoriesResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createTerritoryInput: {
          name: capitalizeFirstWord(newRecord.name),
        },
      },
    }).then((territory) => {
      setDataSource([
        { id: territory.data.CreateTerritory.id, name: territory.data.CreateTerritory.name },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: TerritoryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateTerritoryInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: TerritoryDbModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  const handleClearFilter = () => {
    reset();
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: queryResponse.loading || deleting,
    newRecord,
    handleNewRecord,
    resetNewRecord,
    postNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useTabTerritoryTable;
