import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { CompanyIndustryDbModel } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GetAllCompanyIndustriesResponse, GetAllCompanyIndustries } from '../types';
import GET_ALL_INDUSTRY from '../graphql/queries';
import { CREATE_INDUSTRY, DELETE_INDUSTRY, UPDATE_INDUSTRY } from '../graphql/mutations';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useIndustryTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [deleting, setDeleting] = useState(false);
  const [loadIndustry, { loading }] = useLazyQuery<GetAllCompanyIndustries>(GET_ALL_INDUSTRY, {
    fetchPolicy: 'network-only',
  });

  const [loadIndustryFilter] = useLazyQuery<GetAllCompanyIndustries>(GET_ALL_INDUSTRY, {
    fetchPolicy: 'network-only',
  });

  const [create] = useMutation(CREATE_INDUSTRY);
  const [update] = useMutation(UPDATE_INDUSTRY);
  const [remove] = useMutation(DELETE_INDUSTRY);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const uploadData: FetchFunc = ({ start, renderLen }) =>
    loadIndustry({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllIndustries2.results || [],
      total: res.data?.GetAllIndustries2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return loadIndustryFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },

          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllIndustries2.results as any[],
      filter: res?.data?.GetAllIndustries2.results?.map(
        (result: GetAllCompanyIndustriesResponse) => ({
          value: result[searchField],
          label: result[searchField],
        }),
      ) as Option[],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createCompanyIndustryInput: {
          name: capitalizeFirstWord(name),
          description,
        },
      },
    }).then((customType) => {
      setDataSource([
        {
          id: customType.data.CreateIndustry.id,
          name: customType.data.CreateIndustry.name,
          description: customType.data.CreateIndustry.description,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: CompanyIndustryDbModel[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateCompanyIndustryInput: {
            id,
            name: capitalizeFirstWord(name!),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyIndustryDbModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    onListRender,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    spinning: loading || deleting,
    postData,
    newRecord,
    createNewRecord,
    handleUpdateRows,
    resetNewRecord,
    dataSource,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useIndustryTable;
