import React from 'react';
import SubTable from 'src/components/commons/subTable';
import PageContainer from 'src/components/pageContainer/PageContainer';
import { CustomLoadingModal } from 'src/components/commons/customModal';
import StyleInfinityScroll from '../../../../styles/StyleInfinityScroll.style';
import useTabRegionCompanyTable from './hooks/useTabRegionCompanyTable';

const TabRegionCompanyTable = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    queryResponse,
    newRecord,
    postData,
    resetNewRecord,
    postNewRecord,
    handleClearFilter,
    handleDeleteRows,
  } = useTabRegionCompanyTable();

  return (
    <>
      {queryResponse.loading && <CustomLoadingModal modalStatus />}
      <PageContainer margin='2rem'>
        <StyleInfinityScroll>
          <SubTable
            data={dataSource}
            columns={columns}
            setColumns={setColumns}
            autocompleteFieldProvider={autocompleteFieldSearch}
            onFilterChange={handleFilterChange}
            onSortChange={handleSortModelChange}
            onListRender={onListRender}
            scroll={{ y: '60vh', x: '90vw' }}
            actionCell={['delete']}
            headerActions={['filter', 'edit', 'delete']}
            id='sub-table-category-company-table-id'
            showOptionFilterLeft={false}
            allowGlobalEditActive
            activeOnSelectRowsDisableEdit
            total
            showAutoIncrement
            handleUpdateRows={handleUpdateRows}
            onDeleteClick={handleDeleteRows}
            newRecord={newRecord}
            resetNewRecord={resetNewRecord}
            postData={postData}
            saveNewRecord={postNewRecord}
            storageKey='companyRegions'
            storageType='session'
            clearFilter={handleClearFilter}
            columnFilterShown
          />
        </StyleInfinityScroll>
      </PageContainer>
    </>
  );
};
export default TabRegionCompanyTable;
