import { gql } from '@apollo/client';

const GET_ALL_COMPANY_DIVISION = gql`
  query GetCompaniesFromElastic2(
    $criteria: CompanyCriteriaInput2!
    $searchFields: CompanyFindElasticInput2
  ) {
    GetCompaniesFromElastic2(Criteria: $criteria, SearchFields: $searchFields) {
      total
      results {
        companyType {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
  }
`;

export default GET_ALL_COMPANY_DIVISION;
