import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SortModel } from 'src/components/commons/subTable/types';
import { BuyGroupModel } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import COLUMNS from '../constants';
import {
  CREATE_PRODUCT_POTENTIAL,
  REMOVE_PRODUCT_POTENTIAL,
  UPDATE_PRODUCT_POTENTIAL,
} from '../graphql/mutations';
import GET_ALL_PRODUCT_POTENTIAL from '../graphql/queries';
import { GetAllProductPotentials, GetAllProductPotentialsResponse } from '../types';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useTabProductPotentialsTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [getAll, queryResponse] = useLazyQuery<GetAllProductPotentials>(GET_ALL_PRODUCT_POTENTIAL, {
    fetchPolicy: 'network-only',
  });

  const [getAllFilter] = useLazyQuery<GetAllProductPotentials>(GET_ALL_PRODUCT_POTENTIAL, {
    fetchPolicy: 'cache-and-network',
  });

  const [update] = useMutation(UPDATE_PRODUCT_POTENTIAL);
  const [create] = useMutation(CREATE_PRODUCT_POTENTIAL);
  const [remove] = useMutation(REMOVE_PRODUCT_POTENTIAL);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllProductPotentials.results || [],
      total: res.data?.GetAllProductPotentials.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(fetchPage);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllProductPotentials.results || [],
          filter: res?.data?.GetAllProductPotentials.results?.map(
            (result: GetAllProductPotentialsResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createProductPotentialInput: {
          name: capitalizeFirstWord(name),
          description,
        },
      },
    }).then((productPotential) => {
      setDataSource([
        {
          id: productPotential.data.CreateProductPotential.id,
          name: productPotential.data.CreateProductPotential.name,
          description: productPotential.data.CreateProductPotential.description,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateProductPotentialInput: {
            id,
            name: capitalizeFirstWord(name),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: BuyGroupModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    await Promise.allSettled(
      rows.map((row) =>
        remove({ variables: { removeProductPotentialId: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    );
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    handleDeleteRows,
    queryResponse,
    spinning: queryResponse.loading,
    postData,
    newRecord,
    createNewRecord,
    resetNewRecord,
    handleClearFilter,
  };
};

export default useTabProductPotentialsTable;
