export const getDefaultValue = (selectedPhoneType: any, options: any[]) => {
  if (!selectedPhoneType || selectedPhoneType.length === 0) return undefined;
  if (Array.isArray(selectedPhoneType)) {
    const result: any[] = [];
    selectedPhoneType.forEach((el) => {
      const foundOption = options?.find((opt) => opt.value === el);
      if (foundOption) {
        result.push(foundOption);
      } else {
        result.push('NA');
      }
    });
    return result;
  }
  const foundOption = options?.find((opt) => opt.value === selectedPhoneType);
  return foundOption ? selectedPhoneType : 'NA';
};

export const getValueFromSelectToInput = (selectedValue: any, options: any[]) => {
  if (!selectedValue || selectedValue.length === 0) return undefined;
  if (Array.isArray(selectedValue)) {
    const result: string[] = [];
    selectedValue.forEach((el) => {
      const foundOption = options?.find((opt) => opt.value === el);
      if (foundOption) {
        result.push(foundOption.label);
      }
    });
    return result.join(', ');
  }
  const foundOption = options?.find((opt) => opt.value === selectedValue);

  return foundOption?.label || 'NA';
};
