import { FC, useEffect, useState } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import useProductInterestStore from 'src/hooks/stores/useProductInterest';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import CustomSelect from 'src/components/commons/customSelect';

type Props = { showTitle?: boolean };

interface AutocompleteComponentProps {
  options: { value: number; label: string }[];
  onChange?: (e: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  options,
  onChange,
  readOnly,
  disabled,
}) => (
  <CustomSelect
    multiSel
    handleMultipleSelect={(_, opt) => onChange?.(opt as any)}
    listDefault={options}
    disabled={disabled || readOnly}
    externalWidth='100%'
    customClassName={readOnly ? 'readOnly-ant-multisel' : ''}
  />
);
const ContactFormProductInterest: FC<Props> = ({ showTitle = true }) => {
  const { dataSource } = useProductInterestStore();
  const [opt, setOpt] = useState<{ value: number; label: string }[] | []>([]);
  const { activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();

  useEffect(() => {
    const data = dataSource.map((dataopt) => ({
      value: dataopt.name,
      label: dataopt.name,
      toSend: dataopt.id,
    }));
    setOpt(data);
  }, [dataSource]);
  const fields: FieldType[] = [
    {
      name: 'productInterest',
      type: 'select',
      options: opt,
      render: () => (
        <AutocompleteComponent
          options={opt}
          readOnly={!activeExpandedEdition && !createContactModalActive}
        />
      ),
      span: 24,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Product Interest'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormProductInterest;
