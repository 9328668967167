import { Form, Input } from 'antd';
import React, { FC } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import { GQL } from 'src/graphql';
import { validateEmptyInput } from 'src/utils/functions/validateEmptyInput';

type Props = { showTitle?: boolean };

const ContactFormBasicInfo: FC<Props> = ({ showTitle = true }) => {
  const form = Form.useFormInstance();
  const firstName = Form.useWatch('firstName', form);
  const lastName = Form.useWatch('lastName', form);
  const { activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();

  const fields: FieldType<GQL.CreateContactInput>[] = [
    {
      name: 'firstName',
      label: 'First Name',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      rules: [{ required: true, message: 'First Name is required', validator: validateEmptyInput }],
      type: 'text',
      span: 24,
      autoFocus: true,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      rules: [{ required: false, message: 'Last Name is required' }],
      type: 'text',
      span: 24,
    },
    {
      name: 'fullName' as keyof GQL.CreateContactInput,
      label: 'Full Name',
      type: 'text',
      render: () => <Input value={`${firstName || ''} ${lastName || ''}`} disabled />,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Basic Information'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormBasicInfo;
