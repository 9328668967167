import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import { AllFilters2 } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import COLUMNS from '../constants/divisionConstants';
import UPDATE_COMPANY_DIVISION from '../graphql/mutations';
import GET_ALL_COMPANY_DIVISION from '../graphql/queries';
import { GetAllDivisionsResponse, GetAllDivisions } from '../types/divisionTypes';

const useDivisionTable = () => {
  const [getAllDivision, getAllDivisionResponse] = useLazyQuery<GetAllDivisions>(
    GET_ALL_COMPANY_DIVISION,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getAllFilter] = useLazyQuery<GetAllDivisions>(GET_ALL_COMPANY_DIVISION, {
    fetchPolicy: 'cache-and-network',
  });

  const [update] = useMutation(UPDATE_COMPANY_DIVISION);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAllDivision({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetCompaniesFromElastic2.results || [],
      total: res.data?.GetCompaniesFromElastic2.total || 0,
    }));

  const { dataSource, onListRender, reset } = useInfinityDataSource(fetchPage);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) =>
    getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: [
            {
              fieldName: searchField,
              value: search,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetCompaniesFromElastic2.results || [],
          filter: res?.data?.GetCompaniesFromElastic2.results?.map(
            (result: GetAllDivisionsResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const handleClearFilter = () => {
    reset();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { companyType, category } = row;
      update({
        variables: {
          updateBuyGroupInput: {
            companyType: {
              id: companyType?.id,
              name: capitalizeFirstWord(companyType?.name ?? 'no results'),
            },
            category: {
              id: category?.id,
              name: capitalizeFirstWord(category?.name ?? 'no results'),
            },
          },
        },
      });
    });
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    getAllDivisionResponse,
    handleClearFilter,
  };
};

export default useDivisionTable;
