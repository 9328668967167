import _ from 'lodash';

export const detectDiferences = (obj1: any, obj2: any): any => {
  if (_.isEqual(obj1, obj2)) {
    return {}; // Objects are equal, no differences
  }
  if (!_.isObject(obj1) || !_.isObject(obj2)) {
    return { obj1, obj2 }; // If either is not an object, they are different
  }
  const keys1 = _.keys(obj1);
  const keys2 = _.keys(obj2);
  const keys = _.union(keys1, keys2);
  const diff: any = {};
  keys.forEach((key) => {
    const val1 = obj1[key as keyof typeof obj1];
    const val2 = obj2[key as keyof typeof obj2];
    if (_.has(obj2, key) && !_.isEqual(val2, val1)) {
      if (_.isObject(val1) && _.isObject(val2)) {
        diff[key] = detectDiferences(val1, val2);
      } else {
        diff[key] = val2;
      }
    } else if (key === 'countryCode' && _.isEqual(val2, val1)) {
      diff[key] = val2;
    } else if (key === 'id' && _.isEqual(val2, val1)) {
      diff[key] = val2;
    } else if (key === 'isMain' && _.isEqual(val2, val1)) {
      diff[key] = val2;
    }
  });
  return diff;
};
