import { CompanyModel } from 'src/models';
import _ from 'lodash';

export const prepareDataToDelete = (element: [number], list: number[]) => {
  const innerList = _.cloneDeep(list);
  return _.xor(innerList, element);
};

export const filterCompanyEmailPhoneAddressInfo = (company: CompanyModel) => {
  const innerCompanyData = JSON.parse(JSON.stringify(company));
  const innerInfoAddress = innerCompanyData?.addresses;
  const innerInfoEmails = innerCompanyData?.emails;
  const innerInfoPhones = innerCompanyData?.phones;

  if (innerInfoAddress) {
    const filteredInnerAddress = _.filter(innerInfoAddress, (obj: any) => obj.address);
    delete innerCompanyData.addresses;
    if (filteredInnerAddress.length > 0) {
      filteredInnerAddress.forEach((address: any) => {
        if (address.id) {
          if (!innerCompanyData.updateAddresses) {
            innerCompanyData.updateAddresses = [address];
          } else {
            innerCompanyData.updateAddresses.push(address);
          }
        } else if (!innerCompanyData.addAddresses) {
          innerCompanyData.addAddresses = [address];
        } else {
          innerCompanyData.addAddresses.push(address);
        }
      });
    }
  }

  if (innerInfoEmails) {
    const filteredInnerEmails = _.filter(innerInfoEmails, (obj: any) => obj.email.length > 0);
    delete innerCompanyData.emails;
    if (filteredInnerEmails.length > 0) {
      filteredInnerEmails.forEach((email: any) => {
        if (email.id) {
          if (!innerCompanyData.updateEmails) {
            innerCompanyData.updateEmails = [email];
          } else {
            innerCompanyData.updateEmails.push(email);
          }
        } else if (!innerCompanyData.addEmails) {
          innerCompanyData.addEmails = [email];
        } else {
          innerCompanyData.addEmails.push(email);
        }
      });
    }
  }

  if (innerInfoPhones) {
    const filteredInnerPhones = _.filter(innerInfoPhones, (obj: any) => obj.phone);
    delete innerCompanyData.phones;
    if (filteredInnerPhones.length > 0) {
      filteredInnerPhones.forEach((phone: any) => {
        if (phone.id) {
          if (!innerCompanyData.edditedPhones) {
            innerCompanyData.updatePhones = [phone];
          } else {
            innerCompanyData.updatePhones.push(phone);
          }
        } else if (!innerCompanyData.addPhones) {
          innerCompanyData.addPhones = [phone];
        } else {
          innerCompanyData.addPhones.push(phone);
        }
      });
    }
  }

  return innerCompanyData;
};
