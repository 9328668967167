import { LeftOutlined } from '@ant-design/icons';
import { Button, Card, Drawer } from 'antd';
import { FC, ReactNode, useMemo, useState } from 'react';
import StyledCompanyContent from 'src/components/companyDetails/styles/companyContent.style';

const SIDEBAR_WIDTH = 448;

type Props = {
  content?: () => ReactNode;
  offCanvas?: () => ReactNode;
  header?: (isCollapsed: boolean) => ReactNode;
};

const OffCanvas: FC<Props> = ({
  content = () => false,
  offCanvas = () => false,
  header = () => false,
}) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);

  const contentStyle = useMemo(
    () => (open ? { marginRight: SIDEBAR_WIDTH } : { marginRight: 0 }),
    [open],
  );

  const toggleStyle = useMemo(
    () => (open ? { transform: 'rotate(-180deg)', right: SIDEBAR_WIDTH - 20 } : { right: 0 }),
    [open],
  );

  return (
    <StyledCompanyContent>
      <Card className='company-contact' style={contentStyle}>
        {header(open)}
      </Card>
      <Card className='content' style={contentStyle} data-testid='company-setup-content'>
        {content()}
      </Card>
      <Drawer
        width={SIDEBAR_WIDTH}
        placement='right'
        closable={false}
        onClose={onClose}
        open={open}
        getContainer={false}
        mask={false}
      >
        <p>{offCanvas()}</p>
      </Drawer>
      <Button
        className='toggle'
        onClick={() => setOpen(!open)}
        style={toggleStyle}
        data-testid='company-setup-drawer-toggler'
      >
        <LeftOutlined />
      </Button>
    </StyledCompanyContent>
  );
};

export default OffCanvas;
