import React from 'react';
import SubTable from 'src/components/commons/subTable';
import StyleInfinityScroll from 'src/modules/settings/modules/subTables/styles/StyleInfinityScroll.style';
import useDivisionTable from './hooks/useDivisionTable';

const CompanyDivision = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    handleClearFilter,
  } = useDivisionTable();

  return (
    <StyleInfinityScroll>
      <SubTable
        data={dataSource}
        columns={columns}
        setColumns={setColumns}
        autocompleteFieldProvider={autocompleteFieldSearch}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortModelChange}
        onListRender={onListRender}
        scroll={{ y: '40vh', x: '50vw' }}
        headerActions={['filter', 'edit']}
        id='sub-table-division-id'
        showOptionFilterLeft={false}
        allowGlobalEditActive
        total
        handleUpdateRows={handleUpdateRows}
        clearFilter={handleClearFilter}
        nonAddable
        columnFilterShown
      />
    </StyleInfinityScroll>
  );
};

export default CompanyDivision;
