import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Card, Collapse } from 'antd';
import { EyeInvisibleOutlined, HolderOutlined, EyeOutlined } from '@ant-design/icons';
import SToggleCollapse from './SectionCard.style';

type Props = PropsWithChildren<{ title: string; showEyeIcon?: boolean }>;

// Definir una interfaz para las props adicionales
interface ChildProps {
  handleCollapseToggle: () => void;
}

const SectionCard: FC<Props> = ({ title, showEyeIcon, children }) => {
  const [isComponentMounted, setIsComponentMounted] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const savedState = localStorage.getItem('sectionCollapsedState');
    const stateObj = savedState ? JSON.parse(savedState) : {};
    setIsCollapsed(
      Object.prototype.hasOwnProperty.call(stateObj, title) && stateObj[title] === false,
    );
    setIsComponentMounted(true);
  }, [title]);

  useEffect(() => {
    if (isComponentMounted) {
      const savedState = localStorage.getItem('sectionCollapsedState');
      const newState = savedState ? JSON.parse(savedState) : {};
      newState[title] = !isCollapsed;
      localStorage.setItem('sectionCollapsedState', JSON.stringify(newState));
    }
  }, [isCollapsed, title]);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { handleCollapseToggle, isCollapsed } as ChildProps);
    }
    return child;
  });

  const renderIcon = (showIcon?: boolean) => {
    if (!showIcon) {
      return isCollapsed ? <EyeOutlined /> : <EyeInvisibleOutlined />;
    }
    return isCollapsed ? <EyeOutlined /> : null;
  };

  return (
    <Card
      title={title}
      bordered={false}
      extra={
        <SToggleCollapse onClick={handleCollapseToggle}>{renderIcon(showEyeIcon)}</SToggleCollapse>
      }
      className={`section-card ${isCollapsed ? 'collapsed' : 'expanded'}`}
      style={{ marginBottom: '1em', border: 'none' }}
    >
      <HolderOutlined />
      <Collapse activeKey={!isCollapsed ? title : undefined}>
        <Collapse.Panel header={false} key={title}>
          {childrenWithProps}
        </Collapse.Panel>
      </Collapse>
    </Card>
  );
};

export default SectionCard;
