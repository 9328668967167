import { useEffect, useMemo, useState } from 'react';
import { CompaniesHeaderType2, CompanyHeaderOptionsType2 } from 'src/types/companiesTypes';
import { CountryData } from 'react-phone-input-2';
import { useLocation } from 'react-router';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import { CreatePhoneInput } from 'src/graphql/schema-types';
import { useContactContext } from 'src/context/contact.context';
import { useCompanyContext } from 'src/context/company.context';

export type Props = {
  value: CreatePhoneInput[];
  onChange: (value: CreatePhoneInput[]) => void;
  readOnly?: boolean;
  formName?: string;
};

const createNewPhone = () =>
  ({
    phone: undefined,
    extension: undefined,
    phoneType: undefined,
    areaCode: undefined,
    countryCode: '+1',
  } as unknown as CreatePhoneInput);

const getInitialValue = (value?: CreatePhoneInput[]) => {
  if (!value) {
    return [createNewPhone()];
  }
  if (!value?.length) {
    value.push(createNewPhone());
  }
  return value;
};

const usePhonesSubForm = ({ value, onChange, readOnly, formName }: Props) => {
  const location = useLocation();
  const [phones, setPhones] = useState(getInitialValue(value));
  const headers = useCompanyHeaders();
  const options = useMemo(
    () =>
      headers
        ?.find(({ dataIndex }: CompaniesHeaderType2) => dataIndex === 'mainPhone.phoneType')
        ?.options?.map(
          ({ id, name }: CompanyHeaderOptionsType2) =>
            ({
              value: id,
              label: name,
            } ?? []),
        ),
    [headers],
  );

  const { savePhonesToDelete } = useContactContext();
  const companyContext = useCompanyContext();
  const isCompany = location?.pathname?.includes('companies');

  useEffect(() => {
    setPhones(getInitialValue(value));
  }, [value]);

  useEffect(() => {
    if (value?.[0] && !value[0].phoneType) {
      value[0].phoneType = Number(options?.[0]?.value);

      setPhones(value);
    } else if (value?.[0]) {
      setPhones(value);
    }
  }, [options, value]);

  const handlePhoneChange = (
    innerValue: string,
    data: CountryData,
    event: React.ChangeEvent<HTMLInputElement>,
    formatted: string,
  ) => {
    const splitedFormatedPhone = formatted.split(' ');

    const countryCode = splitedFormatedPhone[0];
    const areaCode = splitedFormatedPhone[1]?.replace(/\(|\)/g, '');
    const phone = splitedFormatedPhone?.slice(2)?.join('').replace(/\(|\)/g, '').replace(/-/g, '');

    const index = Number(event.target.dataset.index);

    const newPhones = [...phones];
    newPhones[index] = {
      ...newPhones[index],
      phone,
      areaCode,
      countryCode,
    };

    if (index === 0) {
      newPhones[index].phoneType = Number(options?.[0]?.value);
    }

    setPhones(newPhones);
    onChange(newPhones);
  };

  const handleAddClick = (index: number) => {
    const newPhones = [...phones];
    newPhones.splice(index + 1, 0, createNewPhone());
    setPhones(newPhones);
    onChange(newPhones);
  };

  const handleRemovePhones = (id: number, index: number, type?: string) => {
    const newPhones = [...phones];
    if (id) {
      const innerIndex = newPhones.findIndex((phone) => phone.id === id);
      newPhones.splice(innerIndex, 1);
      setPhones([...newPhones]);
      onChange(newPhones);
      if (isCompany) {
        companyContext.saveCompanyPhonesToDelete([id]);
      } else {
        savePhonesToDelete([id], type);
      }
    } else {
      newPhones.splice(index, 1);
      setPhones([...newPhones]);
      onChange(newPhones);
    }
  };

  const handleTypeChange = (index: number, val: string) => {
    const newPhones = [...phones];
    newPhones[index] = {
      ...newPhones[index],
      phoneType: Number(val),
    };
    setPhones(newPhones);
    onChange(newPhones);
  };

  const handleExtensionChange = (index: number, val: string) => {
    const newPhones = [...phones];
    newPhones[index] = {
      ...newPhones[index],
      extension: Number.isNaN(val) ? undefined! : Number(val),
    };
    setPhones(newPhones);
    onChange(newPhones);
  };

  return {
    options,
    phones,
    handlePhoneChange,
    handleAddClick,
    handleTypeChange,
    handleExtensionChange,
    readOnly,
    formName,
    handleRemovePhones,
  };
};

export default usePhonesSubForm;
