import React from 'react';
import SubTable from 'src/components/commons/subTable';
import { CustomLoadingModal } from 'src/components/commons/customModal';
import StyleInfinityScroll from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import NewContact from 'src/modules/contacts/components/newContact';
import useContactsCompaniesExpanded from './hooks/useContactsCompaniesExpanded';

const ContactsCompaniesExpanded = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    spinning,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
    handleFavContact,
    showContactForm,
    setShowContactForm,
  } = useContactsCompaniesExpanded();

  return (
    <>
      {spinning && <CustomLoadingModal modalStatus />}
      <StyleInfinityScroll isDetailTable>
        <SubTable
          data={dataSource}
          columns={columns}
          setColumns={setColumns}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortModelChange}
          onListRender={onListRender}
          scroll={{ y: '50vh', x: '100%' }}
          actionCell={['fav', 'delete']}
          // headerActions={['filter', 'email', 'edit', 'columns', 'delete']}
          headerActions={['filter', 'email', 'reassign']}
          id='sub-table-contact-company-table-id'
          showOptionFilterLeft={false}
          allowGlobalEditActive
          activeOnSelectRowsDisableEdit
          total
          handleUpdateRows={handleUpdateRows}
          onDeleteClick={handleDeleteRows}
          onFavClick={handleFavContact}
          newRecord={newRecord}
          resetNewRecord={resetNewRecord}
          postData={postData}
          saveNewRecord={createNewRecord}
          clearFilter={handleClearFilter}
          onAddClick={() => setShowContactForm(true)}
          columnFilterShown
        />
      </StyleInfinityScroll>
      {showContactForm && (
        <NewContact
          onClose={() => {
            setShowContactForm(false);
            handleClearFilter();
          }}
        />
      )}
    </>
  );
};
export default ContactsCompaniesExpanded;
