import { FC, createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { FilterFields, SortModel, SubTableContextProps, SubTableProviderProps } from '../types';
import { RecordType } from '../../createCompany/types';

const SubTableContext = createContext<SubTableContextProps>({} as SubTableContextProps);

const SubTableProvider: FC<SubTableProviderProps> = ({
  children,
  columnFilterShown,
  pagination,
  data,
  isModalCreateEditOpen,
  setIsModalCreateEditOpen,
  ...rest
}) => {
  const [isFiltering, setIsFiltering] = useState(!!columnFilterShown);
  const [isEditing, setIsEditing] = useState(false);
  const [editingKey, setEditingKey] = useState('');

  const [rowsEdited, setRowsEdited] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const [selectedRows, setSelectedRows] = useState<RecordType[]>([]);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [filterFields, setFilterFields] = useState<FilterFields>({});
  const [sortModel, setSortModel] = useState<SortModel | undefined>();
  const [showLeftFilter, setShowLeftFilter] = useState(false);
  const [showModalBulkDelete, setShowModalBulkDelete] = useState(false);
  const [addNewRecord, setAddNewRecord] = useState(false);
  const [dataTable, setDataTable] = useState<any[] | undefined>(data);
  const [keyToEditNewRow, setKeyToEditNewRow] = useState('');
  const [optionSelectedFromSelect, setOptionSelectedFromSelect] = useState('');
  const [rowsExpanded, setRowsExpanded] = useState<any[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [record, setRecord] = useState<Record<any, any> | undefined>(undefined);

  const [optionsFromValues, setOptionsFromValues] = useState<string>('');

  const location = useLocation();
  const hideSubtableBorders =
    location.pathname.includes('subtables') || location.pathname.includes('settings');

  useEffect(() => {
    setOpenModal(!!isModalCreateEditOpen);
    if (!isModalCreateEditOpen) setRecord(undefined);
  }, [isModalCreateEditOpen]);

  useEffect(() => {
    setIsModalCreateEditOpen?.(openModal);
  }, [openModal]);

  useEffect(() => {
    if (rest.selectedRows) {
      setSelectedRows(rest.selectedRows);
    }
  }, [rest.selectedRows]);

  useEffect(() => {
    rest.clickedRecord?.(record);
  }, [record]);

  useEffect(() => {
    rest?.onSelectRecord?.(selectedRows);
  }, [selectedRows]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    isEditing,
    setIsEditing,
    rowsEdited,
    setRowsEdited,
    selectedRowKeys,
    setSelectedRowKeys,
    selectedRows,
    setSelectedRows,
    isFiltering,
    setIsFiltering,
    globalFilter,
    setGlobalFilter,
    filterFields,
    setFilterFields,
    sortModel,
    setSortModel,
    columnFilterShown,
    pagination,
    showLeftFilter,
    data,
    setShowLeftFilter,
    showModalBulkDelete,
    setShowModalBulkDelete,
    addNewRecord,
    setAddNewRecord,
    dataTable,
    setDataTable,
    editingKey,
    setEditingKey,
    keyToEditNewRow,
    setKeyToEditNewRow,
    optionSelectedFromSelect,
    setOptionSelectedFromSelect,
    rowsExpanded,
    setRowsExpanded,
    openModal,
    setOpenModal,
    record,
    setRecord,
    setOptionsFromValues,
    optionsFromValues,
    hideSubtableBorders,

    ...rest,
  };

  return <SubTableContext.Provider value={value}>{children}</SubTableContext.Provider>;
};

export const useSubTableContext = () => useContext(SubTableContext);

export default SubTableProvider;
