import React from 'react';
import SubTable from 'src/components/commons/subTable';
import StyleInfinityScroll from 'src/modules/settings/modules/styles/StyleCustomTab.style';
import { useCompanyContext } from 'src/context/company.context';
import useCompanyRelated from './hooks/useCompanyRelatedTable';
import useCompanyRelatedDetailsTable from './hooks/useCompanyRelatedDetailsTable';

const CompanyRelated = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    setColumns,
    onListRender,
    dataSource,
    columns,
    spinning,
    newRecord,
    postData,
    resetNewRecord,
    createNewRecord,
    handleClearFilter,
    handleDeleteRows,
  } = useCompanyRelated();
  const { activeExpandedEdition } = useCompanyContext();

  return (
    <StyleInfinityScroll isDetailTable>
      <SubTable
        data={dataSource}
        columns={columns}
        setColumns={setColumns}
        autocompleteFieldProvider={autocompleteFieldSearch}
        onFilterChange={handleFilterChange}
        onSortChange={handleSortModelChange}
        onListRender={onListRender}
        scroll={{ y: '50vh', x: '100%' }}
        actionCell={['delete']}
        headerActions={['filter', 'edit', 'delete']}
        id='company-related-companies'
        showOptionFilterLeft={false}
        allowGlobalEditActive
        activeOnSelectRowsDisableEdit
        total
        showAutoIncrement
        handleUpdateRows={handleUpdateRows}
        onDeleteClick={handleDeleteRows}
        newRecord={newRecord}
        resetNewRecord={resetNewRecord}
        postData={postData}
        saveNewRecord={createNewRecord}
        clearFilter={handleClearFilter}
        detailsTable={useCompanyRelatedDetailsTable}
        keyExpandableRow='companyManufacturer'
        titleOnDelete='Are you sure you want to delete this relationship?'
        contentOnDelete=''
        loading={spinning}
        nonGlobalFilterable
        columnFilterShown
        nonAddable={!activeExpandedEdition}
      />
    </StyleInfinityScroll>
  );
};
export default CompanyRelated;
