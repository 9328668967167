import { Modal, ModalProps } from 'antd';
import styled from 'styled-components';

interface StyledModallProps extends ModalProps {
  headerHeight?: string;
  headerWidth?: string;
  bodyHeight?: string;
  bodyWidth?: string;
  footerHeight?: string;
  footerWidth?: string;
  bodyPadding?: string;
}

const StyledCustomModal = styled(Modal).attrs((props: StyledModallProps) => ({
  headerHeight: props.headerHeight,
  headerWidth: props.headerWidth,
  bodyHeight: props.bodyHeight,
  bodyWidth: props.bodyWidth,
  footerHeight: props.footerHeight,
  footerWidth: props.footerWidth,
  bodyPadding: props.bodyPadding,
}))`
  .ant-modal-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    flex: 1;
  }

  .ant-modal-header {
    display: flex;
    height: ${(props) => props.headerHeight && `${props.headerHeight} !important`};
    width: ${(props) => props.headerWidth && `${props.headerWidth} !important`};
    padding: 16px 24px;
    padding-right: 58px;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    margin-bottom: 0;
    border-bottom: 1px solid var(--functional-divider, #fafafa);
    background: var(--neutral-off-white, #fafafa);
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .ant-modal-body {
    overflow-y: auto;
    padding: ${(props) =>
      props.bodyPadding ? `${props.bodyPadding} !important` : '24px 32px 24px 0px'};
    height: ${(props) => props.bodyHeight && `${props.bodyHeight} !important`};
    width: 99.5%;
  }

  .ant-modal-close {
    top: 24px;
  }

  .ant-modal-footer {
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    height: ${(props) => props.footerHeight && `${props.footerHeight} !important`};
    width: ${(props) => props.footerWidth && `${props.footerWidth} !important`};
    gap: 16px;
    align-self: stretch;
    border-top: 1px solid var(--neutral-light-grey, #eee);
    background: var(--neutral-off-white, #fafafa);

    .ant-btn {
      width: 120px;
      height: 32px;
      padding: 4px 15px;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
`;

export default StyledCustomModal;
