import React from 'react';
import SubTable from 'src/components/commons/subTable';
import PageContainer from 'src/components/pageContainer/PageContainer';
import StyleInfinityScroll from '../../../subTables/styles/StyleInfinityScroll.style';
import useFieldSettings from './useFieldsSettings';

const TabFieldSettings = () => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    setColumns,
    columns,
    newRecord,
    // spinning,
    postData,
    createNewRecord,
    // handleUpdateRows,
    loadPage,
    resetNewRecord,
    dataSource,
    reset,
    handleDeleteRows,
    dataModule,
    filterByModule,
  } = useFieldSettings();

  return (
    <PageContainer margin='2rem'>
      <StyleInfinityScroll>
        <SubTable
          data={dataSource || []}
          onListRender={loadPage}
          columns={columns}
          setColumns={setColumns}
          autocompleteFieldProvider={autocompleteFieldSearch}
          onFilterChange={handleFilterChange}
          onSortChange={handleSortModelChange}
          columnFilterShown
          scroll={{ y: '60vh', x: '90vw' }}
          actionCell={['delete']}
          headerActions={['filter']}
          id='application-settings-field-settings'
          showOptionFilterLeft={false}
          allowGlobalEditActive
          total
          // handleUpdateRows={handleUpdateRows}
          onDeleteClick={handleDeleteRows}
          newRecord={newRecord}
          postData={postData}
          saveNewRecord={createNewRecord}
          resetNewRecord={resetNewRecord}
          clearFilter={reset}
          nonEditable
          nonAddable
          nonSelectable
          nonFilterable={false}
          showHeaderSelect
          headerSelectDefaultValue='company'
          dataForListInHeader={dataModule}
          filterByHeaderListClient={filterByModule}
        />
      </StyleInfinityScroll>
    </PageContainer>
  );
};
export default TabFieldSettings;
