import React, { useRef, useState, useContext, useEffect } from 'react';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { CompaniesContext } from 'src/context/companies.context';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import type { RadioChangeEvent } from 'antd';
// eslint-disable-next-line no-duplicate-imports
import { Form } from 'antd';
import {
  FormElementsOptions,
  CompanyTypeSelectionCheckgroupType,
  SeparateComponentDataHandlerType,
  FormAddressElementsType,
  CreateCompanySelectionType,
  CompaniesHeaderType2,
  CompaniesElasticTypes2,
} from 'src/types/companiesTypes';
import { CreateCompanyBodyRenderer } from 'src/components/commons/createCompany/renderer';
import toCase from 'src/utils/functions/toCaseWord';
import { CreateCompanyPayloadType } from './types';
import { createNewPayload } from './consts';

const CreateCompany = () => {
  const createCompanyPayload = useRef<CreateCompanyPayloadType>(createNewPayload());
  const {
    searchRecurrentDataHandler,
    searchRecurrentDataResponse,
    allowCreateCompanyHandler,
    progressCreationCompanyHandler,
    createCompanyPayload: payload,
  } = useContext(CompaniesContext);
  const { companiesHeaderResponse2 } = CompaniesColumns();
  let timer: NodeJS.Timeout = 0 as unknown as NodeJS.Timeout;
  const [validatingStatus, setValidatingStatus] = useState<
    '' | 'success' | 'error' | 'warning' | 'validating' | undefined
  >('');
  const [dependencyCompanyType, setDependencyCompanyType] = useState<
    CompanyTypeSelectionCheckgroupType | undefined
  >();
  const [renderState, setRenderState] = useState(false);
  const update = () => {
    payload.current = createCompanyPayload.current;
    setRenderState(!renderState);
  };
  const [openedParentModal, setOpenedParentModal] = useState(false);
  const [form] = Form.useForm();
  const formElementsOptions: FormElementsOptions[] =
    companiesHeaderResponse2.data?.GetCompaniesDepthGridHeader.map(
      (element: CompaniesHeaderType2) => {
        const innerPayloadData = createCompanyPayload.current;
        if (innerPayloadData[element.dataIndex]) {
          innerPayloadData[element.dataIndex].required = element.required;
        }
        if (element.options) {
          return {
            name: element.dataIndex,
            required: element.required,
            options: element.options.map((item) => ({
              value: item.id,
              label: item.name ?? item.productName,
            })),
          };
        }
        return {
          name: element.dataIndex,
          // element.dataIndex === element.key
          //   ? element.dataIndex
          //   : `${element.dataIndex}.${element.key}`,
          required: element.required,
        };
      },
    );

  const dependencyCompanyTypeHandler = (value: CompanyTypeSelectionCheckgroupType | undefined) => {
    setDependencyCompanyType(value);
  };

  const localAddressData: FormAddressElementsType[] | string = createCompanyPayload.current
    .addresses.data as FormAddressElementsType[] | string;

  const progressHandler = () => {
    const innerPayloadData = createCompanyPayload.current;
    const ineternalPercentageAmmount = Object.keys(createCompanyPayload.current).length;
    const ineternalPercentageUnit = 100 / ineternalPercentageAmmount;
    let percentStatus = 0;
    Object.keys(innerPayloadData).forEach((key) => {
      if (innerPayloadData[key].data !== '') {
        percentStatus += ineternalPercentageUnit;
      }
    });
    if (!dependencyCompanyType?.showAditionalFields) {
      percentStatus += percentStatus === 0 ? 0 : 2 * ineternalPercentageUnit;
    } else {
      percentStatus += percentStatus === 0 ? 0 : ineternalPercentageUnit;
    }
    progressCreationCompanyHandler(Math.round(percentStatus));
  };

  const verifyCompanyName = (name: string) => {
    createCompanyPayload.current.name.data = name;
    clearTimeout(timer);
    if (createCompanyPayload.current.name.data.length !== 0 && name.trim() !== '') {
      timer = setTimeout(() => {
        setValidatingStatus('validating');
        searchRecurrentDataHandler(name.trim(), 'name');
      }, 800);
    } else {
      setValidatingStatus('warning');
      update();
    }
  };

  const manageSearchRecurrentDataResponse = () => {
    const innerData = searchRecurrentDataResponse.data.GetCompanyUniqueValuesFromColumn;
    const innerCreateCompanyPayload = createCompanyPayload.current.name.data as string;
    let innerErrorHandler = false;
    if (innerData.length >= 1) {
      innerData.forEach((element: string) => {
        if (element === innerCreateCompanyPayload.toLowerCase()) {
          setValidatingStatus('error');
          innerErrorHandler = true;
          createCompanyPayload.current.name.error = true;
          update();
        }
        form.setFieldValue('name', createCompanyPayload.current.name.data);
      });
      if (!innerErrorHandler) {
        setValidatingStatus('success');
        createCompanyPayload.current.name.error = false;
        update();
      }
    } else {
      setValidatingStatus('success');
      createCompanyPayload.current.name.error = false;
      update();
    }
  };

  const checkGroupHandler = (checkedValues: CheckboxValueType[]) => {
    const innerObject = createCompanyPayload.current;
    const innerResult = Object.keys(innerObject).filter(
      (key) => innerObject[key as keyof typeof innerObject].inputType === 'check',
    );
    innerResult.forEach((element) => {
      const innerMatchElement = checkedValues.find((item) => item === element);
      if (innerMatchElement) {
        innerObject[element as keyof typeof innerObject].data = true;
      } else {
        innerObject[element as keyof typeof innerObject].data = '';
      }
    });
  };

  const separateComponentDataHandler = (
    data: SeparateComponentDataHandlerType[] | '',
    name?: string,
  ) => {
    const innerPayloadData = createCompanyPayload.current;
    if (name) {
      innerPayloadData[name].data = data;
    }
    update();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'name') {
      verifyCompanyName(toCase(value.trim()));
    } else if (name === 'visitFrequency') {
      const parsedValue = parseInt(value, 10);
      if (Number.isNaN(parsedValue) || parsedValue < 0) {
        form.setFieldsValue({
          visitFrequency: 0,
        });
        createCompanyPayload.current.visitFrequency.data = 0;
      } else if (value.startsWith('0')) {
        form.setFieldsValue({
          visitFrequency: value.substring(1),
        });
        createCompanyPayload.current.visitFrequency.data = value.substring(1);
      } else {
        const innerPayloadData = createCompanyPayload.current;
        innerPayloadData[name].data = value;
        createCompanyPayload.current = innerPayloadData;
      }
      update();
    } else {
      const innerPayloadData = createCompanyPayload.current;
      if (name.length > 0) {
        innerPayloadData[name].data = value;
        createCompanyPayload.current = innerPayloadData;
      }
      update();
    }
  };

  const handleTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const innerPayloadData = createCompanyPayload.current;
    if (name.length > 0) {
      innerPayloadData[name].data = value;
      createCompanyPayload.current = innerPayloadData;
    }
    update();
  };

  const handleSelectChange = (value: React.FormEvent<HTMLInputElement>, name?: string) => {
    let innerValue = value as unknown as string;
    const fromHeaderProp = Object.getOwnPropertyDescriptor(value, 'fromHeaderName');
    let headerName = name;
    if (fromHeaderProp) {
      const propValue = fromHeaderProp.value;
      if (propValue) {
        headerName = propValue;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      innerValue = (value as any).value;
    }
    if (name) {
      createCompanyPayload.current[name].data = {
        id: innerValue as unknown as number,
        name: value as unknown as string,
      };
      const selectedOption: FormElementsOptions | undefined = formElementsOptions.find(
        (element: FormElementsOptions) => element.name === headerName,
      );
      if (selectedOption) {
        const optionToSave = selectedOption.options.find(
          (element: { value: string; label: string }) => element.value === innerValue,
        );
        if (optionToSave) {
          createCompanyPayload.current[name].data = {
            id: optionToSave.value as unknown as number,
            name: optionToSave.label,
          };
          update();
        }
      }
    }
  };

  const getDateAndTime = (): string => {
    const fecha = new Date();

    const dia = String(fecha.getDate()).padStart(2, '0');
    const mes = String(fecha.getMonth() + 1).padStart(2, '0');
    const anno = fecha.getFullYear();

    const hora = String(fecha.getHours()).padStart(2, '0');
    const minutos = String(fecha.getMinutes()).padStart(2, '0');

    return `${mes}/${dia}/${anno} - ${hora}:${minutos}`;
  };

  const addDateAndTimeIntoComments = () => {
    const valorActual = form.getFieldValue('comments');
    const dateAndTime = getDateAndTime();
    createCompanyPayload.current.comments.data = `${dateAndTime}: ${valorActual}`;
    if (valorActual === undefined) {
      form.setFieldsValue({
        comments: dateAndTime,
      });
    } else {
      form.setFieldsValue({
        comments: `${dateAndTime}

${valorActual}`,
      });
    }
    update();
  };

  const handleMultiSelection = (ev: string[], name?: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let data: any = ev;
    const fromHeaderProp = Object.getOwnPropertyDescriptor(ev, 'fromHeaderName');
    let headerName = name;
    if (fromHeaderProp) {
      const propValue = fromHeaderProp.value;
      if (propValue) {
        headerName = propValue;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data = (ev as any).value;
    }

    data = data.map((opt: string) => {
      if (typeof opt === 'object') {
        return opt;
      }

      const regexp = /^{{%(.+)%}}/;
      const match = opt.match(regexp);

      if (match) {
        const id = match[1];
        const label = opt.replace(regexp, '');

        return { id, name: label };
      }
      return opt;
    });

    if (name) {
      const selectedOption: FormElementsOptions | undefined = formElementsOptions.find(
        (element: FormElementsOptions) => element.name === headerName,
      );
      const innerData: CreateCompanySelectionType[] = [];
      if (selectedOption) {
        if (data.length === 0) {
          createCompanyPayload.current[name].data = '';
        } else {
          data.forEach((element: string) => {
            if (typeof element === 'object') {
              innerData.push(element);
              return;
            }

            const optionToSave = selectedOption.options.find(
              (item: { value: string; label: string }) => item.label === element,
            );
            if (optionToSave) {
              innerData.push({
                id: optionToSave.value as unknown as number,
                name: optionToSave.label,
              });
            }
          });
          createCompanyPayload.current[name].data = innerData;
        }
      }
    }
    update();
  };

  const onRadioChangeEvent = (e: RadioChangeEvent) => {
    const { name, value } = e.target;
    const innerPayloadData = createCompanyPayload.current;
    if (name && name.length > 0) {
      innerPayloadData[name].data = value === 1;
      createCompanyPayload.current = innerPayloadData;
    }
    update();
  };
  const onCloseParentModel = (record: CompaniesElasticTypes2 | null) => {
    if (record) {
      const innerPayloadData = createCompanyPayload.current;
      innerPayloadData.parent.data = record.id!;
      form.setFieldsValue({ parent: record.name });
      createCompanyPayload.current = innerPayloadData;
    } else {
      const innerPayloadData = createCompanyPayload.current;
      innerPayloadData.parent.data = '';
      form.setFieldValue('parent', null);
      createCompanyPayload.current = innerPayloadData;
    }
    setOpenedParentModal(false);
    update();
  };

  useEffect(() => {
    if (validatingStatus !== '') {
      if (
        !searchRecurrentDataResponse.loading &&
        searchRecurrentDataResponse.data &&
        validatingStatus !== 'warning'
      ) {
        manageSearchRecurrentDataResponse();
      }
    }
  }, [validatingStatus, searchRecurrentDataResponse.loading]);

  useEffect(() => {
    progressHandler();
    const innerPayloadData = createCompanyPayload.current;
    let allowedToSend = true;
    if (dependencyCompanyType?.salesTeamRequired) {
      createCompanyPayload.current.salesTeam.required = true;
    }
    Object.keys(innerPayloadData).forEach((key) => {
      if (innerPayloadData[key].required) {
        if (innerPayloadData[key].data === '' || innerPayloadData[key].error) {
          allowedToSend = false;
        }
      }
    });
    if (validatingStatus === 'warning' || validatingStatus === 'error') {
      allowCreateCompanyHandler(false);
    } else {
      allowCreateCompanyHandler(allowedToSend);
    }
  }, [renderState]);

  useEffect(() => {
    createCompanyPayload.current = createNewPayload();
    update();
  }, []);

  return (
    <CreateCompanyBodyRenderer
      {...{ handleInputChange }}
      {...{ handleTextAreaChange }}
      {...{ handleSelectChange }}
      {...{ validatingStatus }}
      {...{ formElementsOptions }}
      {...{ dependencyCompanyType }}
      {...{ dependencyCompanyTypeHandler }}
      {...{ handleMultiSelection }}
      {...{ onRadioChangeEvent }}
      {...{ addDateAndTimeIntoComments }}
      {...{ form }}
      {...{ separateComponentDataHandler }}
      {...{ onCloseParentModel }}
      {...{ openedParentModal }}
      {...{ setOpenedParentModal }}
      {...{ checkGroupHandler }}
      externalCountry={
        typeof localAddressData !== 'string' &&
        typeof localAddressData[0].addressData.country !== 'string'
          ? localAddressData[0].addressData.country.name.toLocaleLowerCase()
          : 'us'
      }
    />
  );
};

export default CreateCompany;
