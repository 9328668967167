/* eslint-disable jsx-a11y/label-has-associated-control */
import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useEffect, useState } from 'react';
import {
  addressToInputAdapter,
  emailToInputAdapter,
  list,
  phoneToInputAdapter,
} from 'src/adapters';
import ParentCompanyModal from 'src/components/commons/createCompany/internalComponents/parentCompanyModal';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import { useCompanyContext } from 'src/context/company.context';
import { useContactContext } from 'src/context/contact.context';
import { GQL } from 'src/graphql';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';

type ContactFormBusinessKey =
  | `businessInfo.${keyof GQL.CreateContactBusinessInfoInput}`
  | 'businessInfo.salesTeam'
  | 'businessInfo.companyName'
  | 'businessInfo.companyType';
type FormDataType = Record<ContactFormBusinessKey, unknown>;

type Props = {
  showTitle?: boolean;
  saveCompanyAsociatedIdCreateContact?: (companyId: number) => void;
  formName?: string;
};

const ContactFormBusinessInfo: React.FC<Props> = ({
  showTitle = true,
  saveCompanyAsociatedIdCreateContact,
  formName,
}) => {
  const companyContext = useCompanyContext();
  const { saveCompanyAsociatedId, activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();

  const form = Form.useFormInstance();
  const [selectingParent, setSelectingParent] = useState(false);

  const handleSelectCompany = (company: any | null, checked?: boolean) => {
    if (company) {
      if (saveCompanyAsociatedId) {
        saveCompanyAsociatedId(company.id);
      } else if (saveCompanyAsociatedIdCreateContact) {
        saveCompanyAsociatedIdCreateContact(company.id);
      }
      form.setFieldValue('businessInfo.companyName', company.name);
      form.setFieldValue('businessInfo.companyType', company.companyType?.name);
      form.setFieldValue('businessInfo.salesTeam', company.salesTeam?.name);

      if (checked) {
        const phones = company.phones?.length ? company.phones : [company.mainPhone];
        form.setFieldValue(
          'businessInfo.addresses',
          list(addressToInputAdapter, [company.mainAddress]),
        );
        form.setFieldValue('businessInfo.phones', list(phoneToInputAdapter, phones));
        form.setFieldValue('businessInfo.emails', list(emailToInputAdapter, company.emails));
        form.setFieldsValue({
          ...form.getFieldsValue(),
        });
      }
    }
    setSelectingParent(false);
  };

  const fields: FieldType<FormDataType>[] = [
    {
      name: 'businessInfo.companyName',
      label: 'Company',
      placeholder: 'Select the company',
      type: 'text',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      span: 24,
      rules: [{ required: true, message: 'Company is required' }],
      ...(companyContext
        ? {}
        : {
            suffixButton: <SearchOutlined />,
            suffixType: 'square',
            onSuffixButtonClick:
              activeExpandedEdition || createContactModalActive
                ? () => setSelectingParent(true)
                : undefined,
          }),
    },
    {
      name: 'businessInfo.companyType',
      label: 'Company Type',
      placeholder: 'Company Type',
      type: 'text',
      span: 12,
      readOnly: true,
      disabled: true,
    },
    {
      name: 'businessInfo.salesTeam',
      label: 'Sales Team',
      placeholder: 'Sales Team',
      type: 'text',
      span: 12,
      readOnly: true,
      disabled: true,
    },
    {
      name: 'businessInfo.jobTitle',
      label: 'Job Title',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      rules: [{ required: false, message: 'Job Title is required' }],
      span: 12,
    },
    {
      name: 'businessInfo.department',
      label: 'Department',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      rules: [{ required: false, message: 'Department is required' }],
      span: 12,
    },
    {
      name: 'businessInfo.manager',
      label: 'Manager',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      rules: [{ required: false, message: 'Manager is required' }],
      span: 12,
    },
    {
      name: 'businessInfo.assistant',
      label: 'Assistant',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      span: 12,
    },
    {
      name: 'businessInfo.referencedBy',
      label: 'Referenced By',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      span: 12,
    },
    {
      name: 'businessInfo.context',
      label: 'Context',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'text',
      span: 12,
    },
    {
      name: 'businessInfo.isPrimary',
      label: 'Primary',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'checkbox',
      span: 6,
      initialValue: false,
    },
    {
      name: 'businessInfo.globallyVisible',
      label: 'Globally Visible',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'checkbox',
      span: 18,
      initialValue: false,
    },
    {
      name: 'businessInfo.addresses',
      label: 'Main Address',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'addresses',
      formName,
    },
    {
      name: 'businessInfo.phones',
      label: 'Main Phone',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'phones',
      formName,
    },
    {
      name: 'businessInfo.emails',
      label: 'Main Email',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'emails',
      formName,
    },
    {
      name: 'businessInfo.enabledForcedSync',
      label: 'Enabled Forced Sync',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'checkbox',
      span: 6,
    },
    {
      name: 'businessInfo.doNotEmail' as ContactFormBusinessKey,
      label: 'Do Not Email',
      readOnly: !activeExpandedEdition && !createContactModalActive,
      type: 'checkbox',
      span: 6,
    },
  ];

  if (!showTitle) {
    return (
      <>
        <GenericForm fields={fields} />
        <ParentCompanyModal
          show={selectingParent}
          onClose={handleSelectCompany}
          title='Add company to contact'
          headerCheckbox='Copy Business Fields'
        />
      </>
    );
  }

  useEffect(() => {
    if (companyContext) {
      handleSelectCompany(companyContext.company);
    }
  }, []);

  return (
    <FormSection title='Business Information'>
      <GenericForm fields={fields} />
      <ParentCompanyModal
        show={selectingParent}
        onClose={handleSelectCompany}
        title='Add company to contact'
        headerCheckbox='Copy Business Fields'
      />
    </FormSection>
  );
};

export default ContactFormBusinessInfo;
