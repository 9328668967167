import { gql } from '@apollo/client';

export const GET_ALL_UNIT_OF_MEASUREMENTS = gql`
  query GetAllUnitOfMeasurementFromElastic2(
    $criteria: UnitOfMeasurementCriteriaInput!
    $searchFields: FindUnitOfMeasurementInput
  ) {
    GetAllUnitOfMeasurementFromElastic2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
        units
      }
      total
    }
  }
`;
