import React from 'react';
import SubTable from 'src/components/commons/subTable';
import useCustomTypesTable from '../../hooks/useCustomTypesTable';

const TableCustomTypes = ({ handleCollapseToggle }: { handleCollapseToggle?: () => void }) => {
  const {
    autocompleteFieldSearch,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    handleDeleteRows,
    columns,
    setColumns,
    columnsToSelectOption,
    columnsHeaderSelected,
    handleNewRecord,
    newRecord,
    resetNewRecord,
    postNewRecord,
    predefinedData,
    dataSource,
    onListRender,
    queryResponse,
    reset,
  } = useCustomTypesTable();

  return (
    <SubTable
      data={dataSource ?? []}
      onListRender={onListRender}
      columns={columns}
      setColumns={setColumns}
      columnsToSelectOption={columnsToSelectOption}
      columnsHeaderSelected={columnsHeaderSelected}
      autocompleteFieldProvider={autocompleteFieldSearch}
      onFilterChange={handleFilterChange}
      onSortChange={handleSortModelChange}
      handleUpdateRows={handleUpdateRows}
      onDeleteClick={handleDeleteRows}
      scroll={{ y: '32vh', x: '90vw' }}
      actionCell={['delete']}
      headerActions={['filter', 'edit', 'download', 'delete', 'collapsed']}
      id='custom-types-table'
      showOptionFilterLeft={false}
      showAutoIncrement
      allowGlobalEditActive
      clearFilter={reset}
      editBySelected={false}
      newRecord={newRecord}
      postData={handleNewRecord}
      resetNewRecord={resetNewRecord}
      saveNewRecord={postNewRecord}
      dataToCompare={predefinedData}
      total
      loading={queryResponse.loading}
      columnFilterShown
      handleCollapseToggle={handleCollapseToggle}
    />
  );
};

export default TableCustomTypes;
