import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    key: '',
    title: 'UOM NAME',
    dataIndex: 'name',
    editable: true,
    minWidth: 270,
    dataType: 'string',
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'Uom Name',
    options: [],
    orderField: 'name',
    searchField: 'name',
  },
  {
    key: '',
    title: 'UOM UNITS',
    dataIndex: 'units',
    editable: true,
    minWidth: 270,
    dataType: 'number',
    required: true,
    hidden: false,
    options: [],
    dataStructure: 'string',
    headerName: 'Uom Units',
    orderField: 'units',
    searchField: 'units',
    filterable: false,
  },
];

export default COLUMNS;
