import { gql } from '@apollo/client';

export const CREATE_RELATED_COMPANY = gql`
  mutation CreateRelatedCompany($createRelatedCompanyInput: CreateRelatedCompanyInput!) {
    CreateRelatedCompany(createRelatedCompanyInput: $createRelatedCompanyInput) {
      id
      relatedCompany {
        id
        name
      }
      relatedCategory {
        id
        name
      }
      valueRole
      companyManufacturer {
        id
        name
      }
      customerSplit
    }
  }
`;

export const UPDATE_RELATED_COMPANY = gql`
  mutation UpdateRelatedCompany($updateRelatedCompanyInput: UpdateRelatedCompanyInput!) {
    UpdateRelatedCompany(updateRelatedCompanyInput: $updateRelatedCompanyInput) {
      id
    }
  }
`;

export const DELETE_RELATED_COMPANY = gql`
  mutation RemoveRelatedCompany($removeRelatedCompanyId: Int!) {
    RemoveRelatedCompany(id: $removeRelatedCompanyId)
  }
`;

export const CREATE_RELATED_DETAIL_CATEGORY_COMMISSION = gql`
  mutation CreateRelatedCompany($createRelatedCompanyInput: CreateRelatedCompanyInput!) {
    CreateRelatedCompany(createRelatedCompanyInput: $createRelatedCompanyInput) {
      id
    }
  }
`;

// TODO replace the mutation
export const UPDATE_RELATED_DETAIL_CATEGORY_COMMISSION = gql`
  mutation UpdateContactGroup($updateContactGroupInput: UpdateContactGroupInput!) {
    UpdateContactGroup(updateContactGroupInput: $updateContactGroupInput) {
      id
      name
    }
  }
`;

// TODO replace the mutation
export const DELETE_RELATED_DETAIL_CATEGORY_COMMISSION = gql`
  mutation RemoveContactGroup($id: Int!) {
    RemoveContactGroup(id: $id)
  }
`;
