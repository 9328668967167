import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'COMPANY',
    dataIndex: 'relatedCompany',
    editable: true,
    unique: true,
    required: true,
    key: 'name',
    minWidth: 270,
    dataType: 'typeAheadCompany',
    hidden: false,
    dataStructure: 'company',
    headerName: 'COMPANY',
    options: [],
    orderField: 'relatedCompany',
    searchField: 'relatedCompany.name',
    sortable: false,
  },
  {
    title: 'CATEGORY',
    dataIndex: 'relatedCategory',
    editable: true,
    unique: false,
    required: true,
    key: 'name',
    minWidth: 270,
    dataType: 'dropDown',
    hidden: false,
    dataStructure: 'string',
    headerName: 'CATEGORY',
    options: [],
    orderField: 'relatedCategory',
    searchField: 'relatedCategory.name',
    sortable: false,
  },
  {
    title: 'ROLE',
    dataIndex: 'valueRole',
    editable: true,
    unique: false,
    required: false,
    key: '',
    minWidth: 270,
    dataType: 'dropDown',
    hidden: false,
    dataStructure: 'string',
    headerName: 'ROLE',
    options: [
      {
        LABEL: 'Specifier',
        VALUE: 'Specifier',
      },
      {
        LABEL: 'Purchaser',
        VALUE: 'Purchaser',
      },
    ],
    orderField: 'valueRole',
    searchField: 'valueRole',
    sortable: false,
  },
];

export const DETAILS_COLUMNS: SubTableColumn[] = [
  {
    title: 'MFG / PRODUCT LINE',
    dataIndex: 'companyManufacturer',
    editable: true,
    width: 10,
    unique: false,
    required: true,
    key: 'name',
    dataType: 'typeAheadManufacturerCompany',
    hidden: false,
    dataStructure: 'string',
    headerName: 'MFG / PRODUCT LINE',
    options: [],
    orderField: 'companyManufacturer',
    searchField: 'companyManufacturer',
  },
  {
    title: 'SPECIFIER SPLIT %',
    dataIndex: 'customerSplit',
    editable: true,
    width: 10,
    unique: false,
    required: true,
    key: '',
    dataType: 'percent',
    hidden: false,
    dataStructure: 'percent',
    headerName: 'SPECIFIER SPLIT %',
    options: [],
    orderField: 'customerSplit',
    searchField: 'customerSplit',
  },
];

export enum StorageKey {
  expandableRows = 'expandableRows',
}

export default COLUMNS;
