import React from 'react';
import imagotipo from 'src/images/logo.png';
import { Col, Space } from 'antd';
import { useActivityJournalContext } from 'src/modules/activity/contexts/activityJournals.context';
import {
  StyledTitle,
  // StyledSearch,
  StyledSpace,
  StyledRow,
  StyledImage,
} from '../company/CompanySubHeader.styles';
import CustomButton from '../../customButton';
import CustomSelect from '../../customSelect';

const ActivitySubHeader = () => {
  const { setOpenCreateNew } = useActivityJournalContext();
  const subHeaderStyles = {
    background: '#0078D4',
    color: 'white',
    height: 'auto',
    padding: '2px 25px',
  };

  const mockOptionsA = [
    {
      value: 1,
      label: 'Admin',
    },
    {
      value: 2,
      label: 'Alexa Admin',
    },
    {
      value: 3,
      label: 'Jhon Kinley',
    },
    {
      value: 4,
      label: 'Elton Bhow',
    },
  ];
  const mockOptionsB = [
    {
      value: 1,
      label: 'Mock Type 1',
    },
    {
      value: 2,
      label: 'Mock Type 2',
    },
    {
      value: 3,
      label: 'Mock Type 3',
    },
  ];

  return (
    <>
      <StyledSpace data-testid='contacts-sub-header-id' direction='vertical' size='middle'>
        <StyledRow
          style={{ width: '100vw', padding: '0 1.5rem' }}
          data-testid='styled-row-sub-header-id'
          align='middle'
          justify='space-between'
        >
          <Col xs={6} lg={4} md={5}>
            <StyledTitle style={{ margin: 0 }} data-testid='styled-sub-header-title-id' level={3}>
              Activity Journal
            </StyledTitle>
          </Col>
          <Col xl={6} lg={6} md={4}>
            <Space size='middle'>
              <Col xs={8}>
                <CustomButton
                  dataTestId='new-button-id'
                  type='primary'
                  title='New'
                  style={subHeaderStyles}
                  ghost
                  onClick={() => setOpenCreateNew(true)}
                />
              </Col>
              <Col xs={8}>
                <CustomButton
                  dataTestId='export-button-id'
                  type='primary'
                  title='Export'
                  style={subHeaderStyles}
                  ghost
                  // onClick={exportContactsHandler}
                />
              </Col>
              <Col xs={8}>
                <CustomButton
                  dataTestId='reimport-button-id'
                  type='primary'
                  title='Print'
                  style={subHeaderStyles}
                  ghost
                />
              </Col>
            </Space>
          </Col>
          <Col xl={12} md={14}>
            <Space size='middle' style={{ width: '100%', justifyContent: 'space-between' }}>
              <Col xs={8}>
                <CustomSelect
                  multiSel
                  externalWidth='220px'
                  internalWidth='210px'
                  placeHolder='User'
                  listDefault={mockOptionsA}
                  options={mockOptionsA}
                />
              </Col>
              <Col xl={8}>
                <CustomSelect
                  multiSel
                  externalWidth='220px'
                  internalWidth='210px'
                  placeHolder='Product line / Mfg'
                  listDefault={mockOptionsB}
                  options={mockOptionsB}
                />
              </Col>
              <Col xs={2}>
                <StyledImage
                  data-testid='logo-image-sub-header-id'
                  src={imagotipo}
                  preview={false}
                  alt='Repfabric'
                />
              </Col>
            </Space>
          </Col>
        </StyledRow>
      </StyledSpace>
      {/* {exportContactsModalActive && <ExportContactsModal onClose={() => exportContactsHandler()} />} */}
    </>
  );
};

export default ActivitySubHeader;
