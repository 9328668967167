import { useLazyQuery, useMutation } from '@apollo/client';
import { useState } from 'react';
import { SortModel } from 'src/components/commons/subTable/types';
import { BuyGroupModel } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import COLUMNS from '../constants';
import {
  CREATE_BUYING_GROUP,
  REMOVE_BUYING_GROUP,
  UPDATE_BUYING_GROUP,
} from '../graphql/mutations';
import GET_ALL_BUYING_GROUP from '../graphql/queries';
import { GetAllBuyingGroup, GetAllBuyingGroupResponse } from '../types';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useTabBuyingGroupTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [getAll, queryResponse] = useLazyQuery<GetAllBuyingGroup>(GET_ALL_BUYING_GROUP, {
    fetchPolicy: 'network-only',
  });

  const [getAllFilter] = useLazyQuery<GetAllBuyingGroup>(GET_ALL_BUYING_GROUP, {
    fetchPolicy: 'cache-and-network',
  });

  const [update] = useMutation(UPDATE_BUYING_GROUP);
  const [create] = useMutation(CREATE_BUYING_GROUP);
  const [remove] = useMutation(REMOVE_BUYING_GROUP);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllBuyGroup2.results || [],
      total: res.data?.GetAllBuyGroup2.total || 0,
    }));

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(fetchPage);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllBuyGroup2.results as any[],
      filter: res?.data?.GetAllBuyGroup2.results?.map((result: GetAllBuyingGroupResponse) => ({
        value: result[searchField],
        label: result[searchField],
      })) as [],
    }));
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const createNewRecord = async () => {
    const { name, description } = newRecord;
    if (name === '') return;
    await create({
      variables: {
        createBuyGroupInput: {
          name: capitalizeFirstWord(name),
          description,
        },
      },
    }).then((buying) => {
      setDataSource([
        {
          id: buying.data.CreateBuyGroup.id,
          name: buying.data.CreateBuyGroup.name,
          description: buying.data.CreateBuyGroup.description,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateBuyGroupInput: {
            id,
            name: capitalizeFirstWord(name),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: BuyGroupModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    await Promise.all(
      rows.map((row) =>
        remove({ variables: { id: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    );
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    handleDeleteRows,
    queryResponse,
    postData,
    newRecord,
    createNewRecord,
    resetNewRecord,
    handleClearFilter,
  };
};

export default useTabBuyingGroupTable;
