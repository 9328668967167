import styled from 'styled-components';

const StyledCompanyContent = styled.div`
  min-height: calc(100vh - 182px);
  position: relative;
  overflow: hidden;
  padding: 32px;

  .company-contact {
    margin-bottom: 1.5rem;
    transition: margin 0.3s;
    .ant-card-body {
      padding: 0;
    }
  }

  .content {
    transition: margin 0.3s;

    & > .ant-card-body {
      background: #ffffff;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
      border: none;
    }
  }

  .content + .ant-drawer > .ant-drawer-content-wrapper {
    box-shadow: none;
    padding-right: 32px;
    padding-top: 32px;
    padding-bottom: 32px;

    .ant-drawer-content {
      background: #ffffff;
      box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
        0px 9px 28px 8px rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
  }

  .toggle {
    transition: all 0.3s;
    background: #ffffff;
    box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 16px rgba(0, 0, 0, 0.08);
    border-radius: 30px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 180px;
    z-index: 1000;
  }

  .section-card.ant-card {
    padding: 16px 24px 16px 48px;
    background: #ffffff;

    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .ant-card-head {
      border-bottom: none;
      min-height: 0;
      padding: 0;

      .ant-card-head-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #4b4b4b;
      }
    }

    .ant-card-body {
      padding: 0;
    }

    .anticon-holder {
      position: absolute;
      top: 19px;
      left: 12px;
      font-size: 20px;
      color: #4b4b4b;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    .ant-collapse {
      border: none;
    }

    .ant-collapse-item {
      border-bottom: none;
    }

    .ant-collapse-header {
      display: none;
    }

    .ant-collapse-content {
      border-radius: 0;
      border-top: none;
    }

    .ant-collapse-content-box {
      padding: 24px 0 0 0;
    }

    .ant-form-item {
      margin-bottom: 12px;
    }

    .ant-form-item-required {
      flex-direction: row-reverse;
    }
  }
`;

export default StyledCompanyContent;
