import { FC } from 'react';
import { Col, Row } from 'antd';
import { useSettingTableContext } from 'src/context/settingsTables.context';
import StyledUserAndMembers from './styles/usersAndMembers.styles';
import UserTable from './userTable';

const UsersAndMembers: FC = () => {
  const {
    users,
    columns,
    columnsMembers,
    hasPrimaryContact,
    primaryContact,
    handlePrimaryContact,
    selectedRows,
    setSelectedRows,
    infoIconTextUser,
    infoIconTextMembers,
    usersMembersTitle,
  } = useSettingTableContext();
  return (
    <StyledUserAndMembers>
      <Row justify='space-between' align='middle' style={{ width: '100%' }}>
        <Col sm={{ span: 24 }} md={{ span: 12 }} className='first-table'>
          <UserTable
            isUserTable
            users={users}
            columns={columns}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            infoIconText={infoIconTextUser}
            title={usersMembersTitle?.users}
          />
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 12 }} className='second-table'>
          <UserTable
            users={selectedRows}
            columns={columnsMembers}
            hasPrimaryContact={hasPrimaryContact}
            primaryContact={primaryContact}
            handlePrimaryContact={handlePrimaryContact}
            setSelectedRows={setSelectedRows}
            infoIconText={infoIconTextMembers}
            title={usersMembersTitle?.members}
          />
        </Col>
      </Row>
    </StyledUserAndMembers>
  );
};

export default UsersAndMembers;
