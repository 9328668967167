import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_COMPANIES_ELASTIC_2 } from 'src/components/company/queries';
import { GetCompaniesFromElasticPayloadType } from 'src/types/companiesTypes';
import getKeyValue from 'lodash/get';
import useInfinityDataSource, { ListInfo } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import { SortModel } from '../../subTable/types';

const useParentCompanyModal = () => {
  const [loadCompanies] = useLazyQuery<GetCompaniesFromElasticPayloadType>(
    GET_COMPANIES_ELASTIC_2,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [checked, setChecked] = useState(false);

  const getCompanies = ({ renderLen, start }: ListInfo) => {
    const { filters, sort } = pagination;
    const orderBy = sort?.sortBy ? { field: sort.sortBy, sort: sort.sort } : undefined;
    return loadCompanies({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
          orderBy,
        },
        searchFields: filters,
      },
    }).then((res) => {
      if (res.data?.GetCompaniesFromElastic2) {
        return {
          total: res.data.GetCompaniesFromElastic2.total,
          results: res.data.GetCompaniesFromElastic2.results,
        };
      }
      return { total: 0, results: [] };
    });
  };
  const { dataSource, onListRender, reset } = useInfinityDataSource(getCompanies);

  const autocompleteFieldSearch = (
    field: string,
    search: string,
    dataType: string | number | string[],
    dataIndex?: string,
  ) => {
    const { filters } = pagination;
    const searchFields = { ...filters, [field]: search };

    return loadCompanies({
      variables: {
        criteria: {
          pagination: { from: 0, size: 25 },
        },
        searchFields,
      },
    }).then((res) => {
      if (res.data?.GetCompaniesFromElastic2) {
        return {
          results: res.data.GetCompaniesFromElastic2.results,
          filter: res.data.GetCompaniesFromElastic2.results.map((obj) => ({
            value:
              dataIndex !== 'companyType' ? getKeyValue(obj, dataIndex!) : obj?.companyType?.name,
            label:
              dataIndex !== 'companyType' ? getKeyValue(obj, dataIndex!) : obj?.companyType?.name,
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilter: Record<string, string[]>) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      filters: {
        ...newFilter,
      },
      page: 1,
    }));
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  useEffect(() => {
    reset();
  }, [pagination]);

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    onListRender,
    setChecked,
    checked,
    dataSource,
    pagination,
  };
};

export default useParentCompanyModal;
