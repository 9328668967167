import { Avatar, Card, Select } from 'antd';
import { FC, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import StyledCompanyHeader from 'src/components/companyDetails/styles/companyHeader.styles';
import logo from 'src/images/logo.png';
import FlexCol from 'src/components/companyDetails/styles/flexCol.style';
import { StyledTitle } from 'src/components/commons/customHeader/CustomHeader.styles';
import { MODULE_OPTIONS_CONFIGURATOR } from 'src/modules/settings/modules/subTables/constants';
import removeLastDirectoryPartOf from 'src/utils/functions/url';

interface SettingsSubheaderInterface {
  title?: string;
  record?: any;
  withSelect?: boolean;
}

const SubheaderSettings: FC<SettingsSubheaderInterface> = ({
  title = 'Configuration',
  record,
  withSelect,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [configuratorValue, setConfiguratorValue] = useState({
    label: '',
    value: '',
  });

  useEffect(() => {
    // Remove the las url to update the select value with the correct module
    // selected by the user
    const filterOption = MODULE_OPTIONS_CONFIGURATOR.filter(
      (option) =>
        removeLastDirectoryPartOf(option.value) === removeLastDirectoryPartOf(location.pathname),
    );

    setConfiguratorValue({
      label: filterOption[0]?.label,
      value: filterOption[0]?.value,
    });
  }, [location.pathname]);

  const handleSelectOptions = (value: { value: string; label: React.ReactNode }) => {
    navigate(value.value);
  };

  const titleComplement = (
    <span style={{ fontSize: '1.8rem', color: '#4B4B4B' }}>{record?.name}</span>
  );

  const salesTeamComplement = (
    <>
      <FlexCol className='avatar-company-container'>
        <StyledTitle
          style={{ color: '#C6C4C4', fontFamily: 'Libre Franklin, sans-serif', fontSize: '16px' }}
          level={5}
        >
          Company Commission Split:{' '}
          <span
            style={{ fontSize: '20px', color: '#4B4B4B', fontFamily: 'libre franklin, sans-serif' }}
          >
            {record?.companySplit ? Number(record?.companySplit) * 100 : ''}%
          </span>
        </StyledTitle>
      </FlexCol>
      <FlexCol className='avatar-company-container'>
        <StyledTitle
          style={{ color: '#C6C4C4', fontSize: '16px', fontFamily: 'libre franklin, sans-serif' }}
          level={5}
        >
          Sales Teams:{' '}
          <span style={{ fontSize: '20px', color: '#4B4B4B' }}>
            {record?.companySplit ? 100 - Number(record?.companySplit) * 100 : ''}%
          </span>
        </StyledTitle>
      </FlexCol>
    </>
  );

  const selector = (
    <Select
      labelInValue
      data-testid='subtables-select-id'
      onChange={handleSelectOptions}
      options={MODULE_OPTIONS_CONFIGURATOR}
      showSearch
      style={{
        width: '80%',
      }}
      value={configuratorValue}
    />
  );

  return (
    <StyledCompanyHeader>
      <Card
        className='card-container'
        style={{
          borderRadius: 0,
          paddingBlock: '0.5rem',
        }}
      >
        <FlexCol span={24} className='flex-col-container'>
          <FlexCol className='avatar-company-container'>
            <StyledTitle
              style={{
                color: record ? '#C6C4C4' : '#4B4B4B',
                margin: 0,
                fontFamily: 'libre franklin, sans-serif',
              }}
              level={3}
            >
              {title}
              {record && ': '} {record && titleComplement}
            </StyledTitle>
          </FlexCol>
          {withSelect && selector}
          {record && salesTeamComplement}
          <FlexCol>
            <Avatar src={logo} style={{ width: 40, height: 40 }} />
          </FlexCol>
        </FlexCol>
      </Card>
    </StyledCompanyHeader>
  );
};

export default SubheaderSettings;
