/* eslint-disable arrow-body-style */
import React from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Space, MenuProps } from 'antd';
import CustomButton from '../customButton';
import menuItems from './menuItems';

const items: MenuProps['items'] = menuItems;

const CustomDropdown = () => {
  return (
    <Space>
      <Dropdown menu={{ items }} placement='bottomLeft' trigger={['click']}>
        <CustomButton
          dataTestId='menu-outlined'
          shape='circle'
          icon={<MenuOutlined />}
          onlyIcon
          ghost
          tooltipText='Full Menu'
          tooltipColor='gray'
        />
      </Dropdown>
    </Space>
  );
};

export default CustomDropdown;
