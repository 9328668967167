import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    title: 'DIVISION',
    dataIndex: 'companyType.name',
    minWidth: 200,
    editable: false,
    dataType: 'string',
    unique: true,
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'name',
    options: [],
    orderField: 'name',
    searchField: 'name',
  },
  {
    title: 'USER/SALES REP ASSIGNER',
    dataIndex: 'category.name',
    editable: true,
    dataType: 'dropdown',
    unique: false,
    required: false,
    minWidth: 200,
    hidden: false,
    dataStructure: 'string',
    headerName: 'description',
    options: [
      {
        VALUE: '1',
        LABEL: 'user-1',
      },
      {
        VALUE: '2',
        LABEL: 'user-2',
      },
      {
        VALUE: '3',
        LABEL: 'user-3',
      },
    ],
    orderField: 'description',
    searchField: 'description',
  },
];

export default COLUMNS;
