import React, { useContext, useState, useEffect } from 'react';
import { SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { Input, Row } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TableContext } from 'src/context/table.context';
import { CompaniesContext } from 'src/context/companies.context';
import SideTableFilterRenderer from './renderer';
import { DataType } from './types';
import CustomButton from '../customButton';

const SideTableFilter = () => {
  const { selectedSideTableOptions, selectedSideTableFilter, companyUseGridPreferences } =
    useContext(TableContext);
  const { selectedOptionHandler } = useContext(CompaniesContext);
  const [filteredOptions, setFilteredOptions] = useState<DataType[]>(
    selectedSideTableOptions.current,
  );
  const [sideTableFilterSearchValue, setSideTableFilterSearchValue] = useState('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [sortOrder, setSortOrder] = useState('default');

  const [originalOrder, setOriginalOrder] = useState<DataType[]>([]);

  useEffect(() => {
    setOriginalOrder([...selectedSideTableOptions.current]);
    setFilteredOptions([...selectedSideTableOptions.current]);
    setSelectedRowKeys([]);
  }, [selectedSideTableOptions.current]);

  const searchInput = (event: React.ChangeEvent<HTMLInputElement> | string) => {
    const innerValue = typeof event === 'string' ? event : event.target.value;
    if (innerValue.length === 0) {
      setFilteredOptions(selectedSideTableOptions.current);
      setSideTableFilterSearchValue('');
    } else {
      setSideTableFilterSearchValue(innerValue);
      const filtered = selectedSideTableOptions.current.filter((item) =>
        item.mainData.toLowerCase().includes(innerValue.toLowerCase()),
      );
      setFilteredOptions(filtered);
    }
    localStorage.setItem('sideTableFilterSearchValue', innerValue);
  };

  const getSortIcon = () => {
    switch (sortOrder) {
      case 'asc':
        return <SortAscendingOutlined style={{ fontSize: '16px' }} />;
      case 'desc':
        return <SortDescendingOutlined style={{ fontSize: '16px' }} />;
      default:
        return <SortAscendingOutlined style={{ fontSize: '16px' }} />; // Or another icon for 'default'
    }
  };
  const updateSortOrder = () => {
    setSortOrder((currentSortOrder) => {
      if (currentSortOrder === 'asc') return 'desc';
      if (currentSortOrder === 'desc') return 'default';
      return 'asc';
    });
  };

  useEffect(() => {
    let sorted;
    if (sortOrder === 'asc') {
      sorted = [...filteredOptions].sort((a, b) => a.mainData.localeCompare(b.mainData));
    } else if (sortOrder === 'desc') {
      sorted = [...filteredOptions].sort((a, b) => b.mainData.localeCompare(a.mainData));
    } else if (sideTableFilterSearchValue) {
      sorted = [...filteredOptions].sort((a, b) => b.mainData.localeCompare(a.mainData));
    } else {
      sorted = [...originalOrder];
    }

    setFilteredOptions(sorted);
  }, [sortOrder, originalOrder]);

  useEffect(() => {
    setFilteredOptions(selectedSideTableOptions.current);
    setSelectedRowKeys([]);
  }, [selectedSideTableOptions.current]);

  useEffect(() => {
    const reloadedSideTableFilterSearchValue = localStorage.getItem('sideTableFilterSearchValue');
    const reloadedSelectedRowKeys = localStorage.getItem('selectedRowKeys');
    if (reloadedSelectedRowKeys) {
      setSelectedRowKeys(JSON.parse(reloadedSelectedRowKeys));
      selectedOptionHandler(JSON.parse(reloadedSelectedRowKeys));
    }
    if (reloadedSideTableFilterSearchValue) {
      searchInput(reloadedSideTableFilterSearchValue);
    }
  }, []);

  const setSelectedRowKeysHandler = (e: React.Key[]) => {
    setSelectedRowKeys(e);
    localStorage.setItem('selectedRowKeys', JSON.stringify(e));
    companyUseGridPreferences.localSave('selectedRowKeys', e);
  };

  const column: ColumnsType<DataType> = [
    {
      title: (
        <>
          <Row
            wrap={false}
            style={{ alignItems: 'center', justifyContent: 'space-between', height: '25px' }}
          >
            VIEW ALL
            <CustomButton
              onlyIcon
              icon={getSortIcon()}
              onClick={updateSortOrder}
              danger={sortOrder !== 'default'}
              style={{ color: sortOrder === 'default' ? '#0078d4' : undefined }}
            />
          </Row>
          <Row wrap={false} style={{ alignItems: 'center', paddingBottom: 2 }}>
            <Input
              placeholder='Columns'
              allowClear
              value={sideTableFilterSearchValue}
              className='side-table-filter-input'
              onChange={searchInput}
              style={{ width: '95%', borderRadius: '5px' }}
            />
          </Row>
        </>
      ),
      dataIndex: 'mainData',
      key: 'mainData',
      width: 220,
      fixed: 'left',
    },
  ];

  return (
    <SideTableFilterRenderer
      {...{ column }}
      data={filteredOptions}
      {...{ selectedSideTableFilter }}
      {...{ selectedOptionHandler }}
      {...{ selectedRowKeys }}
      {...{ setSelectedRowKeysHandler }}
    />
  );
};

export default SideTableFilter;
