import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import logo from 'src/images/logo.png';
import CustomButton from 'src/components/commons/customButton';
import CustomSelect from 'src/components/commons/customSelect';
import { useNavigate, useLocation } from 'react-router';
import { useCompanyContext } from 'src/context/company.context';
import CompaniesColumns from 'src/utils/tables/companiesTable';
import { CompaniesHeaderType } from 'src/types/companiesTypes';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import FlexCol from '../styles/flexCol.style';
import Tag from '../styles/tag.style';
import StyledCompanyHeader from '../styles/companyHeader.styles';
import MergeTo from './mergeTo';
import ChangeSalesTeam from './changeSalesTeam';

const CompanyHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companiesHeaderResponse2 } = CompaniesColumns();
  const {
    company,
    submittable,
    handleActiveExpandedEdition,
    activeExpandedEdition,
    handleSave,
    processedSave,
  } = useCompanyContext();
  const [openedMergeTo, setOpenedMergeTo] = useState(false);
  const [openedChangeSalesTeam, setOpenedChangeSalesTeam] = useState(false);

  const { setProccessSaving, setConfirmSaving, confirmSaving } = useUnSaveChangesWarning();

  const salesTeamList = companiesHeaderResponse2?.data?.GetCompaniesDepthGridHeader?.find(
    (element: CompaniesHeaderType) => element?.dataIndex === 'salesTeam',
  );

  useEffect(() => {
    if (confirmSaving) {
      handleSave();
    }
  }, [confirmSaving]);

  useEffect(() => {
    if (processedSave) {
      setConfirmSaving(false);
    }
  }, [processedSave]);

  const handleOpenedMergeTo = () => {
    setOpenedMergeTo(!openedMergeTo);
  };

  const handleOpenedChangeSalesTeam = () => {
    setOpenedChangeSalesTeam(!openedChangeSalesTeam);
  };

  const hideEditButton = location.pathname.includes('basic');
  const companyName = company?.name?.replace(/[^\w\s]/gi, '') || '';
  const avatarFirstLetter = companyName?.substring(0, 1);

  return (
    <>
      <StyledCompanyHeader>
        <Card className='card-container'>
          <FlexCol className='flex-col-container'>
            <FlexCol span={9} className='avatar-company-container'>
              <CustomButton
                onClick={() => navigate('/companies')}
                shape='circle'
                icon={<ArrowLeftOutlined />}
                onlyIcon
              />
              <Avatar
                size={32}
                className='avatar-container'
                style={{ backgroundColor: '#0078d4', fontSize: 18 }}
              >
                {avatarFirstLetter}
              </Avatar>
              <div>
                <span style={{ display: 'block', fontSize: '20px', lineHeight: '28px' }}>
                  {company?.name}
                </span>
                <div className='industries-tags'>
                  {company?.industries?.map((industry) => (
                    <Tag key={industry?.name}>{industry?.name}</Tag>
                  ))}
                </div>
              </div>
            </FlexCol>
            <FlexCol span={15} className='inputs-buttons-container'>
              {hideEditButton && (
                <>
                  <CustomButton
                    disabled={!submittable || !activeExpandedEdition}
                    title='Save'
                    type='primary'
                    onClick={() => setProccessSaving(true)}
                    dataTestId='btn-save-company'
                  />
                  <CustomButton
                    title={activeExpandedEdition ? 'Cancel' : 'Edit'}
                    noborder={false}
                    style={{ width: '80px' }}
                    dataTestId='btn-edit-company'
                    onClick={handleActiveExpandedEdition}
                  />
                </>
              )}

              <CustomButton
                noborder={false}
                title='Merge to'
                onClick={handleOpenedMergeTo}
                dataTestId='btn-merge-to-company'
              />

              <CustomButton
                noborder={false}
                title='Change Sales Team'
                onClick={handleOpenedChangeSalesTeam}
              />

              <CustomSelect
                style={{ width: 280, height: 40 }}
                defaultValue='Dashboard'
                disabled
                options={[
                  {
                    label: 'Product Details Report',
                    value: 'productDetailsReport',
                  },
                  {
                    label: 'Last Sales Report',
                    value: 'lastSalesReport',
                  },
                  {
                    label: 'Customer Details Report',
                    value: 'customerDetailsReport',
                  },
                ]}
              />
              <FlexCol>
                <Avatar src={logo} style={{ width: 40, height: 40 }} />
              </FlexCol>
            </FlexCol>
          </FlexCol>
        </Card>
      </StyledCompanyHeader>
      <MergeTo
        show={openedMergeTo}
        handleOpenedMergeTo={handleOpenedMergeTo}
        companyName={company?.name ?? 'Unknown Company'}
        companyId={company?.id}
      />
      <ChangeSalesTeam
        show={openedChangeSalesTeam}
        {...{ handleOpenedChangeSalesTeam }}
        actualSalesTeam={company?.salesTeam}
        salesTeamList={salesTeamList?.options ?? []}
      />
    </>
  );
};

export default CompanyHeader;
