import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import omit from 'lodash/omit';
import get from 'lodash/get';
import set from 'lodash/set';
import { CREATE_ONE_CONTACT, GQL } from 'src/graphql';
import { useState } from 'react';
import { Button, Space, notification } from 'antd';
import handleError from 'src/utils/functions/handleError';
import { prepareContactCreationPayload } from './utils/prepareContactCreationPayload';

const useCreateContact = () => {
  const [createContactMutation] =
    useMutation<Pick<GQL.Mutation, 'CreateContact'>>(CREATE_ONE_CONTACT);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [asociatedCompanyId, setAsociatedCompanyId] = useState<number>();

  const saveCompanyAsociatedIdCreateContact = (companyId: number) => {
    setAsociatedCompanyId(companyId);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const createContact = async (data: any) => {
    setLoading(true);

    // adjust the payload
    // 1. keep only addresses with a valid state, country, city and postal code
    const personalAddresses = get(data, 'personalInfo.addresses', []);
    set(
      data,
      'personalInfo.addresses',
      personalAddresses.filter(
        (address: GQL.CreateAddressInput) =>
          address.state && address.country && address.city && address.zipCode,
      ),
    );

    // 2. set the title equal to the jobTitle of the business info (check if this is correct, but at the moment it is required in the mutation)
    set(data, 'title', get(data, 'businessInfo.jobTitle'));

    // 3. parse to string createBusinessInfoInput.enabledForcedSync (in the figma is a checkbox but in the mutation is a string)
    set(data, 'businessInfo.enabledForcedSync', get(data, 'businessInfo.enabledForcedSync'));

    // the same for globallyVisible
    set(data, 'businessInfo.globallyVisible', get(data, 'businessInfo.globallyVisible'));

    // the same for isPrimary
    set(data, 'businessInfo.isPrimary', get(data, 'businessInfo.isPrimary'));

    // same for doNotEmail
    set(data, 'businessInfo.doNotEmail', get(data, 'businessInfo.doNotEmail'));

    // 4. remove the field createBusinessInfoInput.doNotEmail because it is in the figma but not in the mutation
    set(data, 'businessInfo.doNotEmail', undefined);

    // 5. put an empty string in the fields city, country, zipCode and state of the personal info
    // (at the moment that fields are required in the mutation)
    set(data, 'personalInfo.city', '');
    set(data, 'personalInfo.country', '');
    set(data, 'personalInfo.zipCode', '');
    set(data, 'personalInfo.state', '');

    // 6. remove website, facebook, linkedin, googlePlus, twitter, productInterest, attendance, contactGroup, instagram
    // from the data (them are not in the mutation)
    set(data, 'website', undefined);
    set(data, 'facebook', undefined);
    set(data, 'linkedin', undefined);
    set(data, 'googlePlus', undefined);
    set(data, 'twitter', undefined);
    set(data, 'productInterest', undefined);
    /* set(data, 'attendance', undefined); */
    set(data, 'contactGroup', undefined);
    set(data, 'instagram', undefined);

    try {
      const payload = {
        ...omit(data, ['personalInfo', 'businessInfo']),
        personalInfo: get(data, 'personalInfo'),
        businessInfo: get(data, 'businessInfo'),
      };

      delete payload.businessInfo.companyType;
      delete payload.businessInfo.salesTeam;
      delete payload.businessInfo.companyName;

      if (asociatedCompanyId) {
        payload.businessInfo.company = asociatedCompanyId;
      }

      const createContactInput = prepareContactCreationPayload(payload);

      const { data: contactResponse, errors: contactErrors } = await createContactMutation({
        variables: { createContactInput },
      });

      if (contactErrors) throw contactErrors[0];

      const btn = (
        <Space>
          <Button
            type='primary'
            size='small'
            onClick={() => {
              const { id } = contactResponse!.CreateContact;
              notification.destroy();
              navigate(`/contacts/${id}`);
            }}
          >
            Yes, go to details
          </Button>
        </Space>
      );

      notification.success({
        message: 'Success',
        // eslint-disable-next-line quotes
        description: "Contact created successfully. Do you want go to the contact's page?",
        key: 'createContactSuccess',
        duration: 3,
        btn,
      });
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  return {
    createContact,
    saveCompanyAsociatedIdCreateContact,
    loading,
    asociatedCompanyId,
  };
};

export default useCreateContact;
