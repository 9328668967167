import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import { CompanyTypeAliasModel } from 'src/graphql/schema-types';
import get from 'lodash/get';
import {
  COLUMNS_TABLE_CUSTOM_TYPES,
  COLUMNS_TABLE_SELECTED,
  COLUMNS_TO_SELECT_OPTIONS,
} from '../constants/table';
import { GetCustomTypesFromElasticSearchType, GetCustomTypesHeaders } from '../types';
import { GET_ALL_CUSTOM_TYPES, GET_HEADERS_CUSTOM_TYPES } from '../graphql/queries';
import { CREATE_CUSTOM_TYPES, REMOVE_CUSTOM_TYPE, UPDATE_CUSTOM_TYPES } from '../graphql/mutations';
import usePredefinedTypesTable from './usePredefinedTypesTable';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
import { useSubTableTypeContext } from '../contexts/subtabletype.context';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useCustomTypesTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const { predefinedTypeChanged, setPredefinedTypeChanges } = useSubTableTypeContext();
  const [loadCustomTypes, queryResponse] = useLazyQuery<GetCustomTypesFromElasticSearchType>(
    GET_ALL_CUSTOM_TYPES,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [loadHeadersCustom] = useLazyQuery<GetCustomTypesHeaders>(GET_HEADERS_CUSTOM_TYPES, {
    fetchPolicy: 'network-only',
  });
  const { getAllPredefinedTypesResponse } = usePredefinedTypesTable();
  const [update] = useMutation(UPDATE_CUSTOM_TYPES);
  const [create] = useMutation(CREATE_CUSTOM_TYPES);
  const [remove] = useMutation(REMOVE_CUSTOM_TYPE);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [columnsToSelectOption] = useState(COLUMNS_TO_SELECT_OPTIONS);
  const [columnsHeaderSelected] = useState(COLUMNS_TABLE_SELECTED);
  const [newRecord, setNewRecord] = useState({
    name: '',
    parent: 'Customer',
    color: '000000',
  });

  const loadData: FetchFunc = ({ start, renderLen }) =>
    loadCustomTypes({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          colors: null,
          parent_ids: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCompanyTypeAlias2.results || [],
      total: res.data?.GetAllCompanyTypeAlias2.total || 0,
    }));

  const loadHeader = () => {
    loadHeadersCustom().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetCompanyCustomTypesGridHeaders ?? [],
      )
        .filter((el) => el.gridVisible)
        .map((el) => {
          if (el.options) {
            el.options = el.options.map((option: any) => ({
              VALUE: option?.id,
              LABEL: option?.name,
            }));
          }
          return el;
        });
      setColumns(filteredResult ?? COLUMNS_TABLE_CUSTOM_TYPES);
    });
  };

  useEffect(() => {
    loadHeader();
  }, []);

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(loadData);

  useEffect(() => {
    reset();
    setColumnFilters([]);
  }, []);

  useEffect(() => {
    if (predefinedTypeChanged) {
      loadHeader();
      reset();
      setPredefinedTypeChanges?.(false);
    }
  }, [predefinedTypeChanged]);

  const setDefaultColor = () => {
    const findColor = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
      (el) => el?.parent?.id === 2,
    );
    setNewRecord((prev) => ({
      ...prev,
      color: findColor?.color as string,
      parent: findColor?.name as string,
    }));
  };

  useEffect(() => {
    if (getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes) {
      setDefaultColor();
    }
  }, [getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes]);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(
      searchField.slice(0, -1),
      search,
      (dataType as string).toLocaleLowerCase() === 'dropdown' ? 'string' : dataType,
    );
    return loadCustomTypes({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
      },
    }).then((res) => {
      if (res.data?.GetAllCompanyTypeAlias2) {
        let modifiedSearch = searchField;
        if (searchField.includes('_')) {
          modifiedSearch = searchField.replace('_', '.');
        }
        return {
          results: res.data.GetAllCompanyTypeAlias2.results,
          filter: res.data.GetAllCompanyTypeAlias2.results.map((result: any) => ({
            value: get(result, modifiedSearch.slice(0, -1)),
            label: get(result, modifiedSearch.slice(0, -1)),
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      parent: 'Customer',
      color: '000000',
    });
    setDefaultColor();
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') return;
    const findParent = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
      (el) => el.name === newRecord.parent,
    );
    await create({
      variables: {
        createCompanyTypeAliasInput: {
          name: capitalizeFirstWord(newRecord.name),
          parent: findParent?.id,
          color: newRecord.color,
        },
      },
    }).then((customType) => {
      setDataSource([
        {
          id: customType.data.CreateCompanyTypeAlias.id,
          name: customType.data.CreateCompanyTypeAlias.name,
          color: customType.data.CreateCompanyTypeAlias.color,
          parent: {
            id: customType.data.CreateCompanyTypeAlias.parent.id,
            name: customType.data.CreateCompanyTypeAlias.parent.name,
          },
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, color, selectedId } = row;
      update({
        variables: {
          updateCompanyTypeAliasInput: {
            id,
            name: capitalizeFirstWord(name),
            color,
            parent: selectedId,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyTypeAliasModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    await Promise.allSettled(
      rows.map((row) =>
        remove({ variables: { id: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            } else if (rows.length === 1) {
              setDataSource((prev) => prev.filter((data) => row.id !== data.id));
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    ).then((res) => {
      if (res.some((response) => response.status === 'rejected')) return;
      if (rows.length === 1) return;
      const ids = rows.map((r) => r.id);
      const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
      setDataSource(copiedDataSource);
    });
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    if (args?.dataType === 'dropDown') {
      if (getAllPredefinedTypesResponse.data?.GetCompaniesBaseTypes) {
        const findColor = getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes?.find(
          (el) => el.id === args?.value,
        );
        setNewRecord((prev) => ({
          ...prev,
          color: findColor?.color as string,
          parent: findColor?.name as string,
        }));
      }
    } else {
      setNewRecord((prev) => ({
        ...prev,
        [args?.dataIndex as string]: args?.value as string,
        autoSave: !!args?.autoSave,
      }));
    }
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    handleUpdateRows,
    handleDeleteRows,
    handleNewRecord,
    pagination,
    columns,
    setColumns,
    columnsToSelectOption,
    columnsHeaderSelected,
    queryResponse,
    newRecord,
    resetNewRecord,
    dataSource,
    onListRender,
    postNewRecord,
    reset,
    predefinedData: getAllPredefinedTypesResponse?.data?.GetCompaniesBaseTypes as [],
  };
};

export default useCustomTypesTable;
