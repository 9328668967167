/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form, FormInstance, Progress, Typography } from 'antd';
import { CustomModal } from 'src/components/commons/customModal';
import { FC, useState, useEffect } from 'react';
import useDebouncedCallback from 'src/hooks/useDebouncedCallback';
import set from 'lodash/set';
import ContactFormBasicInfo from './components/basicInfo';
import ContactFormBusinessInfo from './components/businessInfo';
import ContactFormPersonalInfo from './components/personalInfo';
import ContactFormNotes from './components/notes';
// import ContactFormAttendance from './components/attendance';
import ContactFormContactGroup from './components/contactGroup';
import ContactFormProductInterest from './components/productInterest';
import useCreateContact from './useCreateContact';
import CreateContactStyles from './createContact.styles';
import CreateContactFooter from './components/createContactFooter';

type Props = {
  onClose?: (values: any | null) => void;
  form?: FormInstance;
};

const NewContact: FC<Props> = ({ onClose, form: formInstance }) => {
  let form: FormInstance;
  if (formInstance) {
    form = formInstance;
  } else {
    const [newForm] = Form.useForm();
    form = newForm;
  }

  const [percentage, setPercentage] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const { createContact, saveCompanyAsociatedIdCreateContact, asociatedCompanyId } =
    useCreateContact();

  const handleChanges = useDebouncedCallback(async (changedValues: any, allValues: any) => {
    const values = {} as typeof allValues;
    Object.keys(allValues).forEach((key) => {
      if (!key.includes('onlyVisual')) {
        values[key] = allValues[key];
      }
    });

    const totalFields = Object.keys(values).length;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const completedFields = Object.entries<any>(values).filter(([key, value]) => {
      if (key.endsWith('addresses') && !value?.[0]?.address) return false;
      if (key.endsWith('phones') && !value?.[0]?.phone) return false;
      if (key.endsWith('emails') && !value?.[0]?.email) return false;
      if (value === undefined) return false;

      if (typeof value !== 'boolean' && !value) return false;
      return true;
    }).length;

    const newPercentage = Math.round((completedFields / totalFields) * 100);
    setPercentage(newPercentage);
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsValid(true))
      .catch(() => setIsValid(false));
  }, 200);

  useEffect(() => {
    if (asociatedCompanyId) {
      form
        .validateFields({ validateOnly: true })
        .then(() => setIsValid(true))
        .catch(() => setIsValid(false));
    }
  }, [asociatedCompanyId]);

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    const data: any = {};

    Object.keys(values).forEach((key) => {
      if (!key.includes('onlyVisual')) {
        if (
          ['personalInfo.phones', 'businessInfo.phones'].includes(key) &&
          values[key]?.length > 0
        ) {
          const newPhones: any[] = [];
          values[key].forEach((phone: any, idx: number) => {
            if (idx === 0) newPhones.push(phone);
            if (idx > 0 && !!phone.phone) newPhones.push(phone);
          });
          set(data, key, newPhones);
        } else if (
          ['personalInfo.emails', 'businessInfo.emails'].includes(key) &&
          values[key]?.length > 0
        ) {
          const newMails: any[] = [];
          values[key].forEach((email: any, idx: number) => {
            if (idx === 0) newMails.push(email);
            if (idx > 0 && !!email.email) newMails.push(email);
          });
          set(data, key, newMails);
        } else if (
          ['personalInfo.addresses', 'businessInfo.addresses'].includes(key) &&
          values[key]?.length > 0
        ) {
          const newAddresses: any[] = [];
          values[key].forEach((address: any, idx: number) => {
            if (idx === 0) newAddresses.push(address);
            if (idx > 0 && !!address.address) newAddresses.push(address);
          });
          set(data, key, newAddresses);
        } else {
          set(data, key, values[key]);
        }
      }
    });

    createContact(data).then(() => {
      if (onClose) {
        onClose(data);
      }
    });
  };

  return (
    <>
      <CreateContactStyles />
      <CustomModal
        modalStatus
        onCancelAction={() => onClose && onClose(null)}
        onOkAction={() => 0}
        centered
        customClass='custom-modal'
        headerHeight='72px'
        bodyHeight='589px'
        footerHeight='64px'
        bodyPadding='16px 32px 24px 32px'
        width={1200}
        footer={
          <CreateContactFooter
            onSaveAction={handleSubmit}
            onCancelAction={() => onClose && onClose(null)}
            allowCreateContact={isValid}
          />
        }
        modalTitle={
          <div className='new-company-modal-header-container' data-testid='new-company-modal-title'>
            <div>
              <Typography.Text
                style={{ fontFamily: 'libre franklin, sans-serif', fontSize: '24px' }}
              >
                New Contact
              </Typography.Text>
            </div>
            <div className='progress-bar'>
              <div className='progress-bar-title-container'>
                <Typography.Text type='secondary'>Progress</Typography.Text>
              </div>
              <div className='progress-bar-container'>
                <Progress percent={percentage} />
              </div>
            </div>
          </div>
        }
        modalBody={
          <Form form={form} layout='vertical' onValuesChange={handleChanges}>
            <ContactFormBasicInfo />
            <ContactFormBusinessInfo {...{ saveCompanyAsociatedIdCreateContact }} />
            <ContactFormPersonalInfo />
            {/* <ContactFormWebInfo /> */}
            <ContactFormNotes />
            {/* <ContactFormAttendance /> */}
            <ContactFormContactGroup />
            <ContactFormProductInterest />
          </Form>
        }
      />
    </>
  );
};

export default NewContact;
