import { gql } from '@apollo/client';

export { default as GET_CONTACT_BY_ID } from './getContactByID';
export * from './contactHeaders';

export const GET_ALL_CONTACTS = gql`
  query GetAllContacts2Nest(
    $searchFields: FindContactNestInput
    $criteria: ContactCriteriaNestInput!
  ) {
    GetAllContacts2Nest(SearchFields: $searchFields, Criteria: $criteria) {
      total
      results {
        id
        businessInfo {
          company {
            id
            name
            companyType {
              name
            }
            salesTeam {
              name
            }
          }
          mainEmail {
            id
            email
          }
          mainPhone {
            phone
            id
            countryCode
            areaCode
            createdAt
            extension
          }
          mainAddress {
            address
            city
            addressType {
              name
            }
            continent {
              name
            }
            country {
              name
            }
          }
          jobTitle
        }
        lastName
        firstName
        title
      }
    }
  }
`;

export const GET_ALL_GRID_CONTACTS = gql`
  query GetAllContactsForGrid(
    $criteria: ContactCriteriaNestInput!
    $searchFields: FindContactNestInput
  ) {
    GetAllContactsForGrid(SearchFields: $searchFields, Criteria: $criteria) {
      total
      results {
        id
        lastName
        firstName
        title
        businessInfo {
          company {
            id
            name
            companyType {
              name
            }
            salesTeam {
              name
            }
          }
          mainEmail {
            id
            email
          }
          mainPhone {
            phone
            id
          }
          mainAddress {
            address
            city
            region {
              name
            }
            state {
              name
            }
            zipCode
          }
          jobTitle
        }
      }
    }
  }
`;
