import { FC, createContext, useContext, useRef, useState } from 'react';
import { SubTableExpandableContextProps, SubTableExpandableProviderProps } from '../types';

const SubTableExpandableContext = createContext<SubTableExpandableContextProps>(
  {} as SubTableExpandableContextProps,
);

const SubTableExpandableProvider: FC<SubTableExpandableProviderProps> = ({ children }) => {
  const [rowsExpanded, setRowsExpanded] = useState<any[]>([]);
  const rowsExpandedRef = useRef<any[]>([]);

  const saveRowsExpanded = (newData: any[]) => {
    rowsExpandedRef.current.push(newData);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    rowsExpanded,
    setRowsExpanded,
    rowsExpandedRef,
    saveRowsExpanded,
  };

  return (
    <SubTableExpandableContext.Provider value={value}>
      {children}
    </SubTableExpandableContext.Provider>
  );
};

export const useSubTableExpandableContext = () => useContext(SubTableExpandableContext);

export default SubTableExpandableProvider;
