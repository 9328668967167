const randomNames = [
  { id: 1, name: 'Alejandro' },
  { id: 2, name: 'María' },
  { id: 3, name: 'Juan' },
  { id: 4, name: 'Sofía' },
  { id: 5, name: 'Carlos' },
  { id: 6, name: 'Luisa' },
  { id: 7, name: 'Pedro' },
  { id: 8, name: 'Ana' },
  { id: 9, name: 'Diego' },
  { id: 10, name: 'Laura' },
  { id: 11, name: 'Pablo' },
  { id: 12, name: 'Valentina' },
  { id: 13, name: 'Javier' },
  { id: 14, name: 'Camila' },
  { id: 15, name: 'Mateo' },
  { id: 16, name: 'Isabela' },
  { id: 17, name: 'Andrés' },
  { id: 18, name: 'Lucía' },
  { id: 19, name: 'Gabriel' },
  { id: 20, name: 'Valeria' },
];

export default randomNames;
