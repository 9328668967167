import { Form } from 'antd';
import { FC, PropsWithChildren, useRef } from 'react';
import ModularModal, {
  Props as ModularModalProps,
} from 'src/components/commons/exportModal/ModularModal'; // Adjust the path accordingly
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import ExportCompaniesProvider, {
  useExportCompaniesContext,
} from './contexts/export-companies.context';
import ExportCompaniesFilters from './components/filters';
import ExportCompaniesMoreFilters from './components/moreFilters';
import ExportCompaniesFields from './components/fields';
import ExportCompaniesPreview from './components/preview';

type Props = ModularModalProps & {
  onClose: () => void;
};

const LeftContent = () => {
  const { form, searchFields, setSearchFields, reset } = useExportCompaniesContext();
  return (
    <Form
      layout='vertical'
      form={form}
      onValuesChange={(_ch, fields) => {
        const values = Object.entries(omitBy(fields, isNil)).reduce((acc, [key, value]) => {
          set(acc, key, value);
          return acc;
        }, {}) as { searchFields: Record<string, string[]> };

        if (!isEqual(fields.searchFields, searchFields)) {
          reset();
          setSearchFields(values.searchFields);
        }
      }}
    >
      <ExportCompaniesFilters />
      <ExportCompaniesMoreFilters />
      <ExportCompaniesFields />
    </Form>
  );
};

const ExportCompaniesModal: FC<Props> = ({ onClose }) => {
  const ref = useRef<{ exportTable: () => void }>(null!);

  const leftContent = <LeftContent />;

  const rightContent = <ExportCompaniesPreview />;

  // eslint-disable-next-line react/no-unstable-nested-components
  const ProviderComponent: FC<PropsWithChildren> = ({ children }) => (
    <ExportCompaniesProvider ref={ref}>{children}</ExportCompaniesProvider>
  );

  return (
    <ModularModal
      onClose={onClose}
      modalTitle='Export Company'
      testId='export-companies-modal'
      leftContent={leftContent}
      rightContent={rightContent}
      ProviderComponent={ProviderComponent}
      width={1400}
      onOkAction={() => {
        ref.current.exportTable();
        onClose();
      }}
      okText='Export'
      rowStyle={{ height: 'calc(100vh - 300px)', overflow: 'auto' }}
    />
  );
};

export default ExportCompaniesModal;
