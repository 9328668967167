import { gql } from '@apollo/client';

export const GET_ALL_SALES_TEAMS = gql`
  query GetAllSalesTeam($Criteria: SalesTeamCriteriaInput!) {
    GetAllSalesTeam(Criteria: $Criteria) {
      results {
        id
        name
        companySplit
        specifierSplit
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        companySplitType {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      total
    }
  }
`;

export const GET_ALL_SALES_TEAMS_SHORT = gql`
  query GetAllSalesTeam($criteria: SalesTeamCriteriaInput!) {
    GetAllSalesTeam(Criteria: $criteria) {
      results {
        id
        name
        companySplit
      }
    }
  }
`;

export default GET_ALL_SALES_TEAMS;
