import { Button, Form } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { CustomModal } from '../../customModal';
import { useSubTableContext } from '../contexts/subtable.context';
import StyledModalCreateEdit from '../styles/modalCreateEdit.styles';

interface ModalCreateEditProps {
  BodyModal: React.ComponentType<Record<any, any>>;
  titleNew?: string;
  titleEdit?: string;
  saveNewRecord?: (record?: any) => void;
  handleUpdateRows?: (record: any[]) => void;
  dataSource?: any[];
}

const ModalCreateEdit: FC<ModalCreateEditProps> = ({
  BodyModal,
  titleEdit,
  titleNew,
  saveNewRecord,
  handleUpdateRows,
  dataSource,
}) => {
  const { openModal, setOpenModal, record } = useSubTableContext();
  const [isDisable, setIsDisable] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const setValues = () => {
      form.resetFields();
      if (record?.id) {
        form.setFieldsValue(record);
      }
    };
    setValues();
  }, [record, openModal]);

  const onCancel = () => {
    setOpenModal(false);
  };

  const handleSave = async () => {
    setIsDisable(true);
    try {
      await form.validateFields();
      const fields = form.getFieldsValue();
      if (record) {
        await handleUpdateRows?.([{ ...fields, id: record?.id }]);
      } else {
        await saveNewRecord?.(fields);
      }
      onCancel();
    } catch (error) {
      console.error('Error in form validation:', error);
    } finally {
      setIsDisable(false);
    }
  };

  const validateNameUnique = async (_: any, value: any) => {
    const dataset = dataSource;
    const isNameTaken = dataset?.some(
      (item) =>
        item.name.toLocaleLowerCase() === value?.toLocaleLowerCase() && item.id !== record?.id,
    );
    if (isNameTaken) {
      return Promise.reject(new Error('This name already exists. Please provide unique name'));
    }
    return Promise.resolve();
  };

  return (
    <StyledModalCreateEdit>
      <Form form={form} onFinish={handleSave}>
        <CustomModal
          modalStatus={openModal}
          onCancelAction={onCancel}
          onOkAction={() => 0}
          centered
          customClass='custom-modal-edit'
          closable
          width={1400}
          modalTitle={<h3 style={{ fontWeight: 'normal' }}>{record ? titleEdit : titleNew}</h3>}
          modalBody={<BodyModal {...record} form={form} validateNameUnique={validateNameUnique} />}
          footer={
            <>
              <Button type='default' onClick={onCancel}>
                Cancel
              </Button>
              <Button disabled={isDisable} type='primary' htmlType='submit' onClick={handleSave}>
                {record ? 'Update' : 'Create'}
              </Button>
            </>
          }
          style={{ height: 'max-content' }}
        />
      </Form>
    </StyledModalCreateEdit>
  );
};

export default ModalCreateEdit;
