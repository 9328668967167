/* eslint-disable @typescript-eslint/no-unused-vars */
import { QuestionOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Checkbox, Input, Table, Row, Col, Button, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import StyledUserTable from './styles/userTable.styles';
import { SubTableColumn } from '../commons/subTable/types';
import { showConfirmationMessage } from '../commons/subTable/helpers';

interface UserTableProps {
  users?: {
    id: number;
    name: string;
  }[];
  isUserTable?: boolean;
  columns?: SubTableColumn[] | [];
  hasPrimaryContact?: boolean;
  primaryContact?: number;
  handlePrimaryContact?: (id: number) => void;
  selectedRows?: { id: number; name: string }[] | [];
  setSelectedRows?: React.Dispatch<React.SetStateAction<{ id: number; name: string }[] | []>>;
  infoIconText?: string;
  title?: string;
}

const UserTable: FC<UserTableProps> = ({
  users,
  isUserTable,
  columns,
  hasPrimaryContact,
  primaryContact,
  handlePrimaryContact,
  setSelectedRows,
  selectedRows,
  infoIconText,
  title,
}) => {
  const { pathname } = useLocation();
  const [isChecked, setIsChecked] = useState(false);
  const [cols, setCols] = useState([]);
  const [dataToShow, setDataToShow] = useState<{ id: number; name: string }[]>([]);

  useEffect(() => {
    setDataToShow(users || []);
  }, [users]);

  useEffect(() => {
    if (!isUserTable) {
      const dataCols = [...(columns as SubTableColumn[])];
      dataCols.unshift(Table.SELECTION_COLUMN as any);
      if (hasPrimaryContact)
        dataCols.unshift({
          width: 30,
          minWidth: 30,
          render(value) {
            return (
              <Button
                className='svg-contanier'
                style={{
                  fontSize: 16,
                  fontWeight: 500,
                  marginLeft: '0.5rem',
                }}
                onClick={() => {
                  handlePrimaryContact?.(value.id);
                }}
              >
                <Tooltip title='Leader' color='gray'>
                  {value.id === primaryContact ? (
                    <StarFilled className='filled' />
                  ) : (
                    <StarOutlined className='outlined' />
                  )}
                </Tooltip>
              </Button>
            );
          },
          title: ' ',
          dataIndex: undefined!,
        });
      setCols(dataCols as unknown as []);
    } else {
      setCols(columns as []);
    }
  }, [columns, primaryContact]);

  useEffect(() => {
    if (!isUserTable) return;
    if (selectedRows?.length === users?.length) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedRows, users]);

  const handleCheck = () => {
    if (isUserTable) {
      setIsChecked((prev) => {
        if (prev) {
          setSelectedRows?.([]);
        } else {
          setSelectedRows?.(users as []);
        }
        return !prev;
      });
    } else {
      setSelectedRows?.([]);
    }
  };

  const handleQuestionButton = () => {
    showConfirmationMessage({
      content: '',
      title:
        infoIconText ||
        (isUserTable
          ? 'In this list you can find the users available in RepFabric. Select those you want to have on the team.'
          : 'In this list you can find the users that you have selected.'),
      cancelText: 'Close',
      okButtonProps: {
        style: { display: 'none' },
      },
    });
  };

  const handlingFilter = (value: string) => {
    const filteredData =
      users?.filter((item: { id: number; name: string }) =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      ) || [];

    setDataToShow(filteredData);
  };

  return (
    <StyledUserTable
      hasFooter={!isUserTable && pathname.includes('sales_teams')}
      showHeader={pathname.includes('sales_teams')}
    >
      <Row justify='space-between' align='middle'>
        <Col>
          <h3>{title || (isUserTable ? 'Users' : 'Team Members')}</h3>
        </Col>
        <Col className='question-icon'>
          <Button
            onClick={handleQuestionButton}
            data-testid={`question-icon-${title?.toLowerCase()}`}
          >
            <QuestionOutlined />
          </Button>
        </Col>
      </Row>
      <Row gutter={8} align='middle'>
        <Col sm={1}>
          <Tooltip
            title={
              isUserTable
                ? 'Checking this box will select all Menu Items'
                : 'Unchecking this box will remove all items from the list'
            }
            color='gray'
          >
            <div>
              <Checkbox
                checked={isUserTable ? isChecked : users && users?.length > 0}
                onChange={handleCheck}
              />
            </div>
          </Tooltip>
        </Col>
        <Col sm={22} style={{ width: '100%', marginLeft: 8 }}>
          <Input
            data-testid={`search-input-${title?.toLowerCase()}`}
            placeholder='Columns'
            style={{ border: 'solid 1px #0078D4', width: '100%' }}
            onChange={(event: any) => handlingFilter(event.target.value)}
          />
        </Col>
      </Row>
      <div className='table-container'>
        <div className='table-inner-container'>
          <Table
            dataSource={dataToShow ?? []}
            columns={cols}
            rowKey={(record) => record.id}
            showHeader={pathname.includes('sales_teams')}
            pagination={false}
            style={{ height: '100%', minHeight: '1em' }}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: !isUserTable
                ? (dataToShow?.map((user) => user.id) as React.Key[])
                : selectedRows?.map((user) => user.id),
              onSelect: (key) => {
                setSelectedRows?.((prev) => {
                  let copied = [...prev];
                  if (copied.some((rec) => rec.id === key.id)) {
                    copied = [...prev].filter((rec) => rec.id !== key.id);
                  } else {
                    copied = [...copied, key];
                  }
                  return copied;
                });
                setIsChecked(false);
              },
              columnWidth: 30,
            }}
            footer={
              pathname.includes('sales_teams') && !isUserTable
                ? // eslint-disable-next-line react/no-unstable-nested-components
                  () => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                      }}
                    >
                      <p
                        style={{
                          color: '#C92C2C',
                          lineHeight: '22px',
                          fontSize: '14px',
                          fontWeight: '400',
                        }}
                      >
                        To assign:{' '}
                        <strong
                          style={{
                            color: '#C92C2C',
                            lineHeight: '22px',
                            fontSize: '14px',
                            fontWeight: '600',
                          }}
                        >
                          10.6%
                        </strong>
                      </p>
                      <p
                        style={{
                          color: '#C6C4C4',
                          lineHeight: '24px',
                          fontSize: '16px',
                          fontWeight: '500',
                        }}
                      >
                        Total assigned:{' '}
                        <strong
                          style={{
                            color: '#4B4B4B',
                            fontSize: '24px',
                            fontWeight: '600',
                            lineHeight: '32px',
                          }}
                        >
                          19.5%
                        </strong>
                      </p>
                    </div>
                  )
                : undefined
            }
          />
        </div>
      </div>
    </StyledUserTable>
  );
};

export default UserTable;
