import { useEffect, useRef, useState } from 'react';

export type ListInfo = { start: number; renderLen: number; filter?: string };
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Payload = { total: number; results: any[] };
export type FetchFunc = (info: ListInfo) => Promise<Payload>;

const getInitialData = (size: number) => {
  const data: { placeholder: boolean; ID: string }[] = [];
  for (let i = 0; i < size; i += 1) {
    data.push({
      placeholder: true,
      ID: Math.random().toString(32),
    });
  }
  return data;
};

const useInfinityDataSource = (fetchData: FetchFunc, size = 100) => {
  const listInfoRef = useRef<ListInfo>({ start: 0, renderLen: 0 });
  const loadedPageRef = useRef<boolean[]>([false]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [dataSource, setDataSource] = useState<any[]>(getInitialData(10));

  const getCurrentPage = (start: number) => Math.floor(start / size);

  const isPageLoaded = (page: number) => {
    if (page < 0 || page >= loadedPageRef.current.length) return true;
    return loadedPageRef.current[page];
  };

  const getPageInfo = (page: number) => {
    const start = page * size;
    return { start, renderLen: size };
  };

  const fetchPage = async (start: number, renderLen: number) =>
    fetchData({ start, renderLen }).then(({ total, results }) => {
      setDataSource((prev) => {
        const newData = prev;
        if (newData.length !== total && typeof total === 'number') {
          newData.length = total;
          loadedPageRef.current = Array.from<boolean>({ length: Math.ceil(total / size) }).fill(
            false,
          );
          for (let i = 0; i < newData.length; i += 1) {
            if (!newData[i]) {
              newData[i] = {
                placeholder: true,
                ID: Math.random().toString(32),
              };
            }
          }
        }
        results?.forEach((record, index) => {
          newData[index + start] = record;
        });
        loadedPageRef.current[getCurrentPage(start)] = true;
        return [...newData];
      });
    });

  const loadPage = async () => {
    const listInfo = listInfoRef.current;
    const { start } = listInfo;

    const currentPage = getCurrentPage(start);
    const prevPage = currentPage - 1;
    const nextPage = currentPage + 1;

    try {
      if (!isPageLoaded(currentPage)) {
        const pageInfo = getPageInfo(currentPage);
        await fetchPage(pageInfo.start, pageInfo.renderLen);
      }

      if (!isPageLoaded(prevPage)) {
        const pageInfo = getPageInfo(prevPage);
        await fetchPage(pageInfo.start, pageInfo.renderLen);
      }

      if (!isPageLoaded(nextPage)) {
        const pageInfo = getPageInfo(nextPage);
        await fetchPage(pageInfo.start, pageInfo.renderLen);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onListRender = (listInfo: ListInfo) => {
    const currentListInfo = listInfoRef.current;
    if (
      listInfo.start !== currentListInfo.start ||
      listInfo.renderLen !== currentListInfo.renderLen
    ) {
      listInfoRef.current = listInfo;
      loadPage();
    }
  };

  const reset = (sz = 10) => {
    setDataSource((old) => {
      old.length = sz;

      const newData = old.map(() => ({
        placeholder: true,
        ID: Math.random().toString(32),
      }));

      Object.assign(newData, { reset: true });

      return newData;
    });
  };

  useEffect(() => {
    // get the own property `reset` of datasource
    const isReset = Object.prototype.hasOwnProperty.call(dataSource, 'reset');
    if (isReset) {
      listInfoRef.current = { start: -1, renderLen: -1 };
      loadedPageRef.current = [false];
      onListRender({ start: 0, renderLen: size });
    }
  }, [dataSource]);

  return { dataSource, onListRender, reset, setDataSource };
};

export default useInfinityDataSource;
