import { gql } from '@apollo/client';

export const CREATE_PRODUCT_FAMILY = gql`
  mutation CreateProductFamily($createProductFamilyMasterInput: CreateProductFamilyMasterInput!) {
    CreateProductFamily(createProductFamilyMasterInput: $createProductFamilyMasterInput) {
      id
      name
    }
  }
`;

export const UPDATE_PRODUCT_FAMILY = gql`
  mutation UpdateProductFamilyMaster($updateProductFamilyInput: UpdateProductFamilyMasterInput!) {
    UpdateProductFamilyMaster(updateProductFamilyMasterInput: $updateProductFamilyInput) {
      name
    }
  }
`;
export const REMOVE_PRODUCT_FAMILY = gql`
  mutation RemoveProductFamily($id: Int!) {
    RemoveProductFamily(id: $id)
  }
`;
