import { FC, useEffect, useState } from 'react';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import FormSection from 'src/components/formSection';
import useContactGroupStore from 'src/hooks/stores/useContactGroupStores';
import { useContactContext } from 'src/context/contact.context';
import { useContactsContext } from 'src/modules/contacts/contexts/contacts.contexts';
import CustomSelect from 'src/components/commons/customSelect';

type Props = { showTitle?: boolean };

interface AutocompleteComponentProps {
  options: { value: number; label: string }[];
  onChange?: (e: string) => void;
  readOnly?: boolean;
  disabled?: boolean;
}

const AutocompleteComponent: FC<AutocompleteComponentProps> = ({
  options,
  onChange,
  readOnly,
  disabled,
}) => (
  <CustomSelect
    multiSel
    handleMultipleSelect={(_, opt) => onChange?.(opt as any)}
    listDefault={options}
    disabled={disabled || readOnly}
    externalWidth='100%'
    customClassName={readOnly ? 'readOnly-ant-multisel' : ''}
  />
);
const ContactFormContactGroup: FC<Props> = ({ showTitle = true }) => {
  const { dataSource } = useContactGroupStore();
  const [opt, setOpt] = useState<{ value: number; label: string }[] | []>([]);
  const { activeExpandedEdition } = useContactContext();
  const { createContactModalActive } = useContactsContext();

  useEffect(() => {
    const data = dataSource?.map((group) => ({
      value: group?.name,
      label: group?.name,
      toSend: group.id,
    }));
    setOpt(data);
  }, [dataSource]);

  const fields: FieldType[] = [
    {
      name: 'contactGroup',
      type: 'select',
      options: opt,
      render: () => (
        <AutocompleteComponent
          options={opt}
          readOnly={!activeExpandedEdition && !createContactModalActive}
        />
      ),
      span: 24,
    },
  ];

  if (!showTitle) {
    return <GenericForm fields={fields} />;
  }

  return (
    <FormSection title='Contact Group'>
      <GenericForm fields={fields} />
    </FormSection>
  );
};

export default ContactFormContactGroup;
