import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import randomNames from 'src/constants/usersMembers';

type ContextProps = {
  data: any[];
  columns: SubTableColumn[];
  selectedRecord?: Record<any, any>;
  columnsMembers: SubTableColumn[] | [];
} & PropsWithChildren;

type SettingTableContextProps = {
  currentRecordTitle: string;
  handleRecordData: (record: any) => any;
  users: { id: number; name: string }[];
  columns: SubTableColumn[] | [];
  columnsMembers: SubTableColumn[] | [];
  hasPrimaryContact?: boolean;
  primaryContact?: number;
  handlePrimaryContact: (id: number) => void;
  selectedRows:
    | {
        id: number;
        name: string;
      }[]
    | [];
  setSelectedRows: React.Dispatch<
    React.SetStateAction<
      | {
          id: number;
          name: string;
        }[]
      | []
    >
  >;
  infoIconTextUser?: string;
  infoIconTextMembers?: string;
  usersMembersTitle?: Record<'users' | 'members', string>;
};

const initialState: SettingTableContextProps = {} as SettingTableContextProps;

const settingTableContext = createContext(initialState);

const SettingTableContextProvider: FC<ContextProps> = ({
  children,
  data,
  columns: cols,
  selectedRecord,
  columnsMembers,
  ...rest
}) => {
  const [currentRecordTitle, setCurrentRecordTitle] = useState<string>('');
  const [users, setUsers] = useState<{ id: number; name: string }[] | []>([]);
  const [columns, setColumns] = useState<SubTableColumn[] | []>([]);
  const [primaryContact, setPrimaryContact] = useState<number | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<{ id: number; name: number }[] | []>([]);

  useEffect(() => {
    // TODO implmemt a function to handling the data and set users
    setUsers(randomNames);
    setColumns(cols);
  }, [cols, data]);

  const handleRecordData = (record: any) => {
    setCurrentRecordTitle(record?.name as string);
    setUsers(record?.users as []);
  };

  const handlePrimaryContact = (id: number) => {
    if (primaryContact === id) {
      setPrimaryContact(undefined);
    } else {
      setPrimaryContact(id);
    }
  };

  const value = useMemo(
    () => ({
      currentRecordTitle,
      handleRecordData,
      users,
      columns,
      columnsMembers,
      primaryContact,
      handlePrimaryContact,
      selectedRows,
      setSelectedRows,
      ...rest,
    }),
    [
      currentRecordTitle,
      users,
      columns,
      columnsMembers,
      primaryContact,
      handlePrimaryContact,
      selectedRows,
      setSelectedRows,
    ],
  ) as SettingTableContextProps;

  return <settingTableContext.Provider value={value}>{children}</settingTableContext.Provider>;
};

export const useSettingTableContext = () => useContext(settingTableContext);

export default SettingTableContextProvider;
