import { salesTeamAdapter } from 'src/adapters';
import { GET_ALL_SALES_TEAMS_SHORT, GET_PRIVATE_TEAM } from 'src/graphql';
import { SalesTeamModel } from 'src/models';
import {
  CREATE_SALES_TEAM,
  REMOVE_SALES_TEAM,
  UPDATE_SALES_TEAM,
} from 'src/graphql/mutations/sales-team';
import useEntityAdapter, { OperationSpecs } from '../useEntityAdapter';

const operations: OperationSpecs = {
  createOne: {
    op: CREATE_SALES_TEAM,
  },
  updateOne: {
    op: UPDATE_SALES_TEAM,
  },
  deleteOne: {
    op: REMOVE_SALES_TEAM,
  },
  fetchAll: {
    op: GET_ALL_SALES_TEAMS_SHORT,
    dataPath: 'GetAllSalesTeam',
  },
  fetchOne: {
    op: GET_PRIVATE_TEAM,
    dataPath: 'GetSalesTeam',
  },
};

const useSalesTeamsStore = () => {
  const api = useEntityAdapter<SalesTeamModel>({
    adapters: { entityAdapter: salesTeamAdapter },
    operations,
    isPaginated: true,
    hasFilter: false,
    hasSort: false,
    entityName: 'SalesTeam',
  });

  return api;
};

export default useSalesTeamsStore;
