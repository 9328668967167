import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { AllFilters2, ProductInterestModel } from 'src/graphql/schema-types';
import handleError from 'src/utils/functions/handleError';
import COLUMNS from '../constants';
import { GET_ALL_PRODUCT_INTEREST, GET_PRODUCT_INTEREST_HEADERS } from '../graphql/queries';
import {
  UPDATE_PRODUCT_INTEREST,
  CREATE_PRODUCT_INTEREST,
  REMOVE_PRODUCT_INTEREST,
} from '../graphql/mutations';
import { ProductInterest, GetAllProductInterest, GetProductInterestGridHeaders } from '../types';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';

const useProductInterestTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, queryResponse] = useLazyQuery<GetAllProductInterest>(GET_ALL_PRODUCT_INTEREST, {
    fetchPolicy: 'network-only',
  });
  const [getAllSearch] = useLazyQuery<GetAllProductInterest>(GET_ALL_PRODUCT_INTEREST, {
    fetchPolicy: 'network-only',
  });
  const [getHeaders] = useLazyQuery<GetProductInterestGridHeaders>(GET_PRODUCT_INTEREST_HEADERS, {
    fetchPolicy: 'network-only',
  });
  const [update] = useMutation(UPDATE_PRODUCT_INTEREST);
  const [create] = useMutation(CREATE_PRODUCT_INTEREST);
  const [remove] = useMutation(REMOVE_PRODUCT_INTEREST);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchProductInterestPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllProductInterest2.results || [],
      total: res.data?.GetAllProductInterest2.total || 0,
    }));

  const { dataSource, onListRender, setDataSource, reset } =
    useInfinityDataSource(fetchProductInterestPage);

  useEffect(() => {
    getHeaders().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetProductInterestGridHeaders as unknown as SubTableColumn[],
      ).map((header) => {
        if (header.dataIndex === 'name')
          return {
            ...header,
            searchField: 'name',
            title: 'PRODUCT NAME',
          };
        return header;
      });
      setColumns(filteredResult ?? COLUMNS);
      reset();
    });
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    getAllSearch({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllProductInterest2?.results,
          filter: res?.data?.GetAllProductInterest2?.results.map((result: ProductInterest) => ({
            value: result[searchField],
            label: result[searchField],
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createProductInterestInput: {
          name: capitalizeFirstWord(newRecord.name),
        },
      },
    }).then((product) => {
      setDataSource([
        {
          id: product.data.CreateProductInterest.id,
          name: product.data.CreateProductInterest.name,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: ProductInterestModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateProductInterestInput: {
            id,
            name: capitalizeFirstWord(name as string),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: ProductInterestModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the Contact. You can not delete it',
      },
    };
    setDeleting(true);
    await Promise.all(
      rows.map((row) =>
        remove({ variables: { removeProductInterestId: row.id } })
          .then(({ errors }) => {
            setDeleting(false);
            reset();
            if (errors) {
              handleError(errors[0], messages);
            }
          })
          .catch((err) => {
            setDeleting(false);
            handleError(err, messages);
            throw err;
          }),
      ),
    );
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  const handleClearFilter = () => {
    reset();
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: queryResponse.loading || deleting,
    newRecord,
    handleNewRecord,
    resetNewRecord,
    postNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useProductInterestTable;
