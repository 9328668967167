import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { ContactPersonalInfoModel } from 'src/models';
import addressAdapter from './address.adapter';
import { list } from './common';
import emailAdapter from './email.adapter';
import phoneAdapter from './phone.adapter';

const contactPersonalInfoAdapter: AdapterFunc<
  GQL.ContactPersonalInfoModel,
  ContactPersonalInfoModel
> = (input) => ({
  id: input.id,
  addresses: list(addressAdapter, input.addresses),
  city: input.city!,
  country: input.country!,
  createdAt: input.createdAt,
  email: input.email!,
  emails: list(emailAdapter, input.emails),
  phone: input.phone!,
  phones: list(phoneAdapter, input.phones),
  pobox: input.pobox!,
  zipCode: input.postalCode!,
  state: input.state!,
  street: input.street!,
  updatedAt: input.updatedAt,
});

export default contactPersonalInfoAdapter;
