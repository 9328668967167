import { Navigate, Route, Routes } from 'react-router';
import CustomFooter from 'src/components/commons/customFooter';
import CustomViewContainer from 'src/components/commons/customViewContainer';
import NotFoundPage from 'src/components/commons/notFound';
import Companies from 'src/components/company';
import CompanyBasic from 'src/components/companyDetails/components/companyBasic';
import Pitches from 'src/components/companyDetails/components/pitches';
import ContactCompaniesExpandedInfo from 'src/components/companyDetails/contacts';
import { CompaniesContextProvider } from 'src/context/companies.context';
import { GlobalContextProvider } from 'src/context/global.context';
import { TableContextProvider } from 'src/context/table.context';
import CompanySetupLayout from 'src/layouts/companySetup.layout';

const CompaniesRoutes = () => (
  <Routes>
    <Route
      path='/'
      element={
        <CompaniesContextProvider>
          <TableContextProvider>
            <GlobalContextProvider>
              <CustomViewContainer>
                <Companies />
                <CustomFooter />
              </CustomViewContainer>
            </GlobalContextProvider>
          </TableContextProvider>
        </CompaniesContextProvider>
      }
    />
    <Route path='/:id' element={<CompanySetupLayout />}>
      <Route index path='basic' element={<CompanyBasic />} />
      <Route index path='pitches' element={<Pitches />} />
      <Route index path='contacts' element={<ContactCompaniesExpandedInfo />} />
      <Route index path='opportunities' element={<h2>opportunities</h2>} />
      <Route index path='quotes' element={<h2>quotes</h2>} />
      <Route index path='attachments' element={<h2>attachments</h2>} />
      <Route index path='activity-journal' element={<h2>activity journal</h2>} />
      <Route index path='buying-form' element={<h2>buying form</h2>} />
      <Route index path='jobs' element={<h2>jobs</h2>} />
      <Route index path='mails' element={<h2>mails</h2>} />
      <Route index path='samples' element={<h2>samples</h2>} />
      <Route index path='products' element={<h2>products</h2>} />
      <Route index path='task' element={<h2>task</h2>} />
      <Route index path='*' element={<Navigate to='basic' replace />} />
    </Route>
    <Route path='*' element={<NotFoundPage />} />
  </Routes>
);

export default CompaniesRoutes;
