import { Form, FormInstance, notification } from 'antd';
import _ from 'lodash';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import {
  addressToInputAdapter,
  emailToInputAdapter,
  list,
  phoneToInputAdapter,
} from 'src/adapters';
import useContactsStore from 'src/hooks/stores/useContactsStore';
import { ViewNames } from 'src/types/viewTypes';
import { useDeepLocalStorage } from 'src/hooks/useLocalStorage';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import { objectAreEquals } from 'src/utils/functions/object';
import { AddressModel, ContactModel } from 'src/models';
import cloneDeep from 'lodash/cloneDeep';
import { Maybe } from 'graphql/jsutils/Maybe';
import { IntegratedCustomField } from 'src/components/companyDetails/components/customFields/types/customFieldsTypes';
import { CustomFieldPayload } from 'src/types/companiesTypes';
import useCustomFieldCard from 'src/components/contactDetails/components/customFields/hooks/useCustomFieldCard';
import dayjs from 'dayjs';
import detectJSON from 'src/utils/functions/detectJSON';
import { useMutation } from '@apollo/client';
import {
  CREATE_ADDITIONAL_FIELDS,
  EDIT_ADDITIONAL_FIELDS,
} from 'src/components/contactDetails/components/customFields/graphql/mutations';
import { UPDATE_ONE_CONTACT } from 'src/graphql/mutations/contact';
import {
  filterContactEmailPhoneAddressInfo,
  prepareDataToDelete,
  mainAndAditionalElement,
} from 'src/components/contactDetails/utils/filterContactEmailPhoneAddressInfo';
import { detectDiferences } from 'src/components/contactDetails/utils/detectChanges';

interface ContactContextProps {
  contact: ContactModel | null;
  form: FormInstance;
  submitData: boolean;
  createContactModalActive: boolean;
  activeCreateContact: () => void;
  desactiveCreateContact: () => void;
  handleSave: () => void;
  customFieldPayload: CustomFieldPayload[];
  handleCustomFieldPayload: (innerData: CustomFieldPayload[]) => void;
  showSpinCustomFields: boolean;
  integratedCustomField: IntegratedCustomField[];
  saveCompanyAsociatedId: (companyId: number) => void;
  activeExpandedEdition: boolean;
  handleActiveExpandedEdition: (innerStatus?: string) => void;
  saveAddressToDelete: (companyId: [number], formName?: string) => void;
  savePhonesToDelete: (phoneId: [number], formName?: string) => void;
  saveEmailsToDelete: (emailId: [number], formName?: string) => void;
  processedSave: boolean;
}

const initialState: ContactContextProps = {} as ContactContextProps;

const ContactContext = createContext(initialState);

const ContactContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const initialValuesRef = useRef<any>();
  const [createContactModalActive, setCreateContactModalActive] = useState(false);
  const [submitData, setSubmitData] = useState(false);
  const [customFieldPayload, setCustomFieldPayload] = useState<CustomFieldPayload[]>([]);
  const [showSpinCustomFields, setShowSpinCustomFields] = useState(true);
  const [integratedCustomField, setIntegratedCustomField] = useState<IntegratedCustomField[]>([]);
  const [customFieldsValues, setCustomFieldsValues] = useState<Maybe<Record<string, string>>>(null);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);
  const { loadById, selected, setSelected } = useContactsStore();
  const { id } = useParams();
  const asociatedCompanyId = useRef<number>();
  const [activeExpandedEdition, setActiveExpandedEdition] = useState(false);
  const bussinessAddressToDelete = useRef<number[]>([]);
  const personalAddressToDelete = useRef<number[]>([]);
  const bussinessPhonesToDelete = useRef<number[]>([]);
  const personalPhonesToDelete = useRef<number[]>([]);
  const bussinessEmailsToDelete = useRef<number[]>([]);
  const personalEmailsToDelete = useRef<number[]>([]);
  const [processedSave, setProcessedSave] = useState(false);

  const {
    getAllCustomFieldResponse,
    fetchCustomFields,
    fetchContactCustomFields,
    getAllContactCustomFieldResponse,
  } = useCustomFieldCard();

  const getContactInfo = () => {
    loadById(Number(id));
  };

  const handleActiveExpandedEdition = (innerStatus?: string) => {
    if (innerStatus === 'true') {
      setActiveExpandedEdition(true);
    } else if (innerStatus === 'false') {
      setActiveExpandedEdition(false);
    } else {
      setActiveExpandedEdition(!activeExpandedEdition);
    }
  };

  const handleCustomFieldPayload = (innerData: CustomFieldPayload[]) => {
    setCustomFieldPayload(innerData);
  };

  const { storeData } = useDeepLocalStorage(ViewNames.detailContact, {}, 'localStorage');

  const { setShowExitPrompt } = useUnSaveChangesWarning();

  const activeShowPrompt = () => {
    const initialDataForm = JSON.parse(localStorage.getItem(ViewNames.detailContact) ?? '{}');
    const formValues = form.getFieldsValue();

    if (
      initialDataForm &&
      formValues.firstName &&
      !objectAreEquals({ ...initialDataForm }, { ...formValues }) &&
      activeExpandedEdition
    ) {
      storeData(form.getFieldsValue());
      localStorage.setItem('detail-contact-id', id as string);
      setShowExitPrompt(true);
    } else {
      setShowExitPrompt(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateContact] = useMutation(UPDATE_ONE_CONTACT, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'Contact Updated',
        key: 'update-contact-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'update-contact-error',
      });
    },
  });

  const [createCustomFieldRelation] = useMutation(CREATE_ADDITIONAL_FIELDS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'Custom Field Created',
        key: 'create-custom-field-relation-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'create-custom-field-relation-error',
      });
    },
  });

  const [updateCustomFieldRelation] = useMutation(EDIT_ADDITIONAL_FIELDS, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'Custom Field Updated',
        key: 'update-custom-field-relation-success',
      });
    },
    onError: (error) => {
      notification.error({
        message: error.message,
        key: 'update-custom-field-relation-error',
      });
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleEditCustomFields = () => {
    customFieldPayload.forEach((element) => {
      const { value, relationId } = element;
      const innerId = element.id;
      if (element.relationId) {
        updateCustomFieldRelation({
          variables: {
            updateContactAdditionalFieldsContactInput: {
              contactId: selected?.id,
              additionalFieldsContactId: Number(innerId),
              value,
              id: Number(relationId),
            },
          },
        });
      } else {
        createCustomFieldRelation({
          variables: {
            createContactAdditionalFieldsContactInput: {
              contactId: selected?.id,
              additionalFieldsContactId: Number(innerId),
              value,
            },
          },
        });
      }
    });
  };

  const saveCompanyAsociatedId = (companyId: number) => {
    asociatedCompanyId.current = companyId;
  };

  const saveAddressToDelete = (addressId: [number], formName?: string) => {
    if (formName === 'bussinessInfo') {
      bussinessAddressToDelete.current = prepareDataToDelete(
        addressId,
        bussinessAddressToDelete.current,
      );
    } else {
      personalAddressToDelete.current = prepareDataToDelete(
        addressId,
        personalAddressToDelete.current,
      );
    }
  };

  const savePhonesToDelete = (phoneId: [number], formName?: string) => {
    if (formName === 'bussinessInfo') {
      bussinessPhonesToDelete.current = prepareDataToDelete(
        phoneId,
        bussinessPhonesToDelete.current,
      );
    } else {
      personalPhonesToDelete.current = prepareDataToDelete(phoneId, personalPhonesToDelete.current);
    }
  };

  const saveEmailsToDelete = (emailId: [number], formName?: string) => {
    if (formName === 'bussinessInfo') {
      bussinessEmailsToDelete.current = prepareDataToDelete(
        emailId,
        bussinessEmailsToDelete.current,
      );
    } else {
      personalEmailsToDelete.current = prepareDataToDelete(emailId, personalEmailsToDelete.current);
    }
  };

  const transformKeys = (obj: any) =>
    _.transform(obj, (result: any, value, key: string) => {
      const newKey = key.replace(/\./g, '.');
      _.set(result, newKey, value);
    });

  const filterFieldsToUpdate = (contactData: ContactModel) => {
    const filteredValues = {
      firstName: _.get(contactData, 'firstName'),
      lastName: _.get(contactData, 'lastName'),
      personalInfo: _.get(contactData, 'personalInfo'),
      businessInfo: _.get(contactData, 'businessInfo'),
      notes: _.get(contactData, 'notes'),
    };

    return filteredValues;
  };
  const handleSave = () => {
    setProcessedSave(false);
    handleActiveExpandedEdition();
    const contactData = transformKeys(values);
    const innerInitialValues = transformKeys(initialValuesRef.current);
    const newPayload = detectDiferences(
      filterFieldsToUpdate(innerInitialValues),
      filterFieldsToUpdate(contactData),
    );
    const filteredPayload = _.pickBy(
      filterContactEmailPhoneAddressInfo(newPayload),
      (value) => !_.isEmpty(value) || _.isNumber(value),
    );
    if (bussinessAddressToDelete.current.length) {
      if (filteredPayload.businessInfo) {
        filteredPayload.businessInfo.deleteAddresses = bussinessAddressToDelete.current;
      } else {
        filteredPayload.businessInfo = { deleteAddresses: bussinessAddressToDelete.current };
      }
    }
    if (personalAddressToDelete.current.length) {
      if (filteredPayload.personalInfo) {
        filteredPayload.personalInfo.deleteAddresses = personalAddressToDelete.current;
      } else {
        filteredPayload.personalInfo = { deleteAddresses: personalAddressToDelete.current };
      }
    }
    if (bussinessPhonesToDelete.current.length) {
      if (filteredPayload.businessInfo) {
        filteredPayload.businessInfo.deletePhones = bussinessPhonesToDelete.current;
      } else {
        filteredPayload.businessInfo = { deletePhones: bussinessPhonesToDelete.current };
      }
    }
    if (personalPhonesToDelete.current.length) {
      if (filteredPayload.personalInfo) {
        filteredPayload.personalInfo.deletePhones = personalPhonesToDelete.current;
      } else {
        filteredPayload.personalInfo = { deletePhones: personalPhonesToDelete.current };
      }
    }
    if (bussinessEmailsToDelete.current.length) {
      if (filteredPayload.businessInfo) {
        filteredPayload.businessInfo.deleteEmails = bussinessEmailsToDelete.current;
      } else {
        filteredPayload.businessInfo = { deleteEmails: bussinessEmailsToDelete.current };
      }
    }
    if (personalEmailsToDelete.current.length) {
      if (filteredPayload.personalInfo) {
        filteredPayload.personalInfo.deleteEmails = personalEmailsToDelete.current;
      } else {
        filteredPayload.personalInfo = { deleteEmails: personalEmailsToDelete.current };
      }
    }
    filteredPayload.id = Number(id);
    if (asociatedCompanyId.current) {
      if (filteredPayload.businessInfo) {
        filteredPayload.businessInfo.company = asociatedCompanyId.current;
      } else {
        filteredPayload.businessInfo = { company: asociatedCompanyId.current };
      }
    }
    updateContact({
      variables: {
        updateContactInput: filteredPayload,
      },
    }).then((data) => {
      setProcessedSave(true);
      if (data?.data?.UpdateContact) {
        setSelected(data.data.UpdateContact);
      }
    });
    handleEditCustomFields();
  };

  const activeCreateContact = () => {
    setCreateContactModalActive(true);
  };

  const desactiveCreateContact = () => {
    setCreateContactModalActive(false);
  };

  const activeSubmitData = () => {
    form
      .validateFields()
      .then(() => {
        setSubmitData(true);
      })
      .catch(() => {
        setSubmitData(false);
      });
  };

  useEffect(() => {
    getContactInfo();
    fetchCustomFields(0, 100);
    fetchContactCustomFields(Number(id));
  }, []);

  useEffect(() => {
    if (getAllCustomFieldResponse.data && getAllContactCustomFieldResponse.data) {
      const innerCustomFieldElements =
        getAllCustomFieldResponse.data?.GetAllAdditionalFields.results.filter(
          (element) => element.module?.toLowerCase() === 'contact',
        );
      const dedicatedCustomFieldData =
        getAllContactCustomFieldResponse.data?.GetAllContactAdditionalField;
      const innerIntegratedCustomField: IntegratedCustomField[] = [];

      innerCustomFieldElements.forEach((element) => {
        let innerValue;
        let innerRelationId;
        const innerCustomFieldElementData = dedicatedCustomFieldData.find(
          (item) => item.additionalField.id === element.id,
        );
        if (innerCustomFieldElementData) {
          innerValue = innerCustomFieldElementData.value || undefined;
          innerRelationId = innerCustomFieldElementData?.id;
        } else if (element.dataStructure?.selected) {
          innerValue = element.dataStructure?.selected;
        } else {
          innerValue = undefined;
        }
        const innerElement: IntegratedCustomField = {
          options: element.dataStructure?.options ?? undefined,
          id: JSON.stringify(element?.id),
          label: element.name,
          dataType: element.dataType,
          isRequired: element.dataStructure?.required,
          value: innerValue,
          relationId: innerRelationId ?? undefined,
        };
        innerIntegratedCustomField.push(innerElement);
      });
      setIntegratedCustomField(innerIntegratedCustomField);

      setShowSpinCustomFields(false);
    }
  }, [getAllContactCustomFieldResponse.data]);

  useEffect(() => {
    if (integratedCustomField.length) {
      const newFieldsValue = integratedCustomField.reduce((acc: any, element) => {
        const value = Array.isArray(element.value) ? element.value[0] : element.value;

        if (value) {
          let processedValue;
          switch (element.dataType) {
            case 'date':
            case 'date_time':
              processedValue = dayjs(value as string);
              break;
            case 'multi_select_dropdown':
            case 'integer':
            case 'decimal':
              processedValue = detectJSON(value as string);
              break;
            default:
              processedValue = value;
          }
          acc[element.label] = processedValue;
        }

        return acc;
      }, {});

      setCustomFieldsValues(newFieldsValue);
    }
  }, [integratedCustomField]);

  useEffect(() => {
    if (selected) {
      initialValuesRef.current = {
        firstName: selected.firstName,
        lastName: selected.lastName,
        'businessInfo.company': selected.businessInfo?.company?.id,
        'businessInfo.companyType': selected.businessInfo?.company?.companyType?.name,
        'businessInfo.companyName': selected.businessInfo?.company?.name,
        'businessInfo.salesTeam': selected.businessInfo?.company?.salesTeam?.name,
        'businessInfo.jobTitle': selected.businessInfo?.jobTitle,
        'businessInfo.department': selected.businessInfo?.department,
        'businessInfo.manager': selected?.businessInfo?.manager,
        'businessInfo.assistant': selected?.businessInfo?.assistant,
        'businessInfo.context': selected?.businessInfo?.context,
        'businessInfo.referencedBy': selected?.businessInfo?.referencedBy,
        'businessInfo.isPrimary': selected.businessInfo?.isPrimary,
        'businessInfo.globallyVisible': selected.businessInfo?.globallyVisible,
        'businessInfo.enabledForcedSync': selected.businessInfo?.enabledForcedSync,
        // Add the do not email field
        'businessInfo.doNotEmail': selected.businessInfo?.doNotEmail,
        'businessInfo.addresses': mainAndAditionalElement('bussinessInfo.addresses', selected),
        'businessInfo.emails': mainAndAditionalElement('bussinessInfo.emails', selected),
        'businessInfo.phones': mainAndAditionalElement('bussinessInfo.phones', selected),

        // PERSONAL INFO
        'personalInfo.emails': list(emailToInputAdapter, selected.personalInfo?.emails),
        'personalInfo.phones': list(phoneToInputAdapter, selected.personalInfo?.phones),
        'personalInfo.addresses':
          list(addressToInputAdapter, selected.personalInfo?.addresses) ?? ([] as AddressModel[]),

        // WEB URLs
        website: selected.website ?? '',
        facebook: selected.facebook ?? '',
        twitter: selected.twitter ?? '',
        linkedin: selected.linkedin ?? '',
        instagram: selected.instagram ?? '',
        googlePlus: selected.googlePlus ?? '',

        // NOTES
        notes: selected.notes,

        /* attendance: selected.attendance, */
        contactGroup: selected.contactGroup,
        productInterest: selected.productInterest,
        ...customFieldsValues,
      };
      form.setFieldsValue(cloneDeep(initialValuesRef.current));
      localStorage.setItem('detail-contact-id', id as string);
      localStorage.setItem(ViewNames.detailContact, JSON.stringify(initialValuesRef.current));
    }
  }, [selected, customFieldsValues]);

  useEffect(() => {
    activeShowPrompt();
    activeSubmitData();
  }, [values]);

  const value = useMemo(
    () => ({
      contact: selected,
      form,
      submitData,
      createContactModalActive,
      activeCreateContact,
      desactiveCreateContact,
      handleSave,
      customFieldPayload,
      handleCustomFieldPayload,
      showSpinCustomFields,
      integratedCustomField,
      saveCompanyAsociatedId,
      activeExpandedEdition,
      handleActiveExpandedEdition,
      saveAddressToDelete,
      savePhonesToDelete,
      saveEmailsToDelete,
      processedSave,
    }),
    [
      selected,
      submitData,
      createContactModalActive,
      activeCreateContact,
      desactiveCreateContact,
      handleSave,
      customFieldPayload,
      handleCustomFieldPayload,
      showSpinCustomFields,
      integratedCustomField,
      saveCompanyAsociatedId,
      activeExpandedEdition,
      handleActiveExpandedEdition,
      saveAddressToDelete,
      savePhonesToDelete,
      saveEmailsToDelete,
      processedSave,
    ],
  );

  return <ContactContext.Provider value={value}>{children}</ContactContext.Provider>;
};

export const useContactContext = () => useContext(ContactContext);

export default ContactContextProvider;
