import { gql } from '@apollo/client';

const UPDATE_COMPANY_DIVISION = gql`
  mutation UpdateBuyGroup($updateBuyGroupInput: UpdateBuyGroupInput!) {
    UpdateBuyGroup(updateBuyGroupInput: $updateBuyGroupInput) {
      id
    }
  }
`;

export default UPDATE_COMPANY_DIVISION;
