import { SearchOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { useState } from 'react';
import ParentCompanyModal from 'src/components/commons/createCompany/internalComponents/parentCompanyModal';
import { buildOptions } from 'src/components/commons/createCompany/utils/functions';
import GenericForm, { FieldType } from 'src/components/commons/genericForm';
import { UpdateCompanyInput } from 'src/graphql/schema-types';
import useCompanyHeaders from 'src/hooks/useCompanyHeaders';
import { CompaniesElasticTypes2 } from 'src/types/companiesTypes';
import { useCompanyContext } from 'src/context/company.context';
import usePrivateTeamStore from 'src/hooks/usePrivateTeamStore';
import useSaleTeamsStore from 'src/hooks/useSaleTeamsStore';
import ParentCompanyInput from './parentCompanyInput';

const CompanyDetailsSection = () => {
  const headers = useCompanyHeaders({ fetchPolicy: 'network-only' });
  const privateTeamStore = usePrivateTeamStore();
  const salesTeamsStore = useSaleTeamsStore();
  const privateTeamsOptions = privateTeamStore.entities?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const salesTeamsOptions = salesTeamsStore.entities?.map((item) => ({
    label: item.name,
    value: item.id,
  }));
  const form = Form.useFormInstance();
  const [selectingParent, setSelectingParent] = useState(false);
  const { company, activeExpandedEdition } = useCompanyContext();
  const [parentName, setParentName] = useState<string>(company?.parent?.name || '');

  const handleSelectedParent = (parent: CompaniesElasticTypes2 | null) => {
    if (parent) {
      form.setFieldValue('parent', parent.id);
      setParentName(parent!.name!);
    } else {
      form.setFieldValue('parent', null);
      setParentName('');
    }
    setSelectingParent(false);
  };

  const fields: FieldType<UpdateCompanyInput>[] = [
    {
      label: 'Company Type',
      name: 'companyTypeId',
      type: 'select',
      readOnly: !activeExpandedEdition,
      span: 12,
      options: buildOptions(headers, 'companyType'),
      rules: [{ required: true, message: 'Company Type es required' }],
    },
    {
      label: 'Sales Team',
      name: 'salesTeam',
      type: 'select',
      readOnly: !activeExpandedEdition,
      span: 12,
      options: salesTeamsOptions,
      rules: [{ required: true, message: 'Sales Team es required' }],
    },
    {
      label: 'Private Team',
      name: 'privateTeamId',
      readOnly: !activeExpandedEdition,
      type: 'select',
      options: privateTeamsOptions,
      span: 12,
    },
    {
      label: 'Parent Company',
      name: 'parent',
      type: 'text',
      render: () => (
        <ParentCompanyInput name={parentName} onClear={() => handleSelectedParent(null)} />
      ),
      span: 12,
      suffixButton: <SearchOutlined />,
      onSuffixButtonClick: activeExpandedEdition ? () => setSelectingParent(true) : undefined,
      suffixType: 'square',
    },
    {
      label: 'Call Pattern',
      name: 'callPatternId',
      readOnly: !activeExpandedEdition,
      type: 'select',
      options: buildOptions(headers, 'callPattern'),
      span: 12,
    },
    {
      label: 'Category',
      name: 'categoryId',
      readOnly: !activeExpandedEdition,
      type: 'select',
      options: buildOptions(headers, 'category'),
      span: 12,
    },
    // {
    //   label: 'Company Division',
    //   name: 'divisionsId',
    //   type: 'multiSelect',
    //   options: buildOptions(headers, 'divisions'),
    //   span: 12,
    // },
    // {
    //   label: 'Tenant Division',
    //   name: 'tenantDivision',
    //   type: 'select',
    //   options: buildOptions(headers, 'tenantDivision'),
    //   span: 12,
    // },
    {
      label: 'Rank',
      name: 'rankId',
      readOnly: !activeExpandedEdition,
      type: 'select',
      options: buildOptions(headers, 'rank'),
      span: 8,
    },
    {
      label: 'Visit frequency (Days)',
      name: 'visitFrequency',
      readOnly: !activeExpandedEdition,
      type: 'number',
      span: 8,
    },
    {
      label: 'Print Name',
      name: 'printName',
      readOnly: !activeExpandedEdition,
      type: 'text',
      span: 8,
    },
    {
      label: 'Active',
      name: 'isActive',
      readOnly: !activeExpandedEdition,
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      span: 8,
    },
    {
      label: 'POS Account',
      name: 'hasPosAccount',
      readOnly: !activeExpandedEdition,
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      span: 8,
    },
    {
      label: 'Holiday Card',
      name: 'hasHolydayCard',
      readOnly: !activeExpandedEdition,
      type: 'radio',
      options: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      span: 8,
      // span: 12,
    },
    {
      label: 'Tags',
      name: 'tags',
      type: 'multiSelect',
      readOnly: !activeExpandedEdition,
      options: buildOptions(headers, 'tags'),
      span: 24,
    },
    {
      label: 'Delivery Instructions',
      name: 'shipDetail',
      readOnly: !activeExpandedEdition,
      type: 'textarea',
      span: 24,
    },
    // {
    //   label: 'Add logo',
    //   name: 'logo',
    //   type: 'file',
    //   span: 8,
    //   accept: 'image/*',
    // },
  ];

  return (
    <>
      <GenericForm name='formCompanyDetails' fields={fields} form={form} />
      {selectingParent && (
        <ParentCompanyModal show={selectingParent} onClose={handleSelectedParent} />
      )}
    </>
  );
};

export default CompanyDetailsSection;
