import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import type { ActionType } from '@rc-component/trigger/lib/interface';
import { Checkbox, Popover, Row, Col, Input, Empty } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { TooltipPlacement } from 'antd/es/tooltip';
import { Option } from '../commons/subTable/types';

type Props = {
  children?: ReactNode | undefined;
  options: Option[];
  optionsSelected?: string[];
  onCheckboxChange: (values: CheckboxValueType[]) => void;
  onOpenChange?: (visible: boolean) => void;
  trigger?: ActionType | ActionType[];
  placement?: TooltipPlacement;
};

const { Search } = Input;

const CheckboxMenu: React.FC<Props> = ({
  children,
  options,
  optionsSelected,
  onCheckboxChange,
  onOpenChange,
  trigger = 'click',
  placement = 'bottomLeft',
}) => {
  const [searchValue, setSearchValue] = useState('');

  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);
  useEffect(() => {
    if (optionsSelected && optionsSelected.length) {
      setSelectedItems([...optionsSelected]);
    }
  }, [optionsSelected]);

  const handleOnChangeCheckboxGroup = (selection: CheckboxValueType[]) => {
    const lastElement = selection.pop(); // get last element of array
    selection.unshift(lastElement ?? ''); // add last element to the beginning of array

    setSelectedItems([...selection]);
    onCheckboxChange([...selection]);
  };

  const handleOnChangeSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.value) {
      setSearchValue(event.currentTarget.value);
    } else {
      setSearchValue('');
    }
  };

  const handleOnOpenChange = (visible: boolean) => {
    onOpenChange?.(visible);
  };

  const searchRender = useMemo(
    () => (
      <Row key='search' style={{ marginBottom: 10 }}>
        <Search
          placeholder='Search'
          defaultValue={searchValue}
          onKeyUp={handleOnChangeSearch}
          style={{ width: 120 }}
        />
      </Row>
    ),
    [searchValue, handleOnChangeSearch],
  );

  const filterOptions = useMemo(
    () =>
      options.filter((option) => option.label?.toLowerCase().includes(searchValue?.toLowerCase())),
    [options, searchValue],
  );

  const checkboxRender = useMemo(
    () => (
      <Checkbox.Group onChange={handleOnChangeCheckboxGroup} value={selectedItems}>
        <Col>
          {searchRender}
          {filterOptions.map((option) => (
            <Row key={`checkbox-group-${option.value}`}>
              <Checkbox key={option.value} value={option.value}>
                {option.label}
              </Checkbox>
            </Row>
          ))}

          {filterOptions.length === 0 && (
            <Row key='empty-result' style={{ justifyContent: 'center' }}>
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Col>
      </Checkbox.Group>
    ),
    [filterOptions, searchRender, selectedItems, handleOnChangeCheckboxGroup],
  );

  return (
    <Popover
      content={checkboxRender}
      onOpenChange={handleOnOpenChange}
      trigger={trigger}
      placement={placement}
    >
      {children}
    </Popover>
  );
};

export default CheckboxMenu;
