import { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line camelcase
import { useBlocker } from 'react-router-dom';
import { confirmModal } from 'src/components/commons/customModal';

const useUnSaveChangesWarning = () => {
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [proccessSaving, setProccessSaving] = useState(false);
  const [confirmSaving, setConfirmSaving] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      showExitPrompt && currentLocation.pathname !== nextLocation.pathname,
  );

  const handleOkButton = () => {
    setShowExitPrompt(false);
    if (!proccessSaving) {
      if (blocker.proceed) {
        blocker.proceed();
      }
    } else {
      setConfirmSaving(true);
      setProccessSaving(false);
    }
  };

  const handleCancelButton = () => {
    setShowExitPrompt(false);
    setProccessSaving(false);
  };

  useEffect(() => {
    const handleTabClose = (event: any) => {
      if (!showExitPrompt) return '';
      event.preventDefault();
      // eslint-disable-next-line no-return-assign
      return (event.returnValue = '');
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, [showExitPrompt]);

  const confirmationModal = () =>
    confirmModal(
      proccessSaving
        ? 'Do you want to save these changes?'
        : 'There are unsaved changes. Are you sure you want to leave the page?',
      ' ',
      () => handleOkButton(),
      () => handleCancelButton(),
      'Yes',
      'No',
    );

  useEffect(() => {
    if (blocker.state === 'blocked' || proccessSaving) {
      confirmationModal();
    }
  }, [blocker, proccessSaving]);

  const variables = useMemo(
    () => ({
      showExitPrompt,
      proccessSaving,
      confirmSaving,
      setShowExitPrompt,
      setProccessSaving,
      setConfirmSaving,
    }),
    [
      showExitPrompt,
      proccessSaving,
      confirmSaving,
      setShowExitPrompt,
      setProccessSaving,
      setConfirmSaving,
    ],
  );

  return variables;
};

export default useUnSaveChangesWarning;
