import React from 'react';
// eslint-disable-next-line no-duplicate-imports
import { Space, Row, Col, Dropdown, MenuProps, DropdownProps, Button, Image } from 'antd';
import { PlusCircleOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import useClientinfo from 'src/hooks/useClientInfo';
import { StyledHeader, StyledAvatar } from './CustomHeader.styles';
import CustomExtendedMenu from '../extendedMenu';
import GlobalCreate from './GlobalCreate';

const CustomHeader = () => {
  const { logo } = useClientinfo();
  const [createMode, setCreateMode] = React.useState<
    'company' | 'contact' | 'activityJournal' | null
  >(null);
  const iconsMap = [
    {
      id: '1',
      icon: <PlusCircleOutlined />,
      testid: 'plus-circle-outlined',
      onClick: () => {},
      menu: {
        items: [
          {
            key: 'create-company',
            label: 'Create Company',
            onClick: () => setCreateMode('company'),
          },
          {
            key: 'create-contact',
            label: 'Create Contact',
            onClick: () => setCreateMode('contact'),
          },
          {
            key: 'create-activity-journal',
            label: 'Create Activity Journal',
            onClick: () => setCreateMode('activityJournal'),
          },
        ] as MenuProps['items'],
      },
    },
    // {
    //   id: '2',
    //   icon: <QuestionOutlined />,
    //   testid: 'question-outlined',
    // },
    {
      id: '3',
      icon: <SearchOutlined />,
      testid: 'search-outlined',
    },
    // {
    //   id: '4',
    //   icon: <BellOutlined />,
    //   testid: 'bell-outlined',
    // },
  ];
  // const createNewCompayModalElement = (
  //   <CustomModal
  //     modalStatus={createCompanyModalActive}
  //     onCancelAction={activeCreateCompany}
  //     onOkAction={() => 0}
  //     centered
  //     customClass='custom-modal'
  //     closable
  //     width={1400}
  //     modalTitle={<CreateCompanyHeaderRenderer percentage={progressCreationCompany} />}
  //     modalBody={<CreateCompany />}
  //     footer={<CreateCommpanyFooterRenderer />}
  //     style={{ height: '100%' }}
  //   />
  // );

  return (
    <>
      <GlobalCreate mode={createMode} onClose={() => setCreateMode(null)} />
      <StyledHeader id='custom-header' data-testid='custom-header-id'>
        <Row data-testid='custom-header-row-container-id'>
          <Col>
            <Image src={logo} width={45} height={45} preview={false} />
          </Col>
          <CustomExtendedMenu />
          <Col>
            <Space align='center' size='large' data-testid='custom-header-space-container-id'>
              {iconsMap.map((icon) => {
                const Container = icon.menu ? Dropdown : React.Fragment;

                const props = icon.menu
                  ? ({
                      menu: icon.menu,
                      placement: 'bottomRight',
                      trigger: ['click'],
                    } as DropdownProps)
                  : {};

                return (
                  <Container {...props}>
                    <Button
                      key={icon.id}
                      data-testid={icon.testid}
                      shape='circle'
                      icon={icon.icon}
                      ghost
                      style={{ border: 'none' }}
                    />
                  </Container>
                );
              })}
              <StyledAvatar data-testid='avatar-view' size={40} icon={<UserOutlined />} />
            </Space>
          </Col>
        </Row>
      </StyledHeader>
    </>
  );
};

export default CustomHeader;
