import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete } from 'antd';
import { useState } from 'react';
import StyledSearchInput from '../styles/searchInput.style';
import { useSubTableContext } from '../contexts/subtable.context';
import { Option } from '../types';
import { LOADING_DATA_OPTION, NO_DATA_OPTION } from '../consts';

const GlobalSearch = (globalSearchProps: any) => {
  const { placeholder } = globalSearchProps;

  const { setGlobalFilter, autocompleteGlobalProvider, onGlobalFilterChange } =
    useSubTableContext();
  const [options, setOptions] = useState<Option[]>([]);

  const handleSearch = async (value: string) => {
    setGlobalFilter(value);
    if (!value) {
      setOptions([]);
      return;
    }

    if (onGlobalFilterChange) {
      onGlobalFilterChange(value);

      if (autocompleteGlobalProvider) {
        try {
          setOptions(LOADING_DATA_OPTION);
          const results = await autocompleteGlobalProvider(value);
          if (results?.length) {
            setOptions(results);
          } else {
            setOptions(NO_DATA_OPTION);
          }
        } catch {
          setOptions(NO_DATA_OPTION);
        }
      }
    } else {
      setOptions(NO_DATA_OPTION);
    }
  };

  const handleSelect = (value: string) => {
    setGlobalFilter(value);
    if (onGlobalFilterChange) {
      onGlobalFilterChange(value);
    }
  };

  return (
    <AutoComplete
      data-testid='subtable-global-search'
      style={{ width: '100%' }}
      options={options}
      onSearch={handleSearch}
      onSelect={handleSelect}
    >
      <StyledSearchInput placeholder={placeholder || 'Search'} prefix={<SearchOutlined />} />
    </AutoComplete>
  );
};

export default GlobalSearch;
