const showFilterOptions = [
  { value: 'all', label: 'Show All' },
  { value: 'active', label: 'Show Active' },
  { value: 'inactive', label: 'Show Inactive' },
];

const rowsDisplayedOptions = [
  { value: 25, label: 'Rows 25' },
  { value: 50, label: 'Rows 50' },
  { value: 100, label: 'Rows 100' },
  { value: 200, label: 'Rows 200' },
  { value: 500, label: 'Rows 500' },
  { value: 1000, label: 'Rows 1000' },
];

const legacyFilter = [
  {
    id: 'callPattern.name',
    name: 'Call Pattern',
  },
  {
    id: 'mainAddress.region.name',
    name: 'Region',
  },
  {
    id: 'companyType.name',
    name: 'Company Type',
  },
  {
    id: 'category.name',
    name: 'Category',
  },
  {
    id: 'rank.name',
    name: 'Rank',
  },
  {
    id: 'salesTeam.name',
    name: 'Sales Team',
  },
];

const alphabet = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

const tempPhoneTypes = [
  {
    value: 'Home',
    label: 'Home',
    selected: true,
  },
  {
    value: 'Work',
    label: 'Work',
    selected: false,
  },
  {
    value: 'Mobile',
    label: 'Mobile',
    selected: false,
  },
  {
    value: 'Fax',
    label: 'Fax',
    selected: false,
  },
  {
    value: 'Other',
    label: 'Other',
    selected: false,
  },
];

const checkGroup = [
  {
    dependencyLabel: 'Customer',
    dependencyValue: 66,
    options: [
      { label: 'Split commission on Part Number', value: 'hasPartNumberSplit' },
      { label: 'Alias on primary and Secondary Customer combined', value: 'hasSecondaryAlias' },
      { label: 'Forecast Enabled', value: 'hasForecast' },
    ],
    salesTeamRequired: true,
    salesTeamBlocked: false,
    showAditionalFields: false,
  },
  {
    dependencyLabel: 'Distributor',
    dependencyValue: 3,
    options: [
      { label: 'Split commission on Part Number', value: 'hasPartNumberSplit' },
      { label: 'Alias on primary and Secondary Customer combined', value: 'hasSecondaryAlias' },
      { label: 'Forecast Enabled', value: 'hasForecast' },
    ],
    salesTeamRequired: false,
    salesTeamBlocked: false,
    showAditionalFields: false,
  },
  {
    dependencyLabel: 'Mfg./Product Line',
    dependencyValue: 2,
    salesTeamRequired: false,
    salesTeamBlocked: true,
    showAditionalFields: true,
  },
  {
    dependencyLabel: 'External',
    dependencyValue: 44,
    options: [
      { label: 'Alias on primary and Secondary Customer combined', value: 'hasSecondaryAlias' },
    ],
    salesTeamRequired: false,
    salesTeamBlocked: true,
    showAditionalFields: false,
  },
];

const localGridPreferenceData = [
  {
    id: '00ebb0f1cec696ccd8b45bce',
    gridName: 'Default',
    gridOptions: {
      orderedFields: [
        'CALL_PATTERN_ID',
        'CLASS.LABEL',
        'COMMENTS',
        'ADDRESSES.ADDRESS',
        'CATEGORY.LABEL',
        'ADDRESSES.CITY',
        'PHONES.PHONE',
        'ADDRESSES.PO_BOX',
        'ADDRESSES.REGION',
        'SALES_TEAM.LABEL',
        'ADDRESSES.STATE',
        'TYPE.LABEL',
        'CREATED_BY.NAME',
        'CREATED_DATE',
        'DATA_SOURCE',
        'HAS_HOLYDAY',
        'ID',
        'IMP_BATCH',
        'NAME',
        'PARENT_ID',
        'PARENT_NAME',
        'PRINT_NAME',
        'REF_NO',
        'SHORT_NAME',
        'UPDATED_BY.NAME',
        'UPDATED_DATE',
        'VISIT_FREQUENCY',
        'WEB_SITE',
      ],
      selectedColumns: [
        'NAME',
        'TYPE.LABEL',
        'PHONES.PHONE',
        'SALES_TEAM.LABEL',
        'ADDRESSES.CITY',
        'ADDRESSES.STATE',
        'ADDRESSES.PO_BOX',
        'ADDRESSES.REGION',
        'WEB_SITE',
        'CATEGORY.LABEL',
      ],
      pageSize: 25,
    },
    enabled: true,
    savedName: 'Default',
    isDefault: true,
  },
];

const localGridPreferenceData2 = [
  {
    gridName: 'contacts',
    id: 0,
    isDefault: false,
    name: 'Default',
    options:
      '{"order": ["name", "firstname", "lastname", "businessInfo.mainPhone.phone", "businessInfo.company.type.name", "businessInfo.company.salesTeam.name", "businessInfo.mainEmail.email", "businessInfo.mainAddress.state.name", "groups.name", "businessInfo.mainAddress.city", "businessInfo.mainAddress.state.name", "businessInfo.mainAddress.zipCode", "businessInfo.mainAddress.address", "title", "businessInfo.company.name"], "selected": ["name", "firstname", "lastname", "businessInfo.mainPhone.phone", "businessInfo.company.type.name", "businessInfo.company.salesTeam.name", "businessInfo.mainEmail.email", "businessInfo.mainAddress.state.name", "groups.name", "businessInfo.mainAddress.city", "businessInfo.mainAddress.state.name", "businessInfo.mainAddress.zipCode", "businessInfo.mainAddress.address", "title", "businessInfo.company.name"]}',
  },
];

export {
  showFilterOptions,
  rowsDisplayedOptions,
  alphabet,
  localGridPreferenceData,
  checkGroup,
  tempPhoneTypes,
  localGridPreferenceData2,
  legacyFilter,
};
