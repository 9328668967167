import { SubTableColumn } from 'src/components/commons/subTable/types';

const COLUMNS: SubTableColumn[] = [
  {
    key: '',
    title: 'PRICE CODE',
    dataIndex: 'code',
    editable: true,
    minWidth: 270,
    dataType: 'string',
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'Price Code',
    options: [],
    orderField: 'code',
    searchField: 'code',
  },
  {
    key: '',
    title: 'DESCRIPTION',
    dataIndex: 'description',
    editable: true,
    minWidth: 270,
    dataType: 'number',
    required: true,
    hidden: false,
    dataStructure: 'string',
    headerName: 'Description',
    options: [],
    orderField: 'description',
    searchField: 'description',
  },
];

export default COLUMNS;
