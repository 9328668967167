/* eslint-disable no-duplicate-imports */
import React, { useState } from 'react';
import { SalesTeamModel, Maybe } from 'src/graphql/schema-types';
import type { RadioChangeEvent } from 'antd';
import { CustomModal } from 'src/components/commons/customModal';
import CustomButton from 'src/components/commons/customButton';
import CustomSelect from 'src/components/commons/customSelect';
import { Radio, Space, Typography, Form, Checkbox, notification } from 'antd';
import { CHANGE_SALES_TEAM } from 'src/components/company/mutations';
import { useMutation } from '@apollo/client';

type Props = {
  handleOpenedChangeSalesTeam: () => void;
  show: boolean;
  actualSalesTeam?: Maybe<SalesTeamModel>;
  salesTeamList: { id: string; name: string }[];
};

const { Text } = Typography;

const ChangeSalesTeam: React.FC<Props> = ({
  show,
  handleOpenedChangeSalesTeam,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actualSalesTeam,
  salesTeamList,
}) => {
  const [selectedRadio, setSelectedRadio] = useState();
  const [selectedCheckBox, setSelectedCheckBox] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropdownOption, setSelectedDropdownOption] = useState<{
    id: string;
    name: string;
  }>();

  const handleRadioSelection = (value: RadioChangeEvent) => {
    setSelectedRadio(value.target.value);
  };

  const handleCheckBoxSelection = () => {
    setSelectedCheckBox(!selectedCheckBox);
  };

  const [changeSalesTeam] = useMutation(CHANGE_SALES_TEAM, {
    context: { clientName: 'elastic' },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      notification.success({
        message: 'The process started',
        key: 'change-sales-team-started',
      });
    },
    onError: (error) => {
      notification.success({
        message: error.message,
        key: 'change-sales-team-error',
      });
    },
  });

  const handleSelectedDropdownOption = (e: React.FormEvent<HTMLInputElement>) => {
    const innerValue = e as unknown as string;
    const innerSelectedOption = salesTeamList.find((element) => element.id === innerValue);
    setSelectedDropdownOption(innerSelectedOption);
  };

  const handleChangeSalesTeam = () => {
    const changeSalesTeamInput = {
      idSalesTeamFrom: 0,
      idSalesTeamTo: Number(selectedDropdownOption?.id),
      option: selectedRadio,
      poExceptions: !!selectedCheckBox,
    };
    changeSalesTeam({
      variables: {
        changeSalesTeamInput,
      },
    });
    handleOpenedChangeSalesTeam();
  };

  return (
    <CustomModal
      modalStatus={show}
      onCancelAction={handleOpenedChangeSalesTeam}
      onOkAction={() => {}}
      modalTitle='Change Sales Team'
      modalBody={
        <div>
          <Space direction='vertical'>
            <Radio.Group onChange={handleRadioSelection} value={selectedRadio}>
              <Radio value='pending' style={{ marginBottom: '10px' }}>
                Change CRM - Oportunities, Activity Journal and Purchase Orders.
              </Radio>
              <Radio value='historical' style={{ marginBottom: '10px' }}>
                Change both CRM and historical sales and commission entries.
              </Radio>
              <Radio value='future' style={{ marginBottom: '10px' }}>
                Effective only for future transaction.
              </Radio>
            </Radio.Group>
          </Space>
          <Text>
            <Text strong style={{ paddingRight: '25px' }}>
              From:
            </Text>{' '}
            Team 1
          </Text>
          <Form style={{ marginTop: '15px' }}>
            <Form.Item required label='To'>
              <CustomSelect
                style={{ paddingLeft: '25px' }}
                defaultValue={salesTeamList[0]?.id || ''}
                options={salesTeamList.map((element) => ({
                  value: element.id,
                  label: element.name,
                }))}
                handleChange={handleSelectedDropdownOption}
              />
            </Form.Item>
          </Form>
          <Checkbox onChange={handleCheckBoxSelection}>Push pending PO to PO Exceptions.</Checkbox>
        </div>
      }
      footer={
        <Space>
          <CustomButton
            type='text'
            title='No'
            onClick={handleOpenedChangeSalesTeam}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
          <CustomButton
            type='primary'
            title='Yes'
            disabled={!selectedRadio || !selectedDropdownOption}
            onClick={handleChangeSalesTeam}
            style={{
              fontSize: '14px',
              height: '32px',
              padding: '4px 15px',
            }}
          />
        </Space>
      }
    />
  );
};

export default ChangeSalesTeam;
