export type ConfiguratorModules = {
  [key: string | number]: {
    label: string;
    route: string;
    order: number;
  };
};

// Relabeling
export const ConfigModuleList: ConfiguratorModules = {
  COMPANIES_DROPDOWN_FIELD: {
    label: 'Companies',
    route: '/settings/subtables/companies/type',
    order: 1,
  },
  CONTACTS_DROPDOWN_FIELD: {
    label: 'Contacts',
    route: '/settings/subtables/contacts/contact-group',
    order: 2,
  },
  ACTIVITY_JOURNALS_DROPDOWN_FIELD: {
    label: 'Activity Journals',
    route: '/settings/subtables/activity-journals/canned-notes',
    order: 3,
  },
  OPPORTUNITIES_DROPDOWN_FIELD: {
    label: 'Opportunities',
    route: '/settings/subtables/opportunities/type',
    order: 4,
  },
  JOBS_DROPDOWN_FIELD: {
    label: 'Jobs',
    route: '/settings/subtables/jobs/type',
    order: 5,
  },
  PRODUCTS_DROPDOWN_FIELD: {
    label: 'Products Master',
    route: '/settings/subtables/products/product-family',
    order: 6,
  },
  GOALS_DROPDOWN_FIELD: {
    label: 'Goals',
    route: '/settings/subtables/goals/type',
    order: 7,
  },
  COMMISSION_DROPDOWN_FIELD: {
    label: 'Commission',
    route: '/settings/subtables/commission/type',
    order: 8,
  },
  EXPENSES_DROPDOWN_FIELD: {
    label: 'Expenses',
    route: '/settings/subtables/expenses/type',
    order: 9,
  },
  PO_DROPDOWN_FIELD: {
    label: 'PO',
    route: '/po',
    order: 10,
  },
  DIVISIONS_DROPDOWN_FIELD: {
    label: 'Divisions',
    route: '/settings/subtables/divisions/type',
    order: 11,
  },
  OTHERS_DROPDOWN_FIELD: {
    label: 'Others',
    route: '/settings/subtables/others/type',
    order: 12,
  },
};

// TODO Only the uncomment options we use in MVP
const ConfigDropDownList = [
  'COMPANIES_DROPDOWN_FIELD',
  'CONTACTS_DROPDOWN_FIELD',
  'ACTIVITY_JOURNALS_DROPDOWN_FIELD',
  // 'OPPORTUNITIES_DROPDOWN_FIELD',
  // 'JOBS_DROPDOWN_FIELD',
  'PRODUCTS_DROPDOWN_FIELD',
  // 'GOALS_DROPDOWN_FIELD',
  // 'COMMISSION_DROPDOWN_FIELD',
  // 'EXPENSES_DROPDOWN_FIELD',
  // 'PO_DROPDOWN_FIELD',
  // 'DIVISIONS_DROPDOWN_FIELD',
  // 'OTHERS_DROPDOWN_FIELD',
];

export const MODULE_OPTIONS_CONFIGURATOR = ConfigDropDownList.map((module) => ({
  label: ConfigModuleList[module].label,
  value: ConfigModuleList[module].route,
  order: ConfigModuleList[module].order,
})).sort((a, b) => a.order - b.order);

export const SUB_TABLE_TABS_COMPANIES = [
  { label: 'Type', to: 'subtables/companies/type' },
  { label: 'Call Pattern', to: 'subtables/companies/call-pattern' },
  { label: 'Category', to: 'subtables/companies/category' },
  { label: 'Rank', to: 'subtables/companies/rank' },
  { label: 'Region', to: 'subtables/companies/region' },
  { label: 'Product Potential', to: 'subtables/companies/product-potential' },
  { label: 'Industry', to: 'subtables/companies/industry' },
  { label: 'Buying Group', to: 'subtables/companies/buying-group' },
  { label: 'Tier', to: 'subtables/companies/tier' },
  { label: 'Territory', to: 'subtables/companies/territory' },
  // { label: 'Division', to: 'subtables/companies/division' },
  // { label: 'Zip Code Masterlist', to: 'subtables/companies/zip-code-masterlist' },
  { label: 'Address Type', to: 'subtables/companies/address-type' },
  { label: 'Country', to: 'subtables/companies/country' },
  { label: 'Phone Type', to: 'subtables/companies/phone-type' },
  { label: 'Email Type', to: 'subtables/companies/email-type' },
];

export const SUB_TABLE_TABS_CONTACTS = [
  { label: 'Contact Group', to: 'subtables/contacts/contact-group' },
  { label: 'Product Interest', to: 'subtables/contacts/product-interest' },
];

export const SUB_TABLE_TABS_ACTIVITY_JOURNALS = [
  { label: 'Canned Notes', to: 'subtables/activity-journals/canned-notes' },
  { label: 'Call Type', to: 'subtables/activity-journals/call-type' },
  { label: 'Mfg / Product lines list', to: 'subtables/activity-journals/product-lines' },
];

export const SUB_TABLE_TABS_DIVISIONS = [
  { label: 'Type Divisions', to: 'subtables/divisions/type' },
];

export const SUB_TABLE_TABS_OPPORTUNITIES = [
  { label: 'Type Contact', to: 'subtables/opportunities/type' },
];

export const SUB_TABLE_TABS_PRODUCTS = [
  { label: 'Product Family', to: 'subtables/products/product-family' },
  { label: 'Unit of Measurements', to: 'subtables/products/uom' },
  { label: 'Price Codes', to: 'subtables/products/price-codes' },
];
