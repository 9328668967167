import { Form } from 'antd';
import { ComponentType, FC } from 'react';
import EditableProvider from '../contexts/editable.context';

type EditableRowProps = {
  index: number;
};

const getEditableRowComponent = (TrComponent: ComponentType<unknown> | string) => {
  const EditableRow: FC<EditableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();

    return (
      <Form form={form} component={false}>
        <EditableProvider form={form}>
          <TrComponent {...props} />
        </EditableProvider>
      </Form>
    );
  };

  return EditableRow;
};

export default getEditableRowComponent;
