import { Route, Routes } from 'react-router';
import NotFoundPage from 'src/components/commons/notFound';
import { PageSubTableProducts } from '../../pages';
import TabProductFamily from './tabs/productFamily/TabProductFamilyTable';
import TabUnitOfMeasurements from './tabs/unitOfMeasurements/TabUnitOfMeasurementsTable';
import TabPriceCodes from './tabs/priceCodes/TabPriceCodesTable';

const SubTableProductsRoutes = () => (
  <Routes>
    <Route element={<PageSubTableProducts />}>
      <Route index path='product-family' element={<TabProductFamily />} />
      <Route index path='uom' element={<TabUnitOfMeasurements />} />
      <Route index path='price-codes' element={<TabPriceCodes />} />
      <Route path='*' element={<NotFoundPage />} />
    </Route>
  </Routes>
);

export default SubTableProductsRoutes;
