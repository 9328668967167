import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

const StyledPhoneInput = styled(PhoneInput)`
  .form-control {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    position: relative;
    display: inline-block;
    width: calc(100% - 40px) !important;
    height: 32px !important;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    margin-left: 40px;

    &:hover {
      border-color: #4096ff;
      border-inline-end-width: 1px;
    }

    &:focus,
    :where(.css-dev-only-do-not-override-1m62vyb).ant-input-focused {
      border-color: #4096ff;
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      border-inline-end-width: 1px;
      outline: 0;
    }
    &:disabled {
      background-color: rgba(0, 0, 0, 0.04);
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      box-shadow: none;
      cursor: not-allowed;
      opacity: 1;
    }
  }
`;

export default StyledPhoneInput;
