import { FC } from 'react';
import { useLocation } from 'react-router';
import { SubTableColumn, SubTableGridProps } from './types';
import SubTableProvider from './contexts/subtable.context';
// eslint-disable-next-line import/no-cycle
import SubTableHeader from './components/header';
import StyledSubTable from './styles/subTable.style';
import AdvancedFilterWithSubTableGrid from './components/AdvancedFilterWithSubTableGrid';

type Props = Pick<
  SubTableGridProps,
  | 'columns'
  | 'setColumns'
  | 'columnsToSelectOption'
  | 'columnsHeaderSelected'
  | 'showOptionFilterLeft'
  | 'leftFilters'
  | 'leftFiltersOptions'
  | 'selectedLeftFilter'
  | 'setSelectedLeftFilter'
  | 'showHeaderSelect'
  | 'data'
  | 'scroll'
  | 'nonFilterable'
  | 'nonAddable'
  | 'nonDeletable'
  | 'nonEditable'
  | 'nonSelectable'
  | 'showAutoIncrement'
  | 'allowGlobalEditActive'
  | 'activeOnSelectRowsDisableEdit'
  | 'editBySelected'
  | 'onRowClick'
  | 'onListRender'
  | 'onFilterChange'
  | 'onGlobalFilterChange'
  | 'onSortChange'
  | 'handleUpdateRows'
  | 'autocompleteFieldProvider'
  | 'autocompleteGlobalProvider'
  | 'columnFilterShown'
  | 'nonGlobalFilterable'
  | 'pagination'
  | 'total'
  | 'onPaginationChange'
  | 'page'
  | 'pageSize'
  | 'loading'
  | 'actionCell'
  | 'deleteIcon'
  | 'favIcon'
  | 'editIcon'
  | 'detailsIcon'
  | 'onEditClick'
  | 'onDeleteClick'
  | 'onDetailsClick'
  | 'headerActions'
  | 'id'
  | 'postData'
  | 'newRecord'
  | 'resetNewRecord'
  | 'saveNewRecord'
  | 'dataToCompare'
  | 'clearFilter'
  | 'storageKey'
  | 'storageType'
  | 'selectedRows'
  | 'contentOnDelete'
  | 'titleOnDelete'
  | 'detailsTable'
  | 'onFavClick'
  | 'keyExpandableRow'
  | 'addNewButtonDetailsTable'
  | 'isSubTableExpandable'
  | 'dataForListInHeader'
  | 'filterByHeaderListServer'
  | 'filterByHeaderListClient'
  | 'BodyModal'
  | 'addWithModal'
  | 'onAddClick'
  | 'titleModalNew'
  | 'titleModalEdit'
  | 'expandable'
  | 'notEditWithModal'
  | 'clickedRecord'
  | 'headerSelectDefaultValue'
  | 'isModalCreateEditOpen'
  | 'setIsModalCreateEditOpen'
  | 'onSelectRecord'
  | 'searchPlaceholder'
  | 'clickable'
  | 'handleCollapseToggle'
>;

const SubTable: FC<Props> = (props) => {
  const {
    nonFilterable,
    nonAddable,
    nonDeletable,
    nonEditable,
    nonSelectable,
    nonGlobalFilterable,
    columnFilterShown,
    headerActions,
    pagination,
    total,
    autocompleteFieldProvider,
    autocompleteGlobalProvider,
    onFilterChange,
    onGlobalFilterChange,
    onSortChange,
    setColumns,
    columns,
    showOptionFilterLeft,
    allowGlobalEditActive,
    activeOnSelectRowsDisableEdit,
    editBySelected,
    clearFilter,
    showHeaderSelect,
    dataForListInHeader,
    filterByHeaderListServer,
    filterByHeaderListClient,
    addWithModal,
    onAddClick,
    clickedRecord,
    headerSelectDefaultValue,
    isModalCreateEditOpen,
    setIsModalCreateEditOpen,
    onSelectRecord,
    searchPlaceholder,
    clickable,
    handleCollapseToggle,
  } = props;

  const location = useLocation();
  const hideSubtableBorders =
    location.pathname.includes('subtables') || location.pathname.includes('settings');

  const newColumns = columns.map((column: SubTableColumn) => {
    if (hideSubtableBorders) {
      delete column.width;
    }
    return column;
  });

  return (
    <SubTableProvider
      nonFilterable={nonFilterable}
      nonAddable={nonAddable}
      nonDeletable={nonDeletable}
      nonEditable={nonEditable}
      nonSelectable={nonSelectable}
      columnFilterShown={columnFilterShown}
      nonGlobalFilterable={nonGlobalFilterable}
      autocompleteFieldProvider={autocompleteFieldProvider}
      autocompleteGlobalProvider={autocompleteGlobalProvider}
      onFilterChange={onFilterChange}
      onGlobalFilterChange={onGlobalFilterChange}
      onSortChange={onSortChange}
      headerActions={headerActions}
      pagination={pagination}
      clickedRecord={clickedRecord}
      isModalCreateEditOpen={isModalCreateEditOpen}
      setIsModalCreateEditOpen={setIsModalCreateEditOpen}
      onSelectRecord={onSelectRecord}
      {...props}
    >
      <StyledSubTable $hideBorders={hideSubtableBorders} $clickable={clickable}>
        <SubTableHeader
          total={total}
          setColumns={setColumns}
          columns={newColumns}
          showOptionFilterLeft={showOptionFilterLeft}
          allowGlobalEditActive={allowGlobalEditActive}
          editBySelected={editBySelected}
          activeOnSelectRowsDisableEdit={activeOnSelectRowsDisableEdit}
          showHeaderSelect={showHeaderSelect}
          dataForListInHeader={dataForListInHeader}
          clearList={clearFilter}
          filterByHeaderListServer={filterByHeaderListServer}
          filterByHeaderListClient={filterByHeaderListClient}
          addWithModal={addWithModal}
          onAddClick={onAddClick}
          headerSelectDefaultValue={headerSelectDefaultValue}
          searchPlaceholder={searchPlaceholder}
          handleCollapseToggle={handleCollapseToggle}
        />
        <AdvancedFilterWithSubTableGrid {...props} />
      </StyledSubTable>
    </SubTableProvider>
  );
};

export default SubTable;
