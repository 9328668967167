import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SubTableColumn } from 'src/components/commons/subTable/types';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import handleError from 'src/utils/functions/handleError';
import { PriceCodeModel } from 'src/graphql/schema-types';
import { get } from 'lodash';
import COLUMNS from '../constants';
import { GET_ALL_PRICE_CODES, GET_HEADERS_PRICE_CODES } from '../graphql/queries';
import { GetAllPriceCode, GetAllPriceCodeResponse, GetPriceCodesHeaders } from '../types';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
import { CREATE_PRICE_CODES, REMOVE_PRICE_CODES, UPDATE_PRICE_CODES } from '../graphql/mutations';
import useFilterColumn from '../../../../company/tabs/callPattern/hooks/useFilterColumn';

const usePriceCodesTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [loadPriceCodes, queryResponse] = useLazyQuery<GetAllPriceCode>(GET_ALL_PRICE_CODES, {
    fetchPolicy: 'network-only',
  });
  const [loadHeadersCustom] = useLazyQuery<GetPriceCodesHeaders>(GET_HEADERS_PRICE_CODES, {
    fetchPolicy: 'network-only',
  });

  const [update] = useMutation(UPDATE_PRICE_CODES);
  const [create] = useMutation(CREATE_PRICE_CODES);
  const [remove] = useMutation(REMOVE_PRICE_CODES);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    manufacturer: '',
    code: '',
    description: '',
  });

  const loadData: FetchFunc = ({ start, renderLen }) =>
    loadPriceCodes({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllPriceCodeFromElastic2?.results || [],
      total: res.data?.GetAllPriceCodeFromElastic2?.total || 0,
    }));
  const loadHeader = () => {
    loadHeadersCustom().then((res) => {
      const filteredResult = filterIDAndAddingTitle(res.data?.GetPriceCodeGridHeaders ?? [])
        .filter((el) => el.gridVisible)
        .map((el) => {
          if (el.options) {
            el.options = el.options.map((option: any) => ({
              VALUE: option?.id,
              LABEL: option?.name,
            }));
          }
          return el;
        });
      setColumns(filteredResult ?? COLUMNS);
    });
  };
  useEffect(() => {
    loadHeader();
  }, []);

  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(loadData);

  useEffect(() => {
    reset();
    setColumnFilters([]);
  }, []);
  const getAllPrincipals = columns.find((col) => col?.dataIndex === 'manufacturer')?.options;

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(
      searchField.slice(0, -1),
      search,
      (dataType as string).toLocaleLowerCase() === 'dropdown' ? 'string' : dataType,
    );
    return loadPriceCodes({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
      },
    }).then((res) => {
      if (res.data?.GetAllPriceCodeFromElastic2) {
        let modifiedSearch = searchField;
        if (searchField.includes('_')) {
          modifiedSearch = searchField.replace('_', '.');
        }
        return {
          results: res.data.GetAllPriceCodeFromElastic2.results,
          filter: res.data.GetAllPriceCodeFromElastic2.results.map(
            (result: GetAllPriceCodeResponse) => ({
              value: get(result, modifiedSearch.slice(0, -1)),
              label: get(result, modifiedSearch.slice(0, -1)),
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };
  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const resetNewRecord = () => {
    setNewRecord({
      manufacturer: '',
      code: '',
      description: '',
    });
  };

  const postNewRecord = async () => {
    if (newRecord.manufacturer === '') return;
    const findPrincipal = getAllPrincipals?.find((el) => el.VALUE === newRecord.manufacturer);
    await create({
      variables: {
        createPriceCodeInput: {
          manufacturer: findPrincipal?.VALUE,
          code: newRecord.code,
          description: newRecord.description,
        },
      },
    }).then((customType) => {
      setDataSource([
        {
          id: customType.data.CreatePriceCode.id,
          description: customType.data.CreatePriceCode.description,
          code: customType.data.CreatePriceCode.code,
          manufacturer: {
            id: findPrincipal?.VALUE,
            name: findPrincipal?.LABEL,
          },
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: (PriceCodeModel & { selectedId?: number })[]) => {
    rows.forEach((row) => {
      const { id, selectedId, manufacturer, code, description } = row;
      update({
        variables: {
          updatePriceCodeInput: {
            id,
            manufacturer: selectedId || manufacturer?.id,
            code,
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: PriceCodeModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    await Promise.allSettled(
      rows.map((row) =>
        remove({ variables: { id: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            } else if (rows.length === 1) {
              setDataSource((prev) => prev.filter((data) => row.id !== data.id));
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    ).then((res) => {
      if (res.some((response) => response.status === 'rejected')) return;
      if (rows.length === 1) return;
      const ids = rows.map((r) => r.id);
      const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
      setDataSource(copiedDataSource);
    });
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  return {
    dataSource,
    columns,
    queryResponse,
    newRecord,
    autocompleteFieldSearch,
    handleFilterChange,
    reset,
    setColumns,
    onListRender,
    postNewRecord,
    resetNewRecord,
    handleNewRecord,
    handleUpdateRows,
    handleDeleteRows,
    predefinedData: getAllPrincipals?.map((manufacturer) => ({
      id: manufacturer.VALUE,
      name: manufacturer.LABEL,
    })) as [],
  };
};

export default usePriceCodesTable;
