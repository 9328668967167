import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Option, SortModel } from 'src/components/commons/subTable/types';
import { CompanyTierModel } from 'src/graphql/schema-types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import handleError from 'src/utils/functions/handleError';
import COLUMNS from '../constants';
import {
  CREATE_TIER_COMPANY,
  DELETE_TIER_COMPANY,
  UPDATE_TIER_COMPANY,
} from '../graphql/mutations';
import GET_ALL_TIER_COMPANIES from '../graphql/queries';
import { CompanyTier, GetAllCompanyTier } from '../types';
import useFilterColumn from '../../callPattern/hooks/useFilterColumn';

const useTierTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [getAll, queryResponse] = useLazyQuery<GetAllCompanyTier>(GET_ALL_TIER_COMPANIES, {
    fetchPolicy: 'network-only',
  });

  const [loadDataFilter] = useLazyQuery<GetAllCompanyTier>(GET_ALL_TIER_COMPANIES, {
    fetchPolicy: 'network-only',
  });

  const [update] = useMutation(UPDATE_TIER_COMPANY);
  const [create] = useMutation(CREATE_TIER_COMPANY);
  const [remove] = useMutation(DELETE_TIER_COMPANY);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
    description: '',
  });

  const fetchData: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllCompanyTier2.results || [],
      total: res.data?.GetAllCompanyTier2.total || 0,
    }));

  const { dataSource, onListRender, setDataSource, reset } = useInfinityDataSource(fetchData);

  useEffect(() => {
    reset();
    setColumnFilters([]);
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return loadDataFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },
          filter: filters,
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res?.data?.GetAllCompanyTier2.results as any[],
      filter: res?.data?.GetAllCompanyTier2.results?.map((result: CompanyTier) => ({
        value: result[searchField],
        label: result[searchField],
      })) as Option[],
    }));
  };

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      description: '',
    });
  };

  const handleClearFilter = () => {
    reset();
  };

  const createNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createCompanyTierInput: {
          name: capitalizeFirstWord(newRecord.name),
          description: newRecord.description,
        },
      },
    }).then((customType) => {
      setDataSource([
        {
          id: customType.data.CreateCompanyTier.id,
          name: customType.data.CreateCompanyTier.name,
          description: customType.data.CreateCompanyTier.description,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: any[]) => {
    rows.forEach((row) => {
      const { id, name, description } = row;
      update({
        variables: {
          updateCompanyTierInput: {
            id,
            name: capitalizeFirstWord(name),
            description,
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: CompanyTierModel[]) => {
    const messages = {
      400: {
        title: 'This record cannot be deleted',
        message: 'This value is linked to the company. You can not delete it.',
      },
    };
    Promise.allSettled(
      rows.map((row) =>
        remove({ variables: { id: row.id } })
          .then(({ errors }) => {
            if (errors) {
              handleError(errors[0], messages);
            } else if (rows.length === 1) {
              setDataSource((prev) => prev.filter((data) => row.id !== data.id));
            }
          })
          .catch((err) => {
            handleError(err, messages);
            throw err;
          }),
      ),
    ).then(() => {
      if (rows.length === 1) return;
      const ids = rows.map((r) => r.id);
      const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
      setDataSource(copiedDataSource);
    });
  };

  const postData = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    handleUpdateRows,
    handleDeleteRows,
    newRecord,
    postData,
    createNewRecord,
    resetNewRecord,
    dataSource,
    onListRender,
    queryResponse,
    handleClearFilter,
  };
};

export default useTierTable;
