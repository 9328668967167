/* eslint-disable no-nested-ternary */
import { useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Avatar, Card } from 'antd';
import { useNavigate, useLocation } from 'react-router';
import CustomButton from 'src/components/commons/customButton';
import CustomSelect from 'src/components/commons/customSelect';
import StyledCompanyHeader from 'src/components/companyDetails/styles/companyHeader.styles';
import FlexCol from 'src/components/companyDetails/styles/flexCol.style';
import useUnSaveChangesWarning from 'src/hooks/useUnSaveChangesWarning';
import { useContactContext } from 'src/context/contact.context';
import logo from 'src/images/logo.png';
import NewContact from 'src/modules/contacts/components/newContact';
import copyToClipboard from 'src/utils/functions/copyClipboard';
import { concatContactInfo } from '../utils/concatContactInfo';

const ContactSetupHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    contact,
    submitData,
    createContactModalActive,
    activeCreateContact,
    desactiveCreateContact,
    form,
    activeExpandedEdition,
    handleActiveExpandedEdition,
    handleSave,
    processedSave,
  } = useContactContext();

  const { setProccessSaving, setConfirmSaving, confirmSaving } = useUnSaveChangesWarning();

  const handleVCF = (e: React.FormEvent<HTMLInputElement>) => {
    if ((e as unknown as string) === 'copyText') {
      copyToClipboard(concatContactInfo(contact!));
    }
  };

  useEffect(() => {
    if (confirmSaving) {
      handleSave();
    }
  }, [confirmSaving]);

  useEffect(() => {
    if (processedSave) {
      setConfirmSaving(false);
    }
  }, [processedSave]);

  const hideEditButton = location.pathname.includes('basic');
  const contactName =
    contact?.firstName && contact?.lastName
      ? `${contact?.firstName?.replace(/[^\w\s]/gi, '')} ${contact?.lastName?.replace(
          /[^\w\s]/gi,
          '',
        )}`
      : contact?.firstName
      ? `${contact?.firstName?.replace(/[^\w\s]/gi, '')}`
      : undefined;
  const avatarFirstLetter = contactName?.substring(0, 1);
  const contactCity =
    contact?.businessInfo?.mainAddress?.city && ` - ${contact?.businessInfo?.mainAddress?.city}`;

  return (
    <>
      {createContactModalActive && <NewContact form={form} onClose={desactiveCreateContact} />}
      <StyledCompanyHeader bigAvatar>
        <Card className='card-container'>
          <FlexCol className='flex-col-container'>
            <FlexCol span={9} className='avatar-company-container'>
              <CustomButton
                onClick={() => navigate('/contacts')}
                shape='circle'
                icon={<ArrowLeftOutlined />}
                onlyIcon
              />
              <Avatar className='avatar-container' size={64}>
                {avatarFirstLetter}
              </Avatar>
              <div className='contact-info-container'>
                <h2 style={{ color: '#4B4B4B' }}>{contactName || ''}</h2>
                <h3>{contact?.businessInfo?.jobTitle}</h3>
                <p style={{ whiteSpace: 'nowrap' }}>
                  {contact?.businessInfo?.company?.name}
                  {contactCity}
                </p>
              </div>
            </FlexCol>
            <FlexCol span={15} className='inputs-buttons-container'>
              {/* <Input prefix={<SearchOutlined />} placeholder='Search' className='input-style' /> */}
              {hideEditButton && (
                <>
                  <CustomButton
                    style={{ marginLeft: 'auto' }}
                    title='Save'
                    type='primary'
                    disabled={!submitData || !activeExpandedEdition}
                    onClick={() => setProccessSaving(true)}
                  />
                  <CustomButton
                    title={activeExpandedEdition ? 'Cancel' : 'Edit'}
                    noborder={false}
                    style={{ width: '80px' }}
                    dataTestId='btn-edit-company'
                    onClick={handleActiveExpandedEdition}
                  />
                </>
              )}

              <CustomButton noborder={false} title='Clone' onClick={activeCreateContact} />

              <CustomButton noborder={false} title='Send Mail' />

              <CustomButton noborder={false} title='Merge to' />

              <CustomButton noborder={false} title='Show History' />

              <CustomSelect
                style={{ width: 160, height: 40, whiteSpace: 'break-spaces' }}
                defaultValue='VCF'
                externalValue='VCF'
                options={[
                  {
                    value: 'copyText',
                    label: 'Copy to Clipboard as PlainText',
                  },
                ]}
                handleChange={handleVCF}
              />
              <FlexCol>
                <Avatar src={logo} size={40} />
              </FlexCol>
            </FlexCol>
          </FlexCol>
        </Card>
      </StyledCompanyHeader>
    </>
  );
};
export default ContactSetupHeader;
