import styled from 'styled-components';

const StyledUserTable = styled.div<{ showHeader: boolean; hasFooter: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  h3 {
    font-weight: normal;
  }
  .question-icon {
    button {
      background-color: #0078d4;
      padding: 0.3rem;
      border-radius: 0;
      svg {
        fill: #fff;
        font-size: 1.3rem;
      }
    }
  }
  .table-container {
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
      0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 0.8rem;
    height: 100%;
    max-height: calc(100% - 6rem);
    width: 100%;
    max-width: 100%;
    .table-inner-container {
      display: flex;
      flex-direction: column;
      border: 1px solid #c6c4c4;
      border-radius: 0.3rem;
      height: 100%;
      max-width: 100%;
      overflow: auto;
      button {
        width: max-content;
        height: max-content;
        border: none;
        padding: 0;
        background-color: transparent;
      }
      .filled {
        svg {
          fill: #0078d4 !important;
        }
      }
      .outlined {
        svg {
          fill: rgba(0, 0, 0, 0.1) !important;
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .ant-table-wrapper .ant-table-tbody > tr > td {
        border-bottom: none;
      }
      .ant-table-tbody {
        line-height: 30px;
      }
      .ant-table-footer {
        margin-top: auto;
        position: sticky;
        bottom: 0;
        z-index: 1;
      }
      .ant-table-thead {
        display: table-header-group;
        height: 35px;
        position: sticky;
        top: 0;
        z-index: 1;
        .ant-checkbox {
          display: none;
        }
      }
      .ant-spin-nested-loading,
      .ant-spin-container,
      .ant-table {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export default StyledUserTable;
