import SubTableExpandableProvider from 'src/components/commons/subTable/contexts/subtableExpandable.context';
import CompanyRelated from './table/companiesRelatedTable';

const CompanyRelatedSection = () => (
  <SubTableExpandableProvider>
    <CompanyRelated />
  </SubTableExpandableProvider>
);

export default CompanyRelatedSection;
