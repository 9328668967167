import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { SortModel, SubTableColumn } from 'src/components/commons/subTable/types';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { EmailTypeModel, AllFilters2 } from 'src/graphql/schema-types';
import COLUMNS from '../constants';
import { GET_ALL_EMAIL_TYPES, GET_EMAIL_TYPES_HEADERS } from '../graphql/queries';
import { UPDATE_EMAIL_TYPE, CREATE_EMAIL_TYPE, REMOVE_EMAIL_TYPE } from '../graphql/mutations';
import { EmailTypeCompany, GetAllEmailTypesCompany, GetEmailTypeGridHeaders } from '../types';
import { filterIDAndAddingTitle } from '../../../../../utils/functions';
// import { filterIDAndAddingTitle } from '../../../utils/functions';

const useEmailTypesTable = () => {
  const [deleting, setDeleting] = useState(false);
  const [getAll, queryResponse] = useLazyQuery<GetAllEmailTypesCompany>(GET_ALL_EMAIL_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getAllSearch] = useLazyQuery<GetAllEmailTypesCompany>(GET_ALL_EMAIL_TYPES, {
    fetchPolicy: 'network-only',
  });
  const [getHeaders] = useLazyQuery<GetEmailTypeGridHeaders>(GET_EMAIL_TYPES_HEADERS, {
    fetchPolicy: 'network-only',
  });
  const [update] = useMutation(UPDATE_EMAIL_TYPE);
  const [create] = useMutation(CREATE_EMAIL_TYPE);
  const [remove] = useMutation(REMOVE_EMAIL_TYPE);

  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const [columns, setColumns] = useState<SubTableColumn[]>([]);
  const [newRecord, setNewRecord] = useState({
    name: '',
  });

  const fetchEmailTypesPage: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllEmailTypes2.results || [],
      total: res.data?.GetAllEmailTypes2.total || 0,
    }));

  const { dataSource, onListRender, setDataSource, reset } =
    useInfinityDataSource(fetchEmailTypesPage);

  useEffect(() => {
    getHeaders().then((res) => {
      const filteredResult = filterIDAndAddingTitle(
        res.data?.GetEmailTypeGridHeaders as unknown as SubTableColumn[],
      )
        .filter((header) => header.dataIndex !== 'description')
        .map((header) => {
          if (header.dataIndex === 'name')
            return {
              ...header,
              searchField: 'name',
              title: 'EMAIL TYPE',
            };
          return header;
        });
      setColumns(filteredResult ?? COLUMNS);
    });
    reset();
  }, []);

  const autocompleteFieldSearch = (
    searchField: string,
    value: string,
    dataType: string | number | string[],
  ) =>
    getAllSearch({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 100,
          },
          filter: [
            {
              fieldName: searchField,
              value,
              dataType,
              operator: AllFilters2.Contains,
            },
          ],
        },
        searchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllEmailTypes2.results,
          filter: res?.data?.GetAllEmailTypes2?.results.map((result: EmailTypeCompany) => ({
            value: result[searchField],
            label: result[searchField],
          })),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });

  const resetNewRecord = () => {
    setNewRecord({
      name: '',
    });
  };

  const postNewRecord = async () => {
    if (newRecord.name === '') {
      return;
    }

    await create({
      variables: {
        createEmailTypeInput: {
          name: capitalizeFirstWord(newRecord.name),
          description: '',
        },
      },
    }).then((email) => {
      setDataSource([
        { id: email.data.CreateEmailType.id, name: email.data.CreateEmailType.name },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleUpdateRows = (rows: EmailTypeModel[]) => {
    rows.forEach((row) => {
      const { id, name } = row;
      update({
        variables: {
          updateEmailTypeInput: {
            id,
            name: capitalizeFirstWord(name!),
          },
        },
      });
    });
  };

  const handleDeleteRows = async (rows: EmailTypeModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPagination({ page, pageSize });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleSortModelChange = (sortModel: SortModel | undefined) => {
    setPagination({ ...pagination, sort: sortModel });
  };

  const handleClearFilter = () => {
    reset();
  };

  return {
    autocompleteFieldSearch,
    handlePaginationChange,
    handleFilterChange,
    handleSortModelChange,
    pagination,
    columns,
    setColumns,
    dataSource,
    onListRender,
    handleUpdateRows,
    spinning: queryResponse.loading || deleting,
    newRecord,
    handleNewRecord,
    resetNewRecord,
    postNewRecord,
    handleClearFilter,
    handleDeleteRows,
  };
};

export default useEmailTypesTable;
