import { GQL } from 'src/graphql';
import { AdapterFunc } from 'src/hooks/useEntityAdapter';
import { CompanyTypeAliasModel } from 'src/models';

const companyTypeAliasAdapter: AdapterFunc<GQL.CompanyTypeAliasModel, CompanyTypeAliasModel> = (
  input,
) => ({
  id: input.id,
  name: input.name!,
  createdAt: input.createdAt,
  updatedAt: input.updatedAt,
});

export default companyTypeAliasAdapter;
