import { useState } from 'react';
import PageInfo from 'src/modules/settings/modules/subTables/types';
import { useLazyQuery, useMutation } from '@apollo/client';
import useInfinityDataSource, { FetchFunc } from 'src/hooks/useInfinityDataSource';
import capitalizeFirstWord from 'src/utils/functions/capitalizeFirstWord';
import { UnitOfMeasurementModel } from 'src/graphql/schema-types';
import { GetAllUnitOfMeasurements, GetAllUnitOfMeasurementsResponse } from '../types';
import { GET_ALL_UNIT_OF_MEASUREMENTS } from '../graphql/queries';
import COLUMNS from '../constants';
import {
  CREATE_UNIT_OF_MEASUREMENTS,
  REMOVE_UNIT_OF_MEASUREMENTS,
  UPDATE_UNIT_OF_MEASUREMENTS,
} from '../graphql/mutations';
import useFilterColumn from '../../../../company/tabs/callPattern/hooks/useFilterColumn';

const useUnitOfMeasurementsTable = () => {
  const { createdFilters, setColumnFilters } = useFilterColumn();
  const [deleting, setDeleting] = useState(false);
  const [getAll, queryResponse] = useLazyQuery<GetAllUnitOfMeasurements>(
    GET_ALL_UNIT_OF_MEASUREMENTS,
    {
      fetchPolicy: 'network-only',
    },
  );
  const [getAllFilter] = useLazyQuery<GetAllUnitOfMeasurements>(GET_ALL_UNIT_OF_MEASUREMENTS, {
    fetchPolicy: 'network-only',
  });
  const [columns, setColumns] = useState(COLUMNS);
  const [newRecord, setNewRecord] = useState({
    name: '',
    units: 0,
  });
  const [update] = useMutation(UPDATE_UNIT_OF_MEASUREMENTS);
  const [remove] = useMutation(REMOVE_UNIT_OF_MEASUREMENTS);
  const [create] = useMutation(CREATE_UNIT_OF_MEASUREMENTS);
  const [pagination, setPagination] = useState<PageInfo>({ page: 1, pageSize: 25 });
  const uploadData: FetchFunc = ({ start, renderLen }) =>
    getAll({
      variables: {
        criteria: {
          pagination: {
            from: start,
            size: renderLen,
          },
        },
        searchFields: {
          enabled: null,
        },
      },
    }).then((res) => ({
      results: res.data?.GetAllUnitOfMeasurementFromElastic2.results || [],
      total: res.data?.GetAllUnitOfMeasurementFromElastic2?.total || 0,
    }));
  const { dataSource, onListRender, reset, setDataSource } = useInfinityDataSource(uploadData);
  const autoCompleteFieldSearch = (
    searchField: string,
    search: string,
    dataType: string | number | string[],
  ) => {
    const filters = createdFilters(searchField, search, dataType);
    return getAllFilter({
      variables: {
        criteria: {
          pagination: {
            from: 0,
            size: 25,
          },

          filter: filters,
        },
        SearchFields: {
          ids: null,
          names: null,
          enabled: null,
        },
      },
    }).then((res) => {
      if (res.data) {
        return {
          results: res?.data?.GetAllUnitOfMeasurementFromElastic2.results || [],
          filter: res?.data?.GetAllUnitOfMeasurementFromElastic2.results?.map(
            (result: GetAllUnitOfMeasurementsResponse) => ({
              value: result[searchField],
              label: result[searchField],
            }),
          ),
        };
      }

      return {
        results: [],
        filter: [],
      };
    });
  };

  const handleFilterChange = (newFilters: Record<string, string[]>) => {
    setPagination({ ...pagination, filters: newFilters, page: 1 });
  };

  const handleUpdateRows = (rows: UnitOfMeasurementModel[]) => {
    rows.forEach((row) => {
      const { id, name, units } = row;
      update({
        variables: {
          updateUnitOfMeasurementInput: {
            id,
            name: capitalizeFirstWord(name!),
            units: Number(units!),
          },
        },
      });
    });
  };
  const handleDeleteRows = async (rows: UnitOfMeasurementModel[]) => {
    setDeleting(true);
    await Promise.all(rows.map((row) => remove({ variables: { id: row.id } })));
    setDeleting(false);
    const ids = rows.map((row) => row.id);
    const copiedDataSource = [...dataSource].filter((data) => !ids.includes(data.id));
    setDataSource(copiedDataSource);
  };
  const resetNewRecord = () => {
    setNewRecord({
      name: '',
      units: 0,
    });
  };
  const postNewRecord = async () => {
    if (newRecord.name === '' || newRecord.units === 0) {
      return;
    }

    await create({
      variables: {
        createUnitOfMeasurementInput: {
          name: capitalizeFirstWord(newRecord.name),
          units: Number(newRecord.units),
        },
      },
    }).then((unitOfMeasurement) => {
      setDataSource([
        {
          id: unitOfMeasurement.data.CreateUnitOfMeasurement.id,
          name: unitOfMeasurement.data.CreateUnitOfMeasurement.name,
          units: unitOfMeasurement.data.CreateUnitOfMeasurement.units,
        },
        ...dataSource,
      ]);
    });
    resetNewRecord();
  };

  const handleClearFilter = () => {
    reset();
    setColumnFilters([]);
  };

  const handleNewRecord = (args: Record<string, unknown>) => {
    setNewRecord((prev) => ({
      ...prev,
      [args?.dataIndex as string]: args?.value as string,
      autoSave: !!args?.autoSave,
    }));
  };
  return {
    columns,
    dataSource,
    spinning: queryResponse.loading || deleting,
    newRecord,
    onListRender,
    setColumns,
    handleFilterChange,
    handleDeleteRows,
    handleNewRecord,
    resetNewRecord,
    handleUpdateRows,
    postNewRecord,
    handleClearFilter,
    autoCompleteFieldSearch,
  };
};

export default useUnitOfMeasurementsTable;
