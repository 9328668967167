import { gql } from '@apollo/client';

export const GET_ALL_PRODUCT_FAMILY = gql`
  query GetAllProductFamilyFromElastic2(
    $criteria: ProductFamilyMasterCriteriaInput!
    $searchFields: FindProductFamilyMasterInput
  ) {
    GetAllProductFamilyFromElastic2(Criteria: $criteria, SearchFields: $searchFields) {
      results {
        id
        name
      }
      total
    }
  }
`;
